import { getUser } from 'utils/auth';

export enum PerformanceAccess {
  PERFORMANCE_REVIEW_HR = 'PERFORMANCE_REVIEW_HR',
  VIEW_PERFORMANCE_REVIEW = 'VIEW_PERFORMANCE_REVIEW',
  PERFORMANCE_REVIEW_MANAGE = 'PERFORMANCE_REVIEW_MANAGE',
  PERFORMANCE_MANAGE = 'PERFORMANCE_MANAGE',
}

export const isPerformanceAccess = () => {
  const userPermissions = getUser()?.permissions;
  return (
    userPermissions.includes(PerformanceAccess.PERFORMANCE_REVIEW_HR) ||
    userPermissions.includes(PerformanceAccess.VIEW_PERFORMANCE_REVIEW) ||
    userPermissions.includes(PerformanceAccess.PERFORMANCE_REVIEW_MANAGE) ||
    userPermissions.includes(PerformanceAccess.PERFORMANCE_MANAGE)
  );
};
