import React from 'react';
import { Dropdown, Button, Menu, Popconfirm, MenuItemProps, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { ItemType } from 'antd/es/menu/hooks/useItems';

export interface ItemMenuProps {
  type?: string;
  checkDisabled?: (...props: any) => boolean;
  name?: React.ReactNode;
  confirmTitle?: string;
  icon?: React.ReactNode;
  handleClick?: (data?: any) => void;
  [propName: string]: any;
}

export interface MenuActionProps {
  menu: Array<ItemMenuProps>;
  data?: any;
  [propName: string]: any;
}

const MenuAction = (props: MenuActionProps) => {
  const { t } = useTranslation(['timesheet', 'onboard']);
  const [selectedAction, setSelectedAction] = React.useState<ItemMenuProps | null>(null);

  const handleMenuClick = (item: ItemMenuProps) => {
    if (item.type === 'delete' || item.type === 'confirm') {
      setSelectedAction(item);
    } else {
      item.handleClick && item.handleClick(props.data);
    }
  };

  const handleConfirm = () => {
    if (selectedAction) {
      selectedAction.handleClick && selectedAction.handleClick(props.data);
      setSelectedAction(null);
    }
  };

  const handleCancel = () => {
    setSelectedAction(null);
  };

  const items: ItemType[] = props.menu.map((item, index) => ({
    key: index,
    icon: item.icon,
    disabled: item.checkDisabled && item.checkDisabled(props.data),
    onClick: () => handleMenuClick(item),
    label: item.name,
    type: item.type as 'group' | 'divider' | undefined,
    danger: item.type === 'delete',
  }));

  return (
    <Popconfirm
      title={
        selectedAction?.type === 'delete'
          ? t('onboard:are_you_sure_to_delete_this')
          : selectedAction?.confirmTitle
      }
      visible={!!selectedAction}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      okText={t('Yes')}
      cancelText={t('No')}
      placement="left"
      destroyTooltipOnHide
      overlayClassName="custom-popconfirm"
      onVisibleChange={(visible) => {
        if (!visible) {
          setSelectedAction(null);
        }
      }}
    >
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button type="text" icon={<EllipsisOutlined />} onClick={(e) => e.preventDefault()} />
      </Dropdown>
    </Popconfirm>
  );
};

export default MenuAction;
