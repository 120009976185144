import styled from 'styled-components';
import { CSSProperties } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

// Styled Components
export const CVContainer = styled.div`
  padding: 10px 20px 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  max-width: 100%;
  background-color: white;
  line-height: 1.6;
  color: #2c3e50;

  .cv-content {
    margin-top: 20px;
  }
`;

export const Logo = styled.img`
  height: 40px;
  margin-bottom: 30px;
  display: block;
  object-fit: contain;
  margin-top: -30px;
`;

export const Title = styled.h1`
  color: #2c3e50;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
`;

export const Section = styled.div`
  margin-bottom: 30px;
  break-inside: avoid;
  page-break-inside: avoid;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.h2<{ noUnderline?: boolean }>`
  color: #e74c3c;
  font-size: 18px;
  text-transform: ${(props) => (props.noUnderline ? 'none' : 'uppercase')};
  border-bottom: ${(props) => (props.noUnderline ? 'none' : '2px solid #e74c3c')};
  padding-bottom: 8px;
  margin-bottom: 15px;
  font-weight: ${(props) => (props.noUnderline ? '600' : '700')};
  letter-spacing: 0.5px;
`;

export const TableStyled = styled.div`
  .ant-table-thead > tr > th {
    background-color: #ff4d4f;
    color: white;
    font-weight: normal;
  }
  .ant-table-tbody > tr > td {
    vertical-align: top;
  }
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
`;

export const LoadingContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
`;

export const TemplateContainer = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
`;

export const TemplateOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

export const TemplateOption = styled.div<{ selected?: boolean }>`
  padding: 16px;
  border: 2px solid ${(props) => (props.selected ? '#1890ff' : '#d9d9d9')};
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#e6f7ff' : 'white')};
  transition: all 0.3s;
  text-align: center;

  &:hover {
    border-color: #1890ff;
    background-color: #e6f7ff;
  }

  h4 {
    margin: 0 0 10px;
    color: #1890ff;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    font-size: 13px;
    color: #666;
  }
`;

export const InfoLabel = styled.span`
  color: #2c3e50;
  font-weight: bold;
  width: 120px;
  display: inline-block;
`;

export const InfoValue = styled.span`
  color: #2c3e50;
  margin-left: 8px;
`;

export const InfoRow = styled.div`
  margin-bottom: 12px;
`;

export const ProjectCard = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  position: relative;
`;

export const ProjectHeader = styled.div`
  margin-bottom: 10px;
`;

export const ProjectTitle = styled.h3`
  color: #000;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 8px;
`;

export const EditButtons = styled.div`
  margin-top: 20px;
  text-align: right;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e74c3c;
  padding-bottom: 8px;
  margin-bottom: 16px;
`;

export const NoUnderlineSectionTitle = styled(SectionTitle).attrs({ noUnderline: true })`
  margin: 0;
  border: none;
  padding-bottom: 0;
  font-weight: 700;
`;

export const EducationItem = styled.div`
  margin-bottom: 24px;
  position: relative;
`;

export const EducationRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

export const EducationSchool = styled.div`
  flex: 1;
`;

export const EducationMajor = styled.div`
  width: 400px;
`;

export const FieldLabel = styled.div`
  margin-bottom: 8px;
  color: #666;
`;

export const SchoolName = styled.div`
  word-break: break-word;
  font-weight: bold;
`;

export const MajorName = styled.div`
  word-break: break-word;
  color: #666;
`;

export const DeleteButton = styled(DeleteOutlined)`
  color: #ff4d4f;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const NoEducationInfo = styled.div`
  text-align: center;
  color: #666;
  padding: 16px;
`;

export const LanguageLabel = styled(InfoLabel)`
  min-width: 120px;
`;

export const LanguageValue = styled.span`
  min-width: 150px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
`;

export const SkillItem = styled.div`
  width: 200px;
  margin-bottom: 16px;
`;

export const SkillName = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

export const ProjectRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

export const ProjectNameContainer = styled.div`
  flex: 1;
`;

export const ProjectDurationContainer = styled.div`
  width: 200px;
`;

export const ProjectNameDisplay = styled.div`
  word-break: break-word;
  font-size: 16px;
  font-weight: bold;
`;

export const ProjectDeleteButton = styled(DeleteOutlined)`
  color: #ff4d4f;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const ProjectDurationDisplay = styled.div`
  color: #666;
`;

export const ProjectLabel = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
`;

export const ProjectSection = styled.div`
  margin-bottom: 20px;
`;

export const ProjectContent = styled.div`
  word-break: break-word;
`;

export const ProjectFieldLabel = styled.div`
  margin-bottom: 8px;
  color: #666666;
  font-weight: 500;
`;

export const CancelButton = styled(Button)`
  margin-left: 10px;
`;

export const SaveButton = styled(Button)`
  margin-left: 10px;
`;

export const RefreshButton = styled(Button)`
  margin-right: 8px;
`;

export const CVContentContainer = styled.div`
  margin-top: 20px;
`;

export const NoCVContainer = styled.div`
  text-align: center;
  padding: 20px;
`;

export const NoCVTitle = styled.h3`
  color: #ff4d4f;
`;

export const NoCVDescription = styled.p`
  color: #8c8c8c;
  font-size: 16px;
`;

export const TemplateTitle = styled.h3`
  margin-bottom: 16px;
  color: #1890ff;
`;

export const LoadingTextContainer = styled.div`
  text-align: center;
`;

export const LoadingText = styled.div`
  margin-top: 16px;
  color: #1890ff;
  white-space: nowrap;
`;

export const CenteredInput = styled(Input)`
  text-align: center;
`;

export const PositionContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
  color: #ff4d4f;
`;

export const PositionInput = styled(Input)`
  text-align: center;
  color: #ff4d4f;
`;

export const ProjectDurationText = styled.div`
  color: #666;
`;

export const BulletPoint = styled.span`
  margin: 0 4px;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  min-width: 300px;
`;

export const StyledInput = styled(Input)`
  width: 150px;
`;

export const PaddedInput = styled(Input)`
  padding: 0 8px;
`;

// Global CSS Classes
export const globalStyles = `
  .padded-button {
    padding: 0 8px;
  }
  
  .margin-right-button {
    margin-right: 8px;
  }
`;

// Inline Styles

// Styles for Skills Section
export const SkillsSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e74c3c;
  padding-bottom: 8px;
  margin-bottom: 16px;
`;

export const SkillsSectionTitle = styled.div`
  margin: 0;
  border: none;
  padding-bottom: 0;
`;

// Styles for Work Experience Section
export const WorkExperienceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e74c3c;
  padding-bottom: 8px;
  margin-bottom: 16px;
`;

export const WorkExperienceTitle = styled.div`
  margin: 0;
  border: none;
  padding-bottom: 0;
`;

// Styles for Personal Information section
export const personalInfoStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '300px',
  },
  label: {
    minWidth: '120px',
    fontWeight: 'bold',
  },
  value: {
    minWidth: '150px',
  },
};

// Styles for Education section
export const educationStyles = {
  container: {
    marginBottom: '10px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  school: {
    fontWeight: 'bold',
    marginRight: '10px',
  },
  majorLabel: {
    marginRight: '5px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  input: {
    flex: 1,
    marginRight: '10px',
  },
  removeButton: {
    color: 'red',
  },
};

// Styles for Language section
export const languageStyles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '300px',
    marginBottom: '10px',
  },
  label: {
    minWidth: '120px',
    fontWeight: 'bold',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
};

// Styles for Programming Skills section
export const programmingSkillsStyles = {
  categoryContainer: {
    marginBottom: '20px',
    border: '1px solid #e8e8e8',
    borderRadius: '8px',
    padding: '16px',
    backgroundColor: '#fff',
  },
  categoryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  categoryTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  skillRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  skillInput: {
    flex: 2,
    marginRight: '10px',
  },
  yearsInput: {
    flex: 1,
    marginRight: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  addButton: {
    marginTop: '10px',
  },
};

// Styles for Projects section
export const projectStyles = {
  card: {
    border: '1px solid #e8e8e8',
    borderRadius: '8px',
    padding: '24px',
    marginBottom: '16px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  },
  header: {
    marginBottom: '10px',
  },
  title: {
    color: '#000',
    fontSize: '16px',
    fontWeight: 'normal' as 'normal',
    marginBottom: '8px',
  },
  row: {
    marginBottom: '10px',
  },
  label: {
    marginBottom: '8px',
    color: '#666',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    marginTop: '20px',
    textAlign: 'right' as 'right',
  },
};

// Styles for Template selection
export const templateStyles = {
  container: {
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  options: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '16px',
  },
  option: (selected: boolean): CSSProperties => ({
    padding: '16px',
    border: `2px solid ${selected ? '#1890ff' : '#d9d9d9'}`,
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: selected ? '#e6f7ff' : 'white',
    transition: 'all 0.3s',
  }),
  optionTitle: {
    margin: '0 0 8px',
    color: '#1890ff',
  },
  optionDescription: {
    margin: 0,
    fontSize: '14px',
    color: '#666',
  },
};

// Styles for Loading overlay
export const loadingStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1000,
  } as CSSProperties,
  content: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1001,
  } as CSSProperties,
};

// Styles for Edit buttons
export const editButtonsStyles = {
  container: {
    marginTop: '20px',
    textAlign: 'right' as 'right',
  },
  button: {
    marginLeft: '10px',
  },
};

// Styles for CV container
export const cvContainerStyles = {
  container: {
    padding: '20px',
    fontFamily: "'Helvetica Neue', Arial, sans-serif",
    maxWidth: '100%',
    backgroundColor: 'white',
    lineHeight: 1.6,
    color: '#2c3e50',
  },
  content: {
    marginTop: '20px',
  },
};

// Styles for Logo
export const logoStyles = {
  image: {
    height: '50px',
    marginBottom: '20px',
    display: 'block',
    objectFit: 'contain' as 'contain',
  },
};

// Styles for Title
export const titleStyles = {
  text: {
    color: '#2c3e50',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    textAlign: 'center' as 'center',
  },
  position: {
    textAlign: 'center' as 'center',
    marginBottom: '20px',
    color: '#FF4D4F',
  },
};

// Styles for Section
export const sectionStyles = {
  container: {
    marginBottom: '20px',
    breakInside: 'avoid' as 'avoid',
    pageBreakInside: 'avoid' as 'avoid',
  },
  title: {
    color: '#e74c3c',
    fontSize: '18px',
    textTransform: 'uppercase' as 'uppercase',
    borderBottom: '2px solid #e74c3c',
    paddingBottom: '8px',
    marginBottom: '15px',
    fontWeight: 500,
    letterSpacing: '0.5px',
  },
};

// Styles for Table
export const tableStyles = {
  container: {
    '& .ant-table-thead > tr > th': {
      backgroundColor: '#ff4d4f',
      color: 'white',
      fontWeight: 'normal',
    },
    '& .ant-table-tbody > tr > td': {
      verticalAlign: 'top',
    },
  },
};

// Styles for No CV message
export const noCVStyles = {
  container: {
    textAlign: 'center' as 'center',
    padding: '20px',
  },
  icon: {
    fontSize: '48px',
    color: '#d9d9d9',
    marginBottom: '20px',
  },
  text: {
    color: '#8c8c8c',
    fontSize: '16px',
  },
};
