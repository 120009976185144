import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Input, Row, Select } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { YYYY_MM_DD } from 'constants/commons';
import ButtonBack from 'components/ButtonBack';
import { useHistory } from 'react-router-dom';
import EmployeeLists from './EmployeeLists';
import CustomButton from 'styles/buttonStyled';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import * as yup from 'yup';
import {
  createReviewCycle,
  exportPerformanceReviewCycle,
  getPerformanceTemplates,
  updateReviewCycle,
} from './performanceAction';
import GeneralInformation from './components/GeneralInformation';
import Scheduler from './components/Scheduler';
import Reminder from './components/Reminder';
import moment from 'moment';
import { selectPerformanceData } from './performanceSlice';
import { useTranslation } from 'react-i18next';
import { ExportOutlined } from '@ant-design/icons';

const CreateReviewCycle = ({ setCreateMode, setViewMode, viewMode }) => {
  const { t } = useTranslation(['action']);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [selectUser, setSelectUser] = useState<any>([]);

  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const performanceStore = useAppSelector(selectPerformanceData);
  const cycleDetail = performanceStore?.cycleDetail;
  const schema = yup.object().shape({
    period: yup.object().shape({
      from: yup.string().required('Start date is required'),
      to: yup.string().required('End date is required'),
    }),
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      return cycleDetail && viewMode
        ? transformStepScheduler({
            ...cycleDetail,
            templateId: cycleDetail?.templatesHistory?.templates?.id,
            period: {
              from: moment(cycleDetail?.periodFrom)?.toDate() || '',
              to: moment(cycleDetail?.periodTo)?.toDate() || '',
            },
          })
        : {
            name: '',
            status: 'New',
            description: '',
            scope: 'List', // "all" or "list"
            period: { from: '', to: '' },
            notifyBeforeReviewPeriod: 0,
            notifyBeforeReviewDate: 0,
          };
    }, [cycleDetail]),
  });
  const shouldDisable = getValues()?.status !== 'New';

  // use for show step scheduler
  function transformStepScheduler(obj: any) {
    const result = { ...obj }; // Clone the original object to avoid mutation

    // Loop through each step in the stepScheduler array
    obj?.stepScheduler?.forEach((step: any) => {
      const key = `stepId_${step.stepName}_${step.stepNumber}`;
      result[key] = step?.endDate ? moment(step?.endDate, 'YYYY-MM-DD hh:mm:ss') : undefined; // Assign undefined if endDate is empty
    });

    return result;
  }

  useEffect(() => {
    reset(
      cycleDetail && viewMode
        ? transformStepScheduler({
            ...cycleDetail,
            templateId: cycleDetail?.templatesHistory?.templates?.id,
            period: {
              from: moment(cycleDetail?.periodFrom)?.toDate() || '',
              to: moment(cycleDetail?.periodTo)?.toDate() || '',
            },
          })
        : {
            name: '',
            status: 'New',
            description: '',
            scope: 'List', // "all" or "list"
            period: { from: '', to: '' },
            notifyBeforeReviewPeriod: 0,
            notifyBeforeReviewDate: 0,
          },
    );
    if (cycleDetail) setSelectUser([...cycleDetail?.userIds]);
  }, [cycleDetail]);

  useEffect(() => {
    dispatch(getPerformanceTemplates({}));
  }, [dispatch]);

  function transformObject(obj) {
    // Extract the keys and values for fields matching stepId_
    const stepScheduler = Object.keys(obj)
      .filter((key) => key.startsWith('stepId_'))
      .map((key) => ({
        stepNumber: parseInt(key.split('_')[2], 10),
        endDate: obj[key] ? moment(obj[key]).format('YYYY-MM-DD hh:mm:ss') : '',
        stepName: key?.split('_')?.[1],
      }));

    // Fields to be removed
    const fieldsToRemove = [];

    // Create a new object without the period field and stepId_ fields
    const newObj = { ...obj };
    fieldsToRemove.forEach((field) => {
      unregister(field);
      delete newObj[field];
    });

    // Add the stepScheduler array to the new object
    newObj.stepScheduler = stepScheduler;

    return newObj;
  }

  function filterLastSequentialSteps(
    steps: Array<{ stepNumber: number; endDate: string; stepName: string }>,
  ) {
    let sequences: Array<Array<{ stepNumber: number; endDate: string; stepName: string }>> = [];
    let currentSequence: Array<{ stepNumber: number; endDate: string; stepName: string }> = [];

    steps.forEach((step) => {
      if (step.stepNumber === 1) {
        // If the stepNumber is 1, start a new sequence
        if (currentSequence.length > 0) {
          sequences.push(currentSequence); // Push the previous sequence to sequences
        }
        currentSequence = [step]; // Start a new sequence
      } else if (currentSequence.length > 0 && step.stepNumber === currentSequence.length + 1) {
        // Continue the current sequence only if it's sequential
        currentSequence.push(step);
      }
    });

    // Push the last sequence to sequences if it's valid
    if (currentSequence.length > 0) {
      sequences.push(currentSequence);
    }

    // Return the last valid sequence (the last group in the array)
    return sequences[sequences.length - 1] || [];
  }

  const onSubmit = (data: any) => {
    const filterData = transformObject(data);
    const body = {
      ...filterData,
      stepScheduler: filterLastSequentialSteps(filterData?.stepScheduler),
      totalAssignees: selectUser?.length,
      status: 'New',
      notifyBeforeReviewDate: Number(filterData?.notifyBeforeReviewDate),
      notifyBeforeReviewPeriod: Number(filterData?.notifyBeforeReviewPeriod),
      userIds: selectUser?.map((user: any) => user?.id || user),
    };
    viewMode
      ? dispatch(updateReviewCycle({ id: viewMode, body, callBack: () => setCreateMode(false) }))
      : dispatch(createReviewCycle({ body, callBack: () => setCreateMode(false) }));
  };

  const handleExport = async () => {
    setIsLoadingExport(true);
    dispatch(exportPerformanceReviewCycle([getValues()?.id]));
    setIsLoadingExport(false);
  };

  return (
    <>
      <ButtonBack
        handleBack={() => {
          setCreateMode && setCreateMode(false);
          setViewMode && setViewMode('');
        }}
      />
      {viewMode && (
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <CustomButton
            style={{ marginBottom: 15, marginLeft: 'auto' }}
            icon={<ExportOutlined />}
            loading={isLoadingExport}
            disabled={!shouldDisable}
            onClick={handleExport}
          >
            {t('action:export')}
          </CustomButton>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <GeneralInformation
              control={control}
              watch={watch}
              unregister={unregister}
              getValues={getValues}
              setValue={setValue}
              viewMode={viewMode}
            />
          </Col>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Scheduler
                  control={control}
                  watch={watch}
                  getValues={getValues}
                  viewMode={viewMode}
                  setValue={setValue}
                />
              </Col>
              <Col span={24}>
                <Reminder control={control} viewMode={viewMode} getValues={getValues} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <EmployeeLists
              setSelectedUser={setSelectUser}
              inputData={getValues('userIds')}
              viewMode={viewMode}
              getValues={getValues}
              setValue={setValue}
              currentPerformanceReviewId={getValues('id')}
            />
          </Col>
        </Row>
        {!(viewMode && shouldDisable) && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CustomButton type="primary" htmlType="submit" style={{ marginTop: 16 }}>
              {t('action:submit')}
            </CustomButton>
          </div>
        )}
      </form>
    </>
  );
};

export default CreateReviewCycle;
