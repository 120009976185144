import axiosClient from './axiosClient';

// Interfaces
export interface INote extends ICreateNoteRequest {
  id: string;
  noteApprover: UserApproveNoteDto[];
  createdAt: string;
  parent_note_id: any;
}

export interface ICreateNoteRequest {
  user_id: string;
  messages: Array<{
    user: {
      id: string;
      name: string;
    };
    notes: Array<{
      content: string;
      timestamp: string;
    }>;
  }>;
  contextType: string;
  tags: string[];
  idType: string | null;
}

export enum ApprovalStatus {
  PENDING = 'pending',
  NOT_APPROVED = 'not approved',
  APPROVED = 'approved',
}

export interface UserApproveNoteDto {
  userId: string;
  fullName: string;
  email: string;
  status: ApprovalStatus;
  timestamp: string;
}

export interface ISendMailApproverNoteRequest extends INote {}

// API Methods
const notesApi = {
  getNotes: (userId: string, contextType?: string, idType?: string): Promise<any> => {
    return axiosClient.get(`/notes`, {
      params: { userId, contextType, idType },
    });
  },

  createNote: (data: ICreateNoteRequest): Promise<any> => {
    return axiosClient.post('/notes', data);
  },

  updateNote: (id: string, data: Partial<ICreateNoteRequest>): Promise<any> => {
    return axiosClient.put(`/notes/${id}`, data);
  },

  deleteNote: (id: string): Promise<any> => {
    return axiosClient.delete(`/notes/${id}`);
  },

  sendMailApproverNote: (data: ISendMailApproverNoteRequest): Promise<any> => {
    return axiosClient.post('/notes/send-mail-approver-note', data);
  },

  handleNoteByToken: (data: { token: string }): Promise<any> => {
    return axiosClient.post('/notes/approve-reject-by-token/note-approval', data);
  },
};

export default notesApi;
