import { useAppSelector, useExtraPermission } from 'app/hooks';
import { hrmUser } from 'constants/types';
import { selectUser } from 'features/auth/authSlice';
import { ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth, usePermission } from '../app/hooks';
import { PERMISSION_DASHBOARD_ACCESS } from 'constants/permissions';
import { getUser } from 'utils/auth';

interface PrivateRouteProps {
  children: ReactNode;
  path: string;
  permissions?: Array<string>;
  extraPermissions?: Array<string>;
  exact?: boolean | undefined;
}

export function PrivateRoute({
  children,
  path,
  permissions,
  extraPermissions,
  ...rest
}: PrivateRouteProps) {
  let isAuth = useAuth();
  let isPermission = usePermission(permissions);
  let isExtraPermission = useExtraPermission(extraPermissions);
  const dataUser: hrmUser = useAppSelector(selectUser);
  if (dataUser?.isSystemAdmin) {
    isAuth = true;
    isPermission = true;
  }
  const isPermissionsUser = getUser()?.permissions?.includes(
    PERMISSION_DASHBOARD_ACCESS.DASHBOARD_ACCESS_HR,
  );

  return (
    <Route
      path={path}
      {...rest}
      render={({ location }) =>
        (isAuth && isPermission) || (isAuth && isExtraPermission) ? (
          children
        ) : isAuth ? (
          <Redirect
            to={
              dataUser?.permissions?.includes(PERMISSION_DASHBOARD_ACCESS.DASHBOARD_ACCESS_HR) ||
              dataUser?.isSystemAdmin
                ? isPermissionsUser
                  ? { pathname: '/module-selection' }
                  : { pathname: '/dashboard' }
                : isPermissionsUser
                ? { pathname: '/module-selection' }
                : { pathname: '/my-page/timesheet' }
            }
          />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
}
