import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface CategoryNameProps {
  name: string;
  rowSpan: number;
  categoryIndex: number;
  isEditing: boolean;
  editData?: any;
  setEditData: (data: any) => void;
  handleAddSkillToCategory: (categoryIndex: number) => void;
}

const CategoryName: React.FC<CategoryNameProps> = ({
  name,
  rowSpan,
  categoryIndex,
  isEditing,
  editData,
  setEditData,
  handleAddSkillToCategory,
}) => {
  const [inputValue, setInputValue] = useState('');
  const currentCategory = editData?.programmingSkills?.[categoryIndex];

  useEffect(() => {
    if (currentCategory?.name) {
      setInputValue(currentCategory.name);
    }
  }, [currentCategory]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  const handleInputBlur = () => {
    if (!editData?.programmingSkills) {
      console.log('No programmingSkills found in editData');
      return;
    }

    const updatedSkills = [...(editData.programmingSkills || [])];
    if (!updatedSkills[categoryIndex]) {
      console.log('Category not found at index:', categoryIndex);
      return;
    }

    updatedSkills[categoryIndex] = {
      ...updatedSkills[categoryIndex],
      name: inputValue,
    };

    setEditData({
      ...editData,
      programmingSkills: updatedSkills,
    });
  };

  const handleDeleteCategory = () => {
    if (!editData?.programmingSkills) {
      console.log('No programmingSkills found in editData');
      return;
    }

    const updatedSkills = editData.programmingSkills.filter(
      (_: any, index: number) => index !== categoryIndex,
    );

    setEditData({
      ...editData,
      programmingSkills: updatedSkills,
    });
  };

  return (
    <div
      style={{
        textAlign: 'center',
        padding: '8px 0',
        lineHeight: '1.5715',
        fontSize: '14px',
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      {isEditing ? (
        <>
          <Input
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onPressEnter={handleInputBlur}
            style={{ width: '90%', textAlign: 'center' }}
            placeholder="Enter category name"
          />
          <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}
          >
            <DeleteOutlined
              style={{ color: '#ff4d4f', cursor: 'pointer' }}
              onClick={handleDeleteCategory}
            />
            <Button
              type="link"
              size="small"
              onClick={() => handleAddSkillToCategory(categoryIndex)}
              style={{ padding: 0, height: 'auto' }}
            >
              + Add Skill
            </Button>
          </div>
        </>
      ) : (
        name
      )}
    </div>
  );
};

export default CategoryName;
