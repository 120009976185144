import { Col, Row, Spin } from 'antd';
import { RecruitJobDetailType } from 'features/recruitment/jobs/components/types';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import recruitApi from 'api/recruitApi';
import styled from 'styled-components';
import ITVLogo from '../../../../assets/icons/logo_innotech_black.png';

const PageWrapper = styled.div`
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 24px auto;
  @media (max-width: 768px) {
    margin: 0px auto;
  }
`;

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const SectionTitle = styled.h2`
  font-size: 22px;
  font-weight: 600;
  color: #374151;
  margin: 24px 0 16px;
`;

const DetailItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: baseline;
`;

const Label = styled.div`
  font-size: 14px;
  color: #6b7280;
  min-width: 140px;
  flex-shrink: 0;
`;

const Value = styled.div`
  font-size: 14px;
  color: #111827;
  font-weight: 500;
`;
const LogoImage = styled.img`
  margin-top: 0.2rem;
  height: 50px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;
const RequirementsContent = styled.div`
  font-size: 16px;
  color: #374151;
  line-height: 1.6;

  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin: 20px 0 12px;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 16px;
    list-style-type: circle;
  }

  li {
    margin-bottom: 8px;
    margin-left: 20px;
    list-style-type: circle;
  }
`;

const PublicJobDetails = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const { t } = useTranslation(['recruitment']);
  const [jobData, setJobData] = useState<RecruitJobDetailType | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await recruitApi.getPublicJobDetail(jobId);
        setJobData(response.data || null);
      } catch (error) {
        console.error('Error fetching job details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (jobId) {
      fetchJobDetails();
    }
  }, [jobId]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', flex: 1, alignContent: 'center', height: '100vh' }}>
        <Spin />
      </div>
    );
  }

  if (!jobData) {
    return <div className="text-center p-8">Job not found</div>;
  }

  const formatCurrency = (value: string | number) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const renderItem = (label: string, value: any) => (
    <DetailItem>
      <Label>{label}:</Label>
      <Value>{value || '-'}</Value>
    </DetailItem>
  );

  return (
    <PageWrapper>
      <PageTitle>
        {jobData.jobTemplate?.positionName}
        <LogoImage src={ITVLogo} alt="Itv logo" />
      </PageTitle>

      <SectionTitle>{t('recruitment:job_detail')}</SectionTitle>
      <Row gutter={32}>
        <Col xs={24} sm={12}>
          {renderItem(t('recruitment:job_id'), jobData.jobCode)}
          {renderItem(t('recruitment:job_location'), jobData.jobTemplate?.location?.name)}
          {renderItem(t('recruitment:department'), jobData.jobTemplate?.department?.name)}
          {renderItem(t('recruitment:headcount'), jobData.jobTemplate?.headcount)}
          {renderItem(
            t('recruitment:salary_range'),
            `${formatCurrency(jobData.jobTemplate?.minimumSalary)} - ${formatCurrency(
              jobData.jobTemplate?.maximumSalary,
            )} ${jobData.jobTemplate?.currencySymbol}`,
          )}
        </Col>

        <Col xs={24} sm={12}>
          {renderItem(t('recruitment:position_name'), jobData.jobTemplate?.positionName)}
          {renderItem(t('recruitment:job_type'), jobData.jobTemplate?.jobType?.name)}
          {renderItem(
            t('recruitment:open_date'),
            jobData.openDate ? moment(jobData.openDate).format('DD/MM/YYYY') : '-',
          )}
          {renderItem(
            t('recruitment:close_date'),
            jobData.closeDate ? moment(jobData.closeDate).format('DD/MM/YYYY') : '-',
          )}
        </Col>
      </Row>

      <SectionTitle>{t('recruitment:requirements')}</SectionTitle>
      <RequirementsContent
        dangerouslySetInnerHTML={{ __html: jobData.jobTemplate?.requirements || '' }}
      />
    </PageWrapper>
  );
};

export default PublicJobDetails;
