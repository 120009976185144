import React from 'react';
import { Table, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { TableStyled } from '../../../../../../../../styles/CVOnsiteStyles';
import CategoryName from './CategoryName';
import { processSkills } from '../../utils/skillsProcessing';

interface SkillsTableProps {
  isEditing: boolean;
  editData?: any;
  cvData?: any;
  setEditData: (data: any) => void;
  handleAddSkillToCategory: (categoryIndex: number) => void;
  handleUpdateCategorySkill: (
    categoryIndex: number,
    skillIndex: number,
    field: 'skill' | 'years',
    value: string,
  ) => void;
  handleRemoveSkillFromCategory: (categoryIndex: number, skillIndex: number) => void;
}

const SkillsTable: React.FC<SkillsTableProps> = ({
  isEditing,
  editData,
  cvData,
  setEditData,
  handleAddSkillToCategory,
  handleUpdateCategorySkill,
  handleRemoveSkillFromCategory,
}) => {
  const data = isEditing ? editData : cvData;
  if (!data) return null;

  const skillGroups =
    data.programmingSkills?.map((category: any) => {
      const processedSkills = processSkills(category);
      return {
        key: category.name,
        name: category.name,
        skills: processedSkills,
      };
    }) || [];

  return (
    <div style={{ marginTop: '16px' }}>
      <TableStyled>
        {skillGroups?.map((group: any, groupIndex: number) => (
          <div
            key={group.name}
            style={{ marginBottom: groupIndex < skillGroups.length - 1 ? '16px' : 0 }}
          >
            <Table
              className="skills-table"
              showHeader={groupIndex === 0}
              columns={[
                {
                  title: 'Items',
                  key: 'category',
                  width: '25%',
                  render: (_: any, __: any, index: number) => {
                    if (index === 0) {
                      return {
                        children: (
                          <div
                            style={{
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <CategoryName
                              name={group.name}
                              rowSpan={group.skills.length}
                              categoryIndex={groupIndex}
                              isEditing={isEditing}
                              editData={editData}
                              setEditData={setEditData}
                              handleAddSkillToCategory={handleAddSkillToCategory}
                            />
                          </div>
                        ),
                        props: {
                          rowSpan: group.skills.length,
                          style: {
                            background: '#f5f5f5',
                            borderRight: '1px solid #f0f0f0',
                            verticalAlign: 'middle',
                          },
                        },
                      };
                    }
                    return {
                      props: {
                        rowSpan: 0,
                      },
                    };
                  },
                },
                {
                  title: 'Technical Summary',
                  dataIndex: 'skill',
                  key: 'skill',
                  width: '45%',
                  render: (text: string, record: any, index: number) => {
                    const isNewSkill =
                      group.skills && index < group.skills.length
                        ? group.skills[index].skill === ''
                        : false;
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isEditing ? (
                          <Input
                            style={{ width: '100%' }}
                            defaultValue={text}
                            placeholder="Enter skill"
                            onBlur={(e) =>
                              handleUpdateCategorySkill(
                                groupIndex,
                                index,
                                'skill',
                                (e.target as HTMLInputElement).value,
                              )
                            }
                            onPressEnter={(e) =>
                              handleUpdateCategorySkill(
                                groupIndex,
                                index,
                                'skill',
                                e.currentTarget.value,
                              )
                            }
                          />
                        ) : (
                          <span
                            style={{ paddingLeft: '16px', padding: '8px 16px', display: 'block' }}
                          >
                            {text === 'N/A' ? '' : text}
                          </span>
                        )}
                      </div>
                    );
                  },
                },
                {
                  title: 'Years of Experience',
                  dataIndex: 'years',
                  key: 'years',
                  width: '20%',
                  render: (text: string, record: any, index: number) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {isEditing ? (
                        <Input
                          style={{ width: '100%' }}
                          defaultValue={text}
                          placeholder="Enter years of experience"
                          onBlur={(e) =>
                            handleUpdateCategorySkill(
                              groupIndex,
                              index,
                              'years',
                              (e.target as HTMLInputElement).value,
                            )
                          }
                          onPressEnter={(e) =>
                            handleUpdateCategorySkill(
                              groupIndex,
                              index,
                              'years',
                              e.currentTarget.value,
                            )
                          }
                        />
                      ) : (
                        <span
                          style={{ paddingLeft: '16px', padding: '8px 16px', display: 'block' }}
                        >
                          {text && text.includes('N/A') ? '' : text}
                        </span>
                      )}
                    </div>
                  ),
                },
                ...(isEditing
                  ? [
                      {
                        title: '',
                        key: 'actions',
                        width: '5%',
                        render: (_: any, __: any, index: number) => (
                          <div style={{ textAlign: 'center' }}>
                            <DeleteOutlined
                              style={{ color: '#ff4d4f', cursor: 'pointer' }}
                              onClick={() => handleRemoveSkillFromCategory(groupIndex, index)}
                            />
                          </div>
                        ),
                      },
                    ]
                  : []),
              ]}
              dataSource={group.skills}
              pagination={false}
              bordered
              rowClassName={() => (isEditing ? 'editing-row' : '')}
            />
          </div>
        ))}
        <style>
          {`
            .skills-table .ant-table-thead > tr > th {
              background-color: #ff4d4f;
              color: white;
              text-align: center;
              font-size: 14px;
              padding: 8px;
              font-weight: 500;
            }
            .skills-table .ant-table-tbody > tr > td {
              padding: 0;
              vertical-align: middle;
            }
            .skills-table .editing-row > td:not(:last-child) {
              background-color: #f5f5f5;
            }
          `}
        </style>
      </TableStyled>
    </div>
  );
};

export default SkillsTable;
