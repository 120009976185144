import i18n from 'app/i18n';
import { languages } from 'components/LayoutPage/SelectLanguage';
import { DISPLAY_HEADER, TYPE_HEADER } from 'constants/notification';

export function capitalizeFirstLetter(text: string) {
  if (!text) return null;
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const covertTitleNotification = (type: string) => {
  switch (type) {
    case TYPE_HEADER.Notification:
      return DISPLAY_HEADER.Personnel;

    case TYPE_HEADER.Reminder:
      return DISPLAY_HEADER.Reminder;

    default:
      return '';
  }
};

export function removeAccents(str: any) {
  var AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ];
  for (var i = 0; i < AccentsMap.length; i++) {
    var re = new RegExp('[' + AccentsMap[i].substring(1) + ']', 'g');
    var char = AccentsMap[i][0];
    if (str) str = str.replace(re, char);
  }
  return str;
}

export const getListCharacterFirst = (str: any) => {
  const matches = str.match(/\b(\w)/g) || [];
  const rs = matches.join('');
  return rs;
};
export const getStringContent = (content: string) => {
  const timePattern = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/g;
  let match;
  let lastMatch = null;

  while ((match = timePattern.exec(content)) !== null) {
    lastMatch = match;
  }

  const listEndIndex = lastMatch ? lastMatch.index + lastMatch[0].length : content.length;
  return listEndIndex;
};
export const textFormatDate = (date: Date | null): string => {
  if (!date) return '';
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${hours}:${minutes} ${day}/${month}/${year}`;
};

export const isLangEn = () => {
  const langEnCode = languages.find((lang) => lang.code === 'en')?.code || 'en';

  if (i18n.language === langEnCode) return true;

  return false;
};
