import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface UploadFileChatAIProps {
  onFileUpload: () => void;
}

const UploadFileChatAI: React.FC<UploadFileChatAIProps> = ({ onFileUpload }) => {
  const { t } = useTranslation('timesheet');
  return (
    <UploadMenuContent>
      <UploadButton
        icon={
          <img src="https://www.gstatic.com/images/branding/product/1x/drive_2020q4_48dp.png" />
        }
      >
        {t('connect_google_drive')}
      </UploadButton>
      <UploadButton icon={<PaperClipOutlined />} onClick={onFileUpload}>
        {t('download_computer')}
      </UploadButton>
    </UploadMenuContent>
  );
};

const UploadMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const UploadButton = styled(Button)`
  width: 100%;
  height: 36px;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 13px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .anticon {
    font-size: 16px;
    margin-right: 8px;
  }
`;

export default UploadFileChatAI;
