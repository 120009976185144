import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import VietnamFlag from 'assets/icons/vietnam-flag.png';
import USFlag from 'assets/icons/us-flag.png';
import KoreaFlag from 'assets/icons/korea-flag.png';
import JapanFlag from 'assets/icons/japan-flag.png';
import languageCode from 'constants/languageCode';
import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/vi';
import 'moment/locale/en-gb';
import 'moment/locale/ja';
import 'moment/locale/ko';

export const languages = [
  {
    code: 'en',
    src: USFlag,
    moment: 'en-gb',
  },
  {
    code: 'vi',
    src: VietnamFlag,
    moment: 'vi',
  },
  {
    code: 'ko',
    src: KoreaFlag,
    moment: 'ko',
  },
  {
    code: 'ja',
    src: JapanFlag,
    moment: 'ja',
  },
];

const SelectLanguage = ({ style, iconWidth = 30, title, ...rest }: any) => {
  const { i18n } = useTranslation();
  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
    localStorage.setItem('hrm.language', code);
    window.location.reload();
    moment.locale(languages.find((item: any) => item.code === code)?.moment || 'en-gb');
  };

  return (
    <Menu style={{ borderRadius: '9px' }}>
      {languages.map(({ code, src }: any) => (
        <Menu.Item onClick={() => changeLanguage(code)}>
          <div
            style={{
              width: 130,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>
              <img
                style={{ boxShadow: '0 0 4px rgb(0 0 0 / 20%)' }}
                alt={code}
                src={src}
                width={iconWidth}
              />{' '}
              {languageCode[code]?.nativeName}
            </span>
            {localStorage.getItem('hrm.language') === code && (
              <CheckOutlined style={{ color: 'blue', marginLeft: 10 }} />
            )}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SelectLanguage;
