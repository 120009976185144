import React, { useState, useEffect } from 'react';
import {
  Modal,
  Input,
  Button,
  List,
  Comment,
  Avatar,
  Tag,
  Popconfirm,
  message as messageAntd,
  Tooltip,
} from 'antd';
import {
  UserOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  MessageOutlined,
  MailOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import notesApi, { ICreateNoteRequest, INote } from '../api/notesApi';
import { getUser } from '../utils/auth';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { RichTextField } from './FormFields';
import { isPerformanceAccess } from 'features/employee/pages/PerformanceReview/components/types';
import MailForm from 'components/Forms/MailForm';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import { getListUserApproverOffer } from 'features/recruitment/recruitmentAction';

const TabContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 12px;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  padding: 8px 16px;
  background: ${(props) => (props.isActive ? '#1890ff' : '#fff')};
  color: ${(props) => (props.isActive ? '#fff' : '#595959')};
  border: 1px solid ${(props) => (props.isActive ? '#1890ff' : '#d9d9d9')};
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;

  &:hover {
    background: ${(props) => (props.isActive ? '#40a9ff' : '#f5f5f5')};
    border-color: ${(props) => (props.isActive ? '#40a9ff' : '#d9d9d9')};
  }
`;

const NoteCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #f0f0f0;

  .note-header {
    margin-bottom: 16px;

    .user-info {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      .ant-avatar {
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        background: #1890ff;

        .anticon {
          font-size: 18px;
        }
      }

      .user-name {
        font-weight: 600;
        font-size: 15px;
        color: #262626;
      }

      .timestamp {
        color: #8c8c8c;
        font-size: 13px;
      }

      .note-actions {
        margin-left: auto;
        display: flex;
        gap: 12px;
        align-items: center;

        .ant-btn {
          padding: 0;
          border: none;
          height: auto;
          box-shadow: none;
          background: transparent;

          &:hover {
            color: #1890ff;
            background: transparent;
          }

          &.ant-btn-dangerous:hover {
            color: #ff4d4f;
          }

          .anticon {
            font-size: 16px;
          }
        }
      }
    }
  }

  .note-content {
    font-size: 14px;
    color: #262626;
    line-height: 1.6;
    margin-bottom: 16px;
    padding: 0 4px;
    cursor: pointer;
  }

  .note-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-top: 12px;
    border-top: 1px solid #f0f0f0;

    .tag {
      margin: 0;
      padding: 4px 12px;
      border-radius: 16px;
      font-size: 12px;
      background: #e6f7ff;
      color: #1890ff;
      border: none;
      font-weight: 500;

      &:hover {
        background: #bae7ff;
      }
    }
  }
`;

const ReplyList = styled.div`
  margin: 20px -20px -20px;
  padding: 20px;
  background: #fafafa;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 12px 12px;

  .ant-comment {
    position: relative;
    padding: 16px;
    margin-bottom: 12px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #f0f0f0;

    &:last-child {
      margin-bottom: 0;
    }

    .ant-comment-avatar {
      margin-right: 12px;

      .ant-avatar {
        background: #1890ff;

        .anticon {
          font-size: 14px;
        }
      }
    }

    .ant-comment-content-author {
      margin-bottom: 8px;

      .ant-comment-content-author-name {
        font-weight: 500;
        font-size: 14px;
        color: #262626;
      }

      .ant-comment-content-author-time {
        color: #8c8c8c;
        font-size: 12px;
      }
    }

    .ant-comment-content-detail {
      font-size: 14px;
      color: #262626;
      line-height: 1.5;
    }

    .message-actions {
      margin-top: 12px;
      display: flex;
      gap: 12px;
      justify-content: flex-end;

      .ant-btn {
        padding: 0;
        border: none;
        height: auto;
        background: transparent;

        &:hover {
          color: #1890ff;
          background: transparent;
        }

        &.ant-btn-dangerous:hover {
          color: #ff4d4f;
        }

        .anticon {
          font-size: 14px;
        }
      }
    }
  }
`;

const ReplyInput = styled.div`
  margin-top: 20px;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #f0f0f0;

  .reply-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;

    .ant-avatar {
      background: #1890ff;

      .anticon {
        font-size: 14px;
      }
    }

    .user-name {
      font-weight: 500;
      font-size: 14px;
      color: #262626;
    }
  }

  .ant-input {
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    padding: 12px;
    margin-bottom: 12px;
    resize: none;

    &:hover,
    &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
    }

    &::placeholder {
      color: #bfbfbf;
    }
  }

  .reply-actions {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    .ant-btn {
      height: 32px;
      padding: 0 16px;
      border-radius: 6px;
      font-size: 14px;

      &.ant-btn-primary {
        background: #1890ff;
        border: none;

        &:hover {
          background: #40a9ff;
        }

        &:disabled {
          background: #f5f5f5;
          color: #bfbfbf;
        }
      }
    }
  }
`;

const InputSection = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;

  .input-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;

    .user-name {
      font-weight: 500;
    }

    .timestamp {
      color: rgba(0, 0, 0, 0.45);
      font-size: 12px;
    }
  }

  .tag-section {
    margin: 12px 0;

    .tag {
      background: #e6f7ff;
      border-color: #91d5ff;
      color: #1890ff;
      margin-right: 8px;
    }

    .site-tag-plus {
      background: #fff;
      border-style: dashed;
    }
  }

  .button-group {
    display: flex;
    gap: 8px;
  }
`;

const AddNoteButton = styled(Button)`
  &.ant-btn-primary {
    background: #ff5722;
    border-color: #ff5722;

    &:hover {
      background: #f4511e;
      border-color: #f4511e;
    }
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }

  .ant-modal-header {
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    padding: 16px 24px;
    text-align: center;
  }

  .ant-modal-body {
    padding: 24px;
  }

  .ant-modal-title {
    font-weight: 600;
    color: #262626;
  }

  .ant-modal-close {
    top: 16px;
  }
`;

const OriginalNoteCard = styled(NoteCard)`
  background: #f8f9fa;
  margin-bottom: 0;
  border: 1px solid #e6f7ff;

  .actions {
    display: none;
  }
`;

const EditInput = styled(Input.TextArea)`
  margin: 8px 0;
  border-radius: 8px;
  padding: 12px;
  resize: none;
  font-size: 14px;
  line-height: 1.6;
  border: 1px solid #d9d9d9;

  &:hover,
  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
  }
`;

const EditActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 12px;

  .ant-btn {
    height: 32px;
    padding: 0 16px;
    border-radius: 6px;
    font-size: 14px;

    &.ant-btn-primary {
      background: #1890ff;
      border: none;

      &:hover {
        background: #40a9ff;
      }
    }
  }
`;

const FloatingActionButton = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin: 20px auto;

  &.ant-btn {
    background: #1890ff;
    border: none;

    &:hover {
      background: #40a9ff;
    }

    .anticon {
      font-size: 24px;
    }
  }
`;

// Các interface định nghĩa kiểu dữ liệu
interface NoteProps {
  visible: boolean;
  onClose: () => void;
  userId: string;
  contextType: string;
  idType?: string;
}

interface IReplyContext {
  noteId: string;
  originalContent: string;
  originalUser: string;
  originalTimestamp: string;
  tags: string[];
}

const Note: React.FC<NoteProps> = ({ visible, onClose, userId, contextType, idType }) => {
  const dispatch = useAppDispatch();
  const listUserApproverOffer = useAppSelector(selectRecruitmentData)?.listUserApproverOffer;
  const currentUser = getUser();
  const { t } = useTranslation(['note']);
  const { control, handleSubmit, reset, getValues, setValue, watch } = useForm({
    defaultValues: {
      addNoteText: '',
      replyNoteText: '',
      editNotes: '',
    },
  });

  const addNoteTextValue = watch('addNoteText');
  const replyNoteTextValue = watch('replyNoteText');

  const [notes, setNotes] = useState<INote[]>([]);
  const [contextTypes, setContextTypes] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [expandedNoteId, setExpandedNoteId] = useState<string | null>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState<string | null>(null);
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null);
  const [editingTags, setEditingTags] = useState<string[]>([]);
  const [isReplying, setIsReplying] = useState(false);
  const [replyContext, setReplyContext] = useState<IReplyContext | null>(null);
  const [showAddNote, setShowAddNote] = useState(false);
  const [isMailFormVisible, setIsMailFormVisible] = useState(false);
  const [initialMessage, setInitialMessage] = useState('');
  const [note, setNote] = useState<any>();

  console.log(notes);

  useEffect(() => {
    if (visible && isPerformanceAccess()) {
      dispatch(getListUserApproverOffer({}));
    }
  }, [visible, dispatch]);

  useEffect(() => {
    if (visible) {
      fetchNotes();
    }
  }, [visible, userId]);

  const fetchNotes = async () => {
    try {
      setLoading(true);
      let response;
      if (contextType === 'PERFORMANCE_REVIEW') response = await notesApi.getNotes(userId);
      else response = await notesApi.getNotes(userId, contextType, idType);

      const notesData = response.data;
      setNotes(notesData);

      const uniqueContextTypes = Array.from(
        new Set(notesData.map((note: any) => note.contextType)),
      );
      setContextTypes(uniqueContextTypes as any);

      if (uniqueContextTypes.length > 0 && !activeTab) {
        setActiveTab(uniqueContextTypes[0] as any);
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setExpandedNoteId(null);
  };

  const handleNoteClick = (noteId: string) => {
    setExpandedNoteId(expandedNoteId === noteId ? null : noteId);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('vi-VN');
  };

  const handleEditClick = (note: INote) => {
    if (isEditing) return;
    setEditingNoteId(note.id);
    setIsEditing(true);

    const firstMessage = note.messages[0];
    if (firstMessage && firstMessage.notes.length > 0) {
      const content = firstMessage.notes[0].content;
      setValue('editNotes', content);
    } else {
      setValue('editNotes', '');
    }
    setEditingTags([...note.tags]);
  };

  const handleEditMessage = (message: any, noteId: string) => {
    if (isEditing) return;
    const messageId = `${message.user.id}-${message.content}-${message.timestamp}`;
    setValue('editNotes', message.content);
    setIsEditing(true);
    setEditingMessageId(messageId);
    setEditingNoteId(noteId);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
    setEditingNoteId(null);
    setEditingMessageId(null);
    setValue('editNotes', '');
    setEditingTags([]);
    setInputVisible(false);
  };

  const handleEditSubmit = async () => {
    const updatedContent = getValues('editNotes');
    if (!updatedContent.trim() || !editingNoteId) return;

    try {
      setSubmitting(true);
      const noteToUpdate = notes.find((note) => note.id === editingNoteId);
      if (!noteToUpdate) return;

      let updatedMessages = noteToUpdate.messages;

      if (editingMessageId) {
        const messageIndex = updatedMessages.findIndex((message) =>
          message.notes.some(
            (note) => `${message.user.id}-${note.content}-${note.timestamp}` === editingMessageId,
          ),
        );

        if (messageIndex === -1) return;

        const message = updatedMessages[messageIndex];
        if (message.user.id !== currentUser?.id) return;

        const noteIndex = message.notes.findIndex(
          (note) => `${message.user.id}-${note.content}-${note.timestamp}` === editingMessageId,
        );

        if (noteIndex === -1) return;

        message.notes[noteIndex] = {
          ...message.notes[noteIndex],
          content: updatedContent,
        };
      } else {
        if (!updatedMessages[0]?.notes?.[0]) return;
        updatedMessages[0].notes[0] = {
          ...updatedMessages[0].notes[0],
          content: updatedContent,
        };
      }

      await notesApi.updateNote(editingNoteId, {
        messages: updatedMessages,
        tags: editingTags,
      });

      if (currentUser?.id) {
        let response;
        if (contextType === 'PERFORMANCE_REVIEW') response = await notesApi.getNotes(userId);
        else response = await notesApi.getNotes(userId, contextType, idType);
        setNotes(response.data);
      }

      setIsEditing(false);
      setEditingMessageId(null);
      setEditingNoteId(null);
      setValue('editNotes', '');
      setEditingTags([]);
    } catch (error) {
      console.error('Error updating note:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteNote = async (noteId: string) => {
    try {
      setSubmitting(true);
      await notesApi.deleteNote(noteId);
      if (currentUser?.id) {
        let response;
        if (contextType === 'PERFORMANCE_REVIEW') response = await notesApi.getNotes(userId);
        else response = await notesApi.getNotes(userId, contextType, idType);
        setNotes(response.data);
      }
    } catch (error) {
      console.error('Error deleting note:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteMessage = async (messageToDelete: any) => {
    try {
      setSubmitting(true);
      const parentNote = notes.find((note) =>
        note.messages.some((message) =>
          message.notes.some(
            (noteItem) =>
              `${message.user.id}-${noteItem.content}-${noteItem.timestamp}` ===
              `${messageToDelete.user.id}-${messageToDelete.content}-${messageToDelete.timestamp}`,
          ),
        ),
      );
      if (!parentNote) return;

      // Xóa tất cả child note một cách đồng bộ
      const childNotes = getChildNotes(parentNote.id);
      await Promise.all(childNotes.map((childNote) => deleteNote(childNote.id)));

      let updatedMessages = parentNote.messages;

      const messageIndex = updatedMessages.findIndex(
        (message) => message.user.id === messageToDelete.user.id,
      );
      if (messageIndex === -1) return;

      const userNotes = updatedMessages[messageIndex].notes;
      const updatedNotes = userNotes.filter(
        (note) =>
          `${messageToDelete.user.id}-${note.content}-${note.timestamp}` !==
          `${messageToDelete.user.id}-${messageToDelete.content}-${messageToDelete.timestamp}`,
      );

      updatedMessages[messageIndex].notes = updatedNotes;
      if (updatedNotes.length === 0) {
        updatedMessages = updatedMessages.filter((_, index) => index !== messageIndex);
      }

      await notesApi.updateNote(parentNote.id, {
        messages: updatedMessages,
      });

      let response;
      if (contextType === 'PERFORMANCE_REVIEW') response = await notesApi.getNotes(userId);
      else response = await notesApi.getNotes(userId, contextType, idType);
      setNotes(response.data);
    } catch (error) {
      console.error('Error deleting note:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleReplyClick = (note: INote) => {
    if (isReplying && replyContext && replyContext.noteId === note.id) {
      handleReplyCancel();
    } else {
      setExpandedNoteId(note.id);
      setIsReplying(true);
      setReplyContext({
        noteId: note.id,
        originalContent: note.messages[0].notes[0].content,
        originalUser: note.messages[0].user.name,
        originalTimestamp: note.createdAt,
        tags: note.tags,
      });
      reset({ replyNoteText: '' });
    }
  };

  const handleReplyCancel = () => {
    reset({ replyNoteText: '' });
    setTags([]);
    setIsEditing(false);
    setEditingNoteId(null);
    setIsReplying(false);
    setReplyContext(null);
  };

  const handleReplySubmit = async () => {
    const replyText = getValues('replyNoteText');
    if (!replyText.trim() || !expandedNoteId) return;

    try {
      setSubmitting(true);
      const noteToUpdate = notes.find((note) => note.id === expandedNoteId);
      if (!noteToUpdate) return;

      let updatedMessages = noteToUpdate.messages;

      const currentUserGroupIndex = updatedMessages.findIndex(
        (message) => message.user.id === currentUser?.id,
      );

      const newNoteObj = {
        content: replyText,
        timestamp: new Date().toISOString(),
      };

      if (currentUserGroupIndex !== -1) {
        updatedMessages[currentUserGroupIndex].notes.push(newNoteObj);
      } else {
        updatedMessages.push({
          user: {
            id: currentUser?.id || userId,
            name: currentUser?.fullName || 'Unknown User',
          },
          notes: [newNoteObj],
        });
      }

      await notesApi.updateNote(expandedNoteId, {
        messages: updatedMessages,
      });

      let response;
      if (contextType === 'PERFORMANCE_REVIEW') response = await notesApi.getNotes(userId);
      else response = await notesApi.getNotes(userId, contextType, idType);
      setNotes(response.data);

      reset({ replyNoteText: '' });
      setIsReplying(false);
    } catch (error) {
      console.error('Error adding reply:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const flattenAndSortNotes = (messages: any[]) => {
    const allNotes = messages.flatMap((message) =>
      message.notes
        ? message.notes.map((note: any) => ({
            id: `${message.user.id}-${note.content}-${note.timestamp}`,
            user: message.user,
            content: note.content,
            timestamp: note.timestamp,
          }))
        : [],
    );

    return allNotes.sort((a, b) => {
      const timestampA = new Date(a.timestamp).getTime();
      const timestampB = new Date(b.timestamp).getTime();
      return timestampA - timestampB;
    });
  };

  const renderNotes = () => {
    const filteredNotes = notes.filter((note) => note.contextType === activeTab);
    const uniqueNotes = Array.from(new Set(filteredNotes.map((note) => note.id)))
      .map((id) => filteredNotes.find((note) => note.id === id))
      .filter((note) => note) as INote[];

    return uniqueNotes.map((note) => {
      const isThisNoteBeingReplied = isReplying && replyContext && replyContext.noteId === note.id;
      const shouldExpand =
        expandedNoteId === note.id || isThisNoteBeingReplied || (isEditing && editingMessageId);

      return (
        <NoteCard key={note.id}>
          {isEditing && editingNoteId === note.id && !editingMessageId ? (
            <div style={{ marginTop: '8px' }}>
              <div className="edit-header" style={{ marginBottom: '12px' }}>
                <Avatar size={36} icon={<UserOutlined />} />
                <span style={{ marginLeft: '12px', fontWeight: 500 }}>{currentUser?.fullName}</span>
              </div>
              <RichTextField key={`edit-${editingNoteId}`} control={control} name="editNotes" />

              <div className="tag-section" style={{ margin: '12px 0' }}>
                {editingTags.map((tag) => (
                  <Tag
                    key={tag}
                    closable
                    onClose={() => setEditingTags(editingTags.filter((t) => t !== tag))}
                    className="tag"
                  >
                    {tag}
                  </Tag>
                ))}
                {inputVisible ? (
                  <Input
                    type="text"
                    size="small"
                    style={{ width: 100 }}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onBlur={() => {
                      if (inputValue && !editingTags.includes(inputValue)) {
                        setEditingTags([...editingTags, inputValue]);
                      }
                      setInputValue('');
                      setInputVisible(false);
                    }}
                    onPressEnter={() => {
                      if (inputValue && !editingTags.includes(inputValue)) {
                        setEditingTags([...editingTags, inputValue]);
                      }
                      setInputValue('');
                      setInputVisible(false);
                    }}
                    autoFocus
                  />
                ) : (
                  <Tag onClick={() => setInputVisible(true)} className="site-tag-plus">
                    {t('note:addTags')}
                  </Tag>
                )}
              </div>

              <EditActions>
                <Button onClick={handleEditCancel}>{t('note:cancel')}</Button>
                <Button
                  type="primary"
                  onClick={handleEditSubmit}
                  disabled={!getValues('editNotes').trim()}
                  loading={submitting}
                >
                  {t('note:save')}
                </Button>
              </EditActions>
            </div>
          ) : (
            <div>
              <div className="note-header">
                <div className="user-info">
                  <Avatar size={36} icon={<UserOutlined />} />
                  <span className="user-name">
                    {note.messages.length > 0 && note.messages[0].user
                      ? note.messages[0].user.name
                      : 'Unknown User'}
                  </span>
                  <span className="timestamp">{formatDate(note.createdAt)}</span>
                  <div className="note-actions">
                    {note.messages.length > 0 &&
                      note.messages[0].user &&
                      note.messages[0].user.id === currentUser?.id && (
                        <>
                          {isPerformanceAccess() && (
                            <Tooltip
                              title={renderApproverStatus(note.noteApprover)}
                              placement="top"
                              overlayStyle={{ maxWidth: '400px' }}
                            >
                              <MailOutlined
                                style={{ marginRight: '8px' }}
                                onClick={() =>
                                  handleMailIconClick(note.messages[0].notes[0].content, note)
                                }
                              />
                            </Tooltip>
                          )}
                          <Button
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => handleEditClick(note)}
                          />
                          <Popconfirm
                            title={t('note:deleteNoteConfirmation')}
                            onConfirm={() => handleDeleteNote(note.id)}
                            okText={t('note:yes')}
                            cancelText={t('note:no')}
                          >
                            <Button type="text" icon={<DeleteOutlined />} danger />
                          </Popconfirm>
                        </>
                      )}
                    {!isEditing && (
                      <Button
                        type="text"
                        icon={<MessageOutlined />}
                        onClick={() => handleReplyClick(note)}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="note-content" onClick={() => !isEditing && handleNoteClick(note.id)}>
                <div
                  style={{ fontSize: '14px', color: '#262626', marginBottom: '4px' }}
                  dangerouslySetInnerHTML={{
                    __html: note.messages?.[0]?.notes?.[0]?.content || t('note:noContent'),
                  }}
                />
              </div>

              <div className="note-footer">
                {note.tags.map((tag) => (
                  <Tag key={tag} className="tag">
                    {tag}
                  </Tag>
                ))}
              </div>
            </div>
          )}

          {shouldExpand && (
            <ReplyList>
              <List
                itemLayout="horizontal"
                dataSource={flattenAndSortNotes(note.messages).slice(1)}
                renderItem={(message) => {
                  const messageId = `${message.user.id}-${message.content}-${message.timestamp}`;
                  return (
                    <Comment
                      key={messageId}
                      author={message.user.name}
                      avatar={<Avatar icon={<UserOutlined />} />}
                      content={
                        isEditing && editingMessageId === messageId ? (
                          <div style={{ marginTop: '8px' }}>
                            <RichTextField
                              key={`edit-${editingNoteId}`}
                              control={control}
                              name="editNotes"
                            />
                            <EditActions>
                              <Button onClick={handleEditCancel}>{t('note:cancel')}</Button>
                              <Button type="primary" onClick={handleEditSubmit}>
                                {t('note:save')}
                              </Button>
                            </EditActions>
                          </div>
                        ) : (
                          <>
                            <div
                              style={{ fontSize: '14px', color: '#262626', marginBottom: '4px' }}
                              dangerouslySetInnerHTML={{ __html: message.content }}
                            />
                            {message.user.id === currentUser?.id && !isEditing && (
                              <div className="message-actions">
                                <Button
                                  type="text"
                                  icon={<EditOutlined />}
                                  onClick={() => handleEditMessage(message, note.id)}
                                />
                                <Popconfirm
                                  title={t('note:deleteNote')}
                                  onConfirm={() => handleDeleteMessage(message)}
                                  okText={t('note:confirm')}
                                  cancelText={t('note:cancel')}
                                >
                                  <Button type="text" icon={<DeleteOutlined />} danger />
                                </Popconfirm>
                              </div>
                            )}
                          </>
                        )
                      }
                      datetime={formatDate(message.timestamp)}
                    />
                  );
                }}
              />
              {isThisNoteBeingReplied && !isEditing && (
                <ReplyInput>
                  <div className="reply-header">
                    <Avatar icon={<UserOutlined />} />
                    <span className="user-name">{currentUser?.fullName}</span>
                  </div>
                  <Controller
                    name="replyNoteText"
                    control={control}
                    render={({ field }) => <RichTextField {...field} control={control} />}
                  />
                  <div className="reply-actions">
                    <Button onClick={handleReplyCancel}>{t('note:cancel')}</Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit(handleReplySubmit)}
                      disabled={!replyNoteTextValue.trim()}
                      loading={submitting}
                    >
                      {t('note:addReply')}
                    </Button>
                  </div>
                </ReplyInput>
              )}
            </ReplyList>
          )}
        </NoteCard>
      );
    });
  };

  const renderApproverStatus = (noteApprover: any[] | undefined) => {
    if (!noteApprover || noteApprover.length === 0) {
      return 'No approvers';
    }

    return (
      <div>
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Approver Status:</div>
        {noteApprover.map((approver, index) => (
          <div key={index} style={{ marginBottom: '3px' }}>
            <span
              style={{
                color:
                  approver.status === 'approved'
                    ? '#52c41a'
                    : approver.status === 'pending'
                    ? '#faad14'
                    : '#f5222d',
              }}
            >
              {approver.status === 'approved' ? '✓ ' : approver.status === 'pending' ? '⌛ ' : '✗ '}
            </span>
            <b>{approver.fullName}</b> ({approver.email})
            {approver.status === 'approved' && approver.timestamp && (
              <span style={{ color: '#8c8c8c', fontSize: '12px', marginLeft: '5px' }}>
                at {new Date(approver.timestamp).toLocaleString('vi-VN')}
              </span>
            )}
          </div>
        ))}
      </div>
    );
  };

  const handleAddTag = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
      setInputValue('');
    }
    setInputVisible(false);
  };

  const handleAddNote = async () => {
    const addNoteText = getValues('addNoteText');
    if (!addNoteText.trim()) {
      messageAntd.error('Please enter a note');
      return;
    }

    if (tags.length === 0) {
      messageAntd.error('Please add at least one tag');
      return;
    }

    try {
      setSubmitting(true);
      const data: ICreateNoteRequest = {
        user_id: userId,
        messages: [
          {
            user: {
              id: currentUser?.id || userId,
              name: currentUser?.fullName || 'Unknown User',
            },
            notes: [
              {
                content: addNoteText,
                timestamp: new Date().toISOString(),
              },
            ],
          },
        ],
        contextType: contextType,
        idType: idType || null,
        tags: tags,
      };

      await notesApi.createNote(data as any);
      reset({ addNoteText: '', replyNoteText: replyNoteTextValue });
      setTags([]);
      setShowAddNote(false);
      fetchNotes();
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleToggleAddNote = () => {
    if (contextType === 'PERFORMANCE_REVIEW' && activeTab !== 'PERFORMANCE_REVIEW') {
      setActiveTab('PERFORMANCE_REVIEW');
      if (!contextTypes.includes('PERFORMANCE_REVIEW')) {
        setContextTypes([...contextTypes, 'PERFORMANCE_REVIEW']);
      }
    }

    setShowAddNote(!showAddNote);
    if (!showAddNote) {
      reset({ addNoteText: '', replyNoteText: replyNoteTextValue });
      setTags([]);
      setInputVisible(false);
      setInputValue('');
    }
  };

  const getChildNotes = (noteId: string) => {
    const childNotes = notes.filter((note) => note.parent_note_id === noteId);
    return childNotes;
  };

  const deleteNote = async (noteId: string) => {
    try {
      await notesApi.deleteNote(noteId);
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  const handleMailIconClick = (noteContent: string, note: INote) => {
    setIsMailFormVisible(true);
    setInitialMessage(noteContent);
    setNote(note);
  };

  return (
    <>
      <Modal title={t('note:notes')} open={visible} onCancel={onClose} footer={null} width={800}>
        <div>
          <div style={{ marginBottom: '16px' }}>
            <TabContainer>
              {contextTypes.map((type) => (
                <TabButton
                  key={type}
                  isActive={activeTab === type}
                  onClick={() => setActiveTab(type)}
                >
                  {type}
                </TabButton>
              ))}
            </TabContainer>
          </div>

          {renderNotes()}
        </div>
        {!isEditing && !showAddNote && (
          <FloatingActionButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleToggleAddNote}
          />
        )}
        <div style={{ marginBottom: '24px' }}>
          {!isEditing && showAddNote && (
            <InputSection>
              <div className="input-header">
                <Avatar icon={<UserOutlined />} />
                <span className="user-name">{currentUser?.fullName}</span>
                <span className="timestamp">{new Date().toLocaleString()}</span>
              </div>

              <Controller
                name="addNoteText"
                control={control}
                render={({ field }) => <RichTextField {...field} control={control} />}
              />

              <div className="tag-section">
                {tags.map((tag) => (
                  <Tag
                    key={tag}
                    closable
                    onClose={() => setTags(tags.filter((t) => t !== tag))}
                    className="tag"
                  >
                    {tag}
                  </Tag>
                ))}
                {inputVisible ? (
                  <Input
                    type="text"
                    size="small"
                    style={{ width: 100 }}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onBlur={handleAddTag}
                    onPressEnter={handleAddTag}
                    autoFocus
                  />
                ) : (
                  <Tag onClick={() => setInputVisible(true)} className="site-tag-plus">
                    {t('note:addTags')}
                  </Tag>
                )}
              </div>

              <div className="button-group">
                <AddNoteButton
                  type="primary"
                  onClick={handleAddNote}
                  loading={submitting}
                  disabled={!addNoteTextValue.trim()}
                >
                  {t('note:addNote')}
                </AddNoteButton>
                <Button onClick={handleToggleAddNote}>{t('note:cancel')}</Button>
              </div>
            </InputSection>
          )}
        </div>
      </Modal>
      {isMailFormVisible && note && (
        <MailForm
          onCancel={() => setIsMailFormVisible(false)}
          onSuccess={fetchNotes}
          initialMessage={initialMessage}
          note={note}
          listUserApproverOffer={listUserApproverOffer}
        />
      )}
    </>
  );
};

export default Note;
