import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Modal, Popconfirm, Row, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useAppDispatch } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import { TextAreaField } from 'components/FormFields';
import { DATETIME_FORMAT } from 'constants/commons';
import {
  JobStatus,
  PermissionUser,
  RecruitJobDetailType,
  isInterviewer,
} from 'features/recruitment/jobs/components/types';
import {
  createCandidate,
  updateCandidate,
  updateCandidateNoteInterviewer,
  updateJobNote,
} from 'features/recruitment/recruitmentAction';
import moment from 'moment';
import React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import CustomButton from 'styles/buttonStyled';
import { getUser } from 'utils/auth';

const Notes = ({
  data,
  handleChange,
}: {
  data?: RecruitJobDetailType | any;
  handleChange?: (value: any) => void;
}) => {
  const { t } = useTranslation(['onboard', 'recruitment']);
  const [visible, setVisible] = useState(false);
  const [notes, setNotes] = useState<
    { userName: string; userId: string; note: string; createDate: string }[]
  >([]);
  const { handleSubmit, control, reset } = useForm();
  const [isEdit, setIsEdit] = useState([]);
  const dispatch = useAppDispatch();
  const { id, jobId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (data?.jobNotes) {
      setNotes(data.jobNotes);
    }
  }, [data]);

  const handleSave = (dt: any) => {
    const body = {
      ...dt,
      candidateNotes: dt?.jobNotes || [],
      skills: dt?.skills?.map((i) => ({ ...i, rate: `${i.rate}/10` })),
    };

    if (id === 'draft') {
      dispatch(
        createCandidate({
          body,
          callBack: () => {
            history.push('/recruitment/candidates');
          },
        }),
      );
    } else {
      if (isInterviewer()) {
        dispatch(
          updateCandidateNoteInterviewer({
            id,
            body,
          }),
        );
      } else if (data?.status) {
        dispatch(
          updateJobNote({
            id,
            body,
          }),
        );
      } else
        dispatch(
          updateCandidate({
            id,
            body,
          }),
        );
    }
  };

  const onSubmit = (newData: { note: string }, index?: any) => {
    const newNote = {
      ...newData,
      userName: getUser()?.fullName,
      userId: getUser()?.id,
      createDate: moment(),
    };
    let newNotes: any = [];

    if (Number.isInteger(index)) {
      newNotes = [...notes];
      newNotes[index] = newNote;
    } else {
      newNotes = [...notes, newNote];
    }
    setNotes(newNotes);
    setVisible(false);
    reset();
    handleChange &&
      handleChange({
        ...data,
        jobNotes: newNotes,
        notes: newNotes,
      });
    handleSave({
      ...data,
      jobNotes: newNotes,
      notes: newNotes,
    });
  };

  const handleDelete = (index: number) => {
    const newNotes = notes.filter((_, i) => i !== index);
    setNotes(newNotes);
    handleChange &&
      handleChange({
        ...data,
        jobNotes: newNotes,
        notes: newNotes,
      });
    handleSave({
      ...data,
      jobNotes: newNotes,
      notes: newNotes,
    });
  };

  const canEditNotes = getUser()?.permissions?.includes(PermissionUser.RECRUIT_JOB_CREATED);
  // !(
  //   data?.status === JobStatus.APPROVED //|| data?.status === JobStatus.WAITING_FOR_APPROVAL
  // );

  const canEdit = (userId: any, index: number) => {
    if (userId !== getUser()?.id) {
      return true;
    }
    return !isEdit?.includes(index);
  };

  const handleEdit = (index: number) => {
    if (isEdit.includes(index)) {
      setIsEdit(isEdit.filter((i) => i !== index));
    } else {
      setIsEdit([...isEdit, index]);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {canEditNotes && (
          <CustomButton
            onClick={() => setVisible(true)}
            style={{ marginBottom: '1rem' }}
            icon={<PlusOutlined />}
            disabled={!canEditNotes}
          >
            {t('recruitment:add_note')}
          </CustomButton>
        )}
      </Col>
      <Col span={24}>
        {notes.map(({ note, userName, userId, createDate }, index) => (
          <Row key={index} gutter={[16, 16]} style={{ marginBottom: 25 }}>
            <Col span={4}>
              <EmployeeColumn avatar={userName} fullName={userName} />
            </Col>
            <Col
              span={14}
              style={{
                border: '1px solid #d9d9d9',
                background: 'white',
                borderRadius: 10,
                padding: 5,
              }}
            >
              <TextArea
                style={{ height: 150, color: 'black', border: 'none' }}
                disabled={canEdit(userId, index)}
                value={note}
                onChange={(e) => {
                  const newData = { note: e.target.value };
                  if (canEditNotes && userId !== getUser()?.id) return;
                  const newNote = {
                    ...newData,
                    userName: getUser()?.fullName,
                    userId: getUser()?.id,
                    createDate: moment(),
                  };
                  let newNotes: any = [];

                  if (Number.isInteger(index)) {
                    newNotes = [...notes];
                    newNotes[index] = newNote;
                  } else {
                    newNotes = [...notes, newNote];
                  }
                  setNotes(newNotes);
                  setVisible(false);
                  reset();
                  handleChange &&
                    handleChange({
                      ...data,
                      jobNotes: newNotes,
                      notes: newNotes,
                    });
                }}
              />
              <div
                style={{ height: 1, background: '#d9d9d9', width: '100%', marginBottom: 5 }}
              ></div>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <span>{createDate ? moment(createDate).format(DATETIME_FORMAT) : ''}</span>

                <div>
                  {canEditNotes && userId === getUser()?.id && (
                    <>
                      <Tooltip title={t('action:edit')}>
                        <EditOutlined
                          style={{ marginRight: 8, cursor: 'pointer' }}
                          onClick={() => {
                            handleEdit(index);
                            !canEdit(userId, index) &&
                              handleSave({
                                ...data,
                              });
                          }}
                        />
                      </Tooltip>
                      <Popconfirm
                        key={index}
                        title={t('onboard:are_you_sure_to_delete_this')}
                        onConfirm={() => {
                          handleDelete(index);
                        }}
                        okText={t('Yes')}
                        cancelText={t('No')}
                        placement="topRight"
                      >
                        <Tooltip title={t('action:delete')}>
                          <DeleteOutlined style={{ cursor: 'pointer' }} />
                        </Tooltip>
                      </Popconfirm>
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col span={6}></Col>
          </Row>
        ))}
      </Col>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={handleSubmit(onSubmit)}
        okText={t('action:add')}
        title={t('recruitment:add_note')}
        destroyOnClose
      >
        <form>
          <div>
            <label>{t('recruitment:notes')}</label>
            <TextAreaField control={control} name="note" />
          </div>
        </form>
      </Modal>
    </Row>
  );
};

export default Notes;
