import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, InputNumber, Popconfirm, Select } from 'antd';
import { selectFormatDate, selectFormatMonth } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DatePickerField } from 'components/FormFields';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { MONTH_YEAR_FORMAT } from 'constants/payroll';
import {
  getAllAllowanceListForUser,
  getAllAllowanceUser,
  updateAllowanceUser,
} from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import { DateType } from 'hrm-common/extensions/enums/personel/declare.enums';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { removeAccents } from 'utils/text';

const { Option } = Select;

const AllowanceTable = (props: any) => {
  const { employeeData, callBack, monthYear } = props;

  const { t } = useTranslation([
    'menu',
    'action',
    'overtime',
    'timesheet',
    'employee',
    'masterConfig',
    'leaveOff',
  ]);
  const dispatch = useAppDispatch();
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const formatDate = useAppSelector(selectFormatDate);
  const formatMonth = useAppSelector(selectFormatMonth);
  const employee = useAppSelector(selectEmployee);
  const dataAssignAllowance = employee?.dataAssignAllowance?.result;
  const statusConfirmAllowance = employee?.dataAssignAllowance?.status;
  const dataListAllowance = employee?.dataListAllowance?.result;
  const loadingAllowance = employee?.loadingAllowance;
  const [data, setData] = useState<any>([]);
  const [dataOri, setDataOri] = useState<any>([]);
  const [errMsg, setErrMsg] = useState<any>('');
  const [errList, setErrList] = useState<any>([]);
  const { control } = useForm();
  const [month, setMonth] = useState(monthYear ?? moment().format(MONTH_YEAR_FORMAT));

  const isDataDisabled = (record: any) => {
    return statusConfirmAllowance === 'approved';
  };

  useEffect(() => {
    if (employeeData?.id) {
      dispatch(getAllAllowanceListForUser({ month }));
      dispatch(getAllAllowanceUser({ userId: employeeData?.id, monthYear: month }));
    }
  }, [dispatch, employeeData?.id, month]);

  useEffect(() => {
    if (dataAssignAllowance?.length)
      setData(dataAssignAllowance?.map((item: any, index: any) => ({ ...item, key: index })));
    else setData([]);
  }, [dataAssignAllowance]);

  const checkDateType = (value: any) => {
    switch (value) {
      case DateType.DATE:
        return 'date';
      case DateType.MONTH:
        return 'month';
      case DateType.YEAR:
        return 'year';
      case DateType.QUARTER:
        return 'quarter';
      default:
        return 'date';
    }
  };

  const disabledDate = (current: moment.Moment | null) => {
    if (!current) return false;

    const startOfMonth = moment(month).startOf('month');
    return current.isBefore(startOfMonth);
  };

  const menu = [
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      handleClick: (value: any) => {
        let copyArr = JSON.parse(JSON.stringify(data));
        copyArr.splice(
          copyArr.findIndex((item: any) => item?.key === value?.key),
          1,
        );
        setData(copyArr?.map((item: any, index: any) => ({ ...item, key: index })));
      },
    },
  ];

  const COLUMNS: any = [
    {
      title: t('employee:bonus_information_fields.col_id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <div>{record?.key + 1}</div>;
      },
    },
    {
      title: t('employee:allowance_information_fields.allowance_name'),
      dataIndex: 'type',
      key: 'type',
      width: 150,
      minWidth: 150,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Select
            showSearch
            onChange={(value: any) => {
              const idHistoryAllowance = dataListAllowance
                .filter((item: any) => item.name === value)
                .map((item: any) => item.id)[0];

              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex
                  ? { ...obj, name: value, allowanceHistoryId: idHistoryAllowance }
                  : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex
                  ? { ...obj, name: value, allowanceHistoryId: idHistoryAllowance }
                  : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            defaultValue={record?.name ?? null}
            style={{ width: '100%' }}
            filterOption={(input, option) => {
              return removeAccents(option!.children as unknown as string)
                ?.toLowerCase()
                ?.includes(removeAccents(input)?.toLowerCase());
            }}
            disabled={isDataDisabled(record)}
          >
            {dataListAllowance?.map((item: any) => (
              <Option key={item?.id} value={item?.name}>
                {item?.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: t('employee:allowance_information_fields.is_pay_taxes'),
      dataIndex: 'isTaxed',
      key: 'isTaxed',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const selected = dataListAllowance?.find((item: any) => item?.name === record?.name);
        return (
          <Checkbox disabled checked={selected ? selected.isTaxed : record.isTaxed}></Checkbox>
        );
      },
    },
    {
      title: t('employee:allowance_information_fields.gross'),
      dataIndex: 'gross',
      key: 'gross',
      width: 100,
      align: 'center',
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        const selected = dataListAllowance?.find((item: any) => item?.name === record?.name);
        return (
          <Checkbox disabled checked={selected ? selected.isGross : record.isGross}></Checkbox>
        );
      },
    },
    {
      title: t('employee:allowance_information_fields.is_calculate_by'),
      dataIndex: 'isDate',
      key: 'isDate',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Select
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) => {
                return objIndex === trueIndex
                  ? {
                      ...obj,
                      type: value,
                      startDate: obj?.startDate
                        ? checkDateType(value) === 'month'
                          ? moment(obj?.startDate).startOf(value)
                          : checkDateType(value) === 'year'
                          ? moment(obj?.startDate).startOf(value)
                          : checkDateType(value) === 'quarter'
                          ? moment(obj?.startDate).startOf(value)
                          : moment(obj?.startDate)
                        : null,
                      endDate: obj?.endDate
                        ? checkDateType(value) === 'month'
                          ? moment(obj?.endDate).endOf(value)
                          : checkDateType(value) === 'year'
                          ? moment(obj?.endDate).endOf(value)
                          : checkDateType(value) === 'quarter'
                          ? moment().quarter(moment(obj?.endDate).quarter()).endOf('quarter')
                          : moment(obj?.endDate)
                        : null,
                    }
                  : obj;
              });
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, type: value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            value={record?.type ?? DateType.DATE}
            style={{ width: '100%' }}
            disabled={isDataDisabled(record)}
          >
            <Select.Option value={DateType.DATE}>
              {t('employee:allowance_information_fields.daily')}
            </Select.Option>
            <Select.Option value={DateType.MONTH}>
              {t('employee:allowance_information_fields.monthly')}
            </Select.Option>
            <Select.Option value={DateType.QUARTER}>
              {t('employee:allowance_information_fields.quarterly')}
            </Select.Option>
            <Select.Option value={DateType.YEAR}>
              {t('employee:allowance_information_fields.yearly')}
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: t('timesheet:start_date'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <DatePicker
            allowClear={false}
            picker={checkDateType(record?.type)}
            format={
              checkDateType(record?.type) === 'date'
                ? formatDate
                : checkDateType(record?.type) === 'month'
                ? formatMonth
                : checkDateType(record?.type) === 'quarter'
                ? 'YYYY-Qo'
                : 'YYYY'
            }
            onChange={(value: any) => {
              const startDate = value
                ? checkDateType(record?.type) === 'date'
                  ? moment(value)
                  : moment(value).startOf(record?.type)
                : null;

              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, startDate: startDate, endDate: null } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, startDate: startDate, endDate: null } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            defaultValue={record?.startDate ? moment(record?.startDate) : undefined}
            style={{ width: '100%' }}
            disabledDate={(current) => disabledDate(current)}
            disabled={isDataDisabled(record)}
          ></DatePicker>
        );
      },
    },
    {
      title: t('timesheet:End_date'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <DatePicker
            disabledDate={(currentMonth) => moment(record?.startDate) > currentMonth}
            format={
              checkDateType(record?.type) === 'date'
                ? formatDate
                : checkDateType(record?.type) === 'month'
                ? formatMonth
                : checkDateType(record?.type) === 'quarter'
                ? 'YYYY-Qo'
                : 'YYYY'
            }
            picker={checkDateType(record?.type)}
            onChange={(value: any) => {
              const endDate = value
                ? checkDateType(record?.type) === 'date'
                  ? moment(value)
                  : moment(value).endOf(record?.type)
                : null;

              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, endDate: endDate } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, endDate: endDate } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            value={record?.endDate ? moment(record?.endDate) : undefined}
            defaultPickerValue={moment(month)}
            style={{ width: '100%' }}
            disabled={statusConfirmAllowance === 'approved' || isDataDisabled(record)}
          ></DatePicker>
        );
      },
    },
    {
      title: t('employee:allowance_information_fields.money'),
      dataIndex: 'money',
      key: 'money',
      width: 200,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <CustomInputNumber
            max={2000000000}
            min={0}
            style={{ width: '100%' }}
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, money: value ?? 0 } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, money: value ?? 0 } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            defaultValue={text}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
            disabled={isDataDisabled(record)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (statusConfirmAllowance !== 'approved') {
      COLUMNS.push({
        title: t('employee:employee_information_fields.action'),
        dataIndex: 'action',
        key: 'action',
        width: 60,
        minWidth: 60,
        align: 'center',
        render: function (text: any, record: any, index: any) {
          return <MenuAction menu={menu} data={record} />;
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [COLUMNS, statusConfirmAllowance, month]);

  const onAddRow = () => {
    if (data?.length >= 0) {
      const currentMonth = moment().format('YYYY-MM');
      const selectedMonth = moment(month).format('YYYY-MM');
      const startDate = currentMonth === selectedMonth ? moment() : moment(month).startOf('month');

      const newList = data.concat({
        startDate,
        key: data?.length,
        endDate: null,
        money: 0,
        type: DateType.DATE,
        name: dataListAllowance[0].name,
        isChange: null,
        isGross: dataListAllowance[0].isGross,
        isTaxed: dataListAllowance[0].isTaxed,
        allowanceHistoryId: dataListAllowance[0].id,
      });
      setData(newList);
    }
  };

  const onSave = () => {
    const updateData = data?.map((item: any) => {
      const { key, ...rest } = item;
      return {
        ...rest,
        monthYear: month,
        startDate: item?.startDate ? moment(item?.startDate)?.format('YYYY-MM-DD') : null,
        endDate: item?.endDate ? moment(item?.endDate)?.format('YYYY-MM-DD') : null,
      };
    });

    const deleteData = dataAssignAllowance
      ?.filter((item: any) => !updateData?.some((i: any) => i?.id === item?.id))
      .map((item: any) => {
        const { key, ...rest } = item;
        return {
          ...rest,
          startDate: item?.startDate ? moment(item?.startDate)?.format('YYYY-MM-DD') : null,
          endDate: item?.endDate ? moment(item?.endDate)?.format('YYYY-MM-DD') : null,
          deleteFlag: true,
        };
      });

    dispatch(
      updateAllowanceUser({
        id: employeeData?.id,
        data: [...updateData, ...deleteData],
        callBack: () => {
          dispatch(getAllAllowanceUser({ userId: employeeData?.id, monthYear: month }));
          callBack && callBack();
        },
      }),
    );
  };

  const handleChangeMonth = (value: Moment | null) => {
    if (value) {
      setMonth(moment(value).format(MONTH_YEAR_FORMAT));
    }
  };

  return (
    <div style={{ backgroundColor: '#fff', padding: 10, display: 'grid' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DatePickerField
          name="monthYear"
          control={control}
          picker="month"
          allowClear={false}
          value={moment(month)}
          defaultValue={moment(month)}
          onChange={handleChangeMonth}
          // disabledDate={(currentMonth) => moment() < currentMonth}
        />
        {statusConfirmAllowance !== 'approved' && dataListAllowance?.length && (
          <CustomButton icon={<PlusOutlined />} onClick={onAddRow}>
            {t('action:add_new')}
          </CustomButton>
        )}
      </div>
      <div style={{ overflow: 'scroll' }}>
        <ITVTable
          height={350}
          loading={loadingAllowance}
          columns={COLUMNS}
          data={data}
          isRowSelect={false}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
          errorList={errList}
        />
      </div>
      {errMsg && (
        <div style={{ color: 'red', height: 80, overflowY: 'scroll' }}>
          {errMsg?.flat(1)?.map((item: any, i: any, arr: any) => {
            let divider = i < arr.length - 1 && <div></div>;
            return (
              <span key={i}>
                <div>{item?.name}</div>
                <ul style={{ listStylePosition: 'inside' }}>
                  {item?.message?.map((childItem: any) => (
                    <li style={{ listStyleType: 'disc' }}>{childItem}</li>
                  ))}
                </ul>
                {divider}
              </span>
            );
          })}
        </div>
      )}
      {errMsg && (
        <div style={{ color: 'red', height: 80, overflowY: 'scroll' }}>
          {errMsg?.flat(1)?.map((item: any, i: any, arr: any) => {
            let divider = i < arr.length - 1 && <div></div>;
            return (
              <span key={i}>
                <div>{item?.name}</div>
                <ul style={{ listStylePosition: 'inside' }}>
                  {item?.message?.map((childItem: any) => (
                    <li style={{ listStyleType: 'disc' }}>{childItem}</li>
                  ))}
                </ul>
                {divider}
              </span>
            );
          })}
        </div>
      )}
      {statusConfirmAllowance !== 'approved' && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Popconfirm
            title={t('leaveOff:modal_item.are_you_sure_save')}
            onConfirm={() => {
              setErrList([]);
              setErrMsg(null);
              onSave();
            }}
            okText={t('Yes')}
            cancelText={t('No')}
            placement="topRight"
          >
            <CustomButton>{t('action:save')}</CustomButton>
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

export default AllowanceTable;

const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
    // padding-right:1px;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
