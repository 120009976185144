import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button, Input, message, Spin } from 'antd';
import {
  PaperClipOutlined,
  CloseOutlined,
  MessageOutlined,
  AudioOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { getToken, getUser } from '../../utils/auth';
import useSpeechRecognition from '../../hooks/useSpeechRecognition';
import StepMessage from 'features/chatDisplay/components/StepMessage';
import TableLeverOff from 'features/chatDisplay/components/TableLeverOff';
import ButtonApproverChat from 'features/chatDisplay/components/ButtonApproverChat';
import AvatarUser from 'components/AvatarBig';
import { useSelector } from 'react-redux';
import { KEY_LEAVE_OFF_CHAT } from 'constants/employee';
import stopButton from '../../assets/images/stopbutton.png';
import HistoryChatIcon from 'assets/icons/historyChatIcon.png';
import i18n from 'app/i18n';
import UploadFileChatAI from 'features/chatDisplay/components/UploadFileChatAI';

const { TextArea } = Input;

interface Message {
  type: 'bot' | 'user';
  content: string;
  chatName?: string;
  images?: any;
}

const API_BASE_URL = process.env.REACT_APP_CHAT_AI_BASE_URL;

const authToken = getToken();

const MAX_IMAGES = 1;

let chatIdResponse = '';

export const ChatBox: React.FC = () => {
  const { t } = useTranslation(['moduleSelection', 'ai_chatbox']);
  const [isOpen, setIsOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({
    x: window.innerWidth - 84,
    y: window.innerHeight - 84,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [wasDragging, setWasDragging] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const location = useLocation();
  const history = useHistory();
  const [isHideChatbox, setIsHideChatbox] = useState(false);
  const userName = useSelector((state: { auth: { user: { fullName: string } | null } }) =>
    state.auth.user ? state.auth.user.fullName : '',
  );
  const arrayWordFullName = userName.split(' ');

  const latestMessageRef = useRef<HTMLDivElement | null>(null);
  const scrollToLatestMessage = () => {
    latestMessageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  const [isBotAnswering, setIsBotAnswering] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadMenuRef = useRef<HTMLDivElement>(null);
  const uploadButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (
      location.pathname === '/module-selection' ||
      location.pathname === '/module-selection-detail' ||
      location.pathname === '/chat-display'
    ) {
      setIsHideChatbox(true);
    } else {
      setIsHideChatbox(false);
    }
  }, [location]);

  const [messages, setMessages] = useState<Message[]>([
    {
      type: 'bot',
      content: t('chatDisplay.welcomeMessage', { ns: 'ai_chatbox' }),
    },
  ]);
  const [inputValue, setInputValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [showUploadOptions, setShowUploadOptions] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [controller, setController] = useState<AbortController | null>(null);

  const [suggestedQuestions] = useState([
    t('chatDisplay.suggestedQuestion1', { ns: 'ai_chatbox' }),
    t('chatDisplay.suggestedQuestion2', { ns: 'ai_chatbox' }),
    t('chatDisplay.suggestedQuestion3', { ns: 'ai_chatbox' }),
    t('chatDisplay.suggestedQuestion4', { ns: 'ai_chatbox' }),
    t('chatDisplay.suggestedQuestion5', { ns: 'ai_chatbox' }),
  ]);

  const [listChatHistory, setListChatHistory] = useState<Message[]>([]);
  const [isShowChatHistory, setIsShowChatHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatHistoryDetailData, setChatHistoryDetailData] = useState<any>(null);

  const fetchChatHistory = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/chat-ai/chat-history`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setListChatHistory(response?.data?.data?.result);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchChatHistory();
  }, [fetchChatHistory, isShowChatHistory]);

  useEffect(() => {
    if (!isShowChatHistory) {
      const timer = setTimeout(() => {
        scrollToLatestMessage();
      }, 100); // Add a slight delay if needed
      return () => clearTimeout(timer);
    }
  }, [messages, isShowChatHistory]);

  const openChatBox = () => {
    if (!isDragging) {
      setIsOpen(true);
    }
  };

  let clickTimeout: NodeJS.Timeout | null = null;

  const handleMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsDragging(true);
    setWasDragging(false);
    if (buttonRef.current) {
      const boundingRect = buttonRef.current.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - boundingRect.left,
        y: e.clientY - boundingRect.top,
      });
    }
    if (clickTimeout) {
      clearTimeout(clickTimeout);
    }
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging && buttonRef.current) {
        e.preventDefault();
        setWasDragging(true);
        const newX = e.clientX - dragOffset.x;
        const newY = e.clientY - dragOffset.y;

        const maxX = window.innerWidth - buttonRef.current.offsetWidth;
        const maxY = window.innerHeight - buttonRef.current.offsetHeight;

        setButtonPosition({
          x: Math.max(0, Math.min(newX, maxX)),
          y: Math.max(0, Math.min(newY, maxY)),
        });
      }
    },
    [isDragging, dragOffset],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    if (!wasDragging) {
      setIsOpen(true);
    }
  }, [wasDragging]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (buttonRef.current) {
        const maxX = window.innerWidth - buttonRef.current.offsetWidth;
        const maxY = window.innerHeight - buttonRef.current.offsetHeight;
        setButtonPosition((prev) => ({
          x: Math.min(prev.x, maxX),
          y: Math.min(prev.y, maxY),
        }));
      }
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const callChatGPTAPI = async (
    message: string,
    callback: (answer: string, queryResult?: any) => void,
  ) => {
    if (isSending) return; // Prevent sending if already sending
    setIsSending(true);
    const id = setTimeout(() => {
      setIsSending(false);
      setTimeoutId(null);
    }, 60000); // 60 seconds timeout
    setTimeoutId(id);
    const abortController = new AbortController();
    setController(abortController);

    try {
      const requestData = {
        question: message,
        images: previewImages.map((img) => ({
          type: 'image',
          image_url: { url: img },
        })),
        chatId: chatIdResponse || chatHistoryDetailData?.id,
      };

      const response = await fetch(`${API_BASE_URL}/query`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          userId: getUser().id,
          lang: i18n.language,
        },
        body: JSON.stringify(requestData),
        signal: abortController.signal,
      });
      const data = await response.json();
      const answer = data.answer || 'No response';
      const queryResult = data?.data;
      callback(answer, queryResult);
    } catch (error: any) {
      if (error?.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Error fetching data:', error);
        callback('Error fetching data');
      }
    } finally {
      clearTimeout(id);
      setIsSending(false);
      setTimeoutId(null);
    }
  };

  const handleSendMessage = async (message?: string) => {
    if (isSending) return;
    const messageToSend = typeof message === 'string' ? message : inputValue;
    if (!previewImages.length && (!messageToSend || !messageToSend.trim())) return;

    setMessages((prev) => [
      ...prev,
      { type: 'user', content: messageToSend, images: previewImages },
      { type: 'bot', content: '...' },
    ]);
    setIsBotAnswering(true);
    setInputValue('');
    setSelectedFiles([]);
    setPreviewImages([]);
    setShowUploadOptions(false);

    // Reset input file
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    handleSaveMessage(messageToSend, 'user', undefined, (success) => {
      if (success) {
        setIsBotAnswering(true);
        callChatGPTAPI(messageToSend, (botResponse, queryResult) => {
          setIsBotAnswering(false);
          setMessages((prev) => [
            ...prev.slice(0, -1), // Remove the loading message
            { type: 'bot', content: botResponse },
          ]);
          if (!['No response', 'Error fetching data'].includes(botResponse)) {
            handleSaveMessage(botResponse, 'bot', queryResult, (success) => {
              if (!success) {
                console.error('Failed to save message');
              }
            });
          }
        });
      }
    });
  };

  const { startRecognition } = useSpeechRecognition(setInputValue);

  const handleShowUploadOptions = () => {
    setShowUploadOptions(!showUploadOptions);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const filesArray = Array.from(files);

      if (selectedFiles.length + filesArray.length > MAX_IMAGES) {
        message.warning(
          t('message_max_image', {
            maxfile: MAX_IMAGES,
          }),
        );
        return;
      }

      setSelectedFiles((prev) => [...prev, ...filesArray]);

      filesArray.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            setPreviewImages((prev) => [...prev, reader.result as string]);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleRemoveImage = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    setPreviewImages((prev) => prev.filter((_, i) => i !== index));

    // Reset input file để có thể chọn lại file đã xóa
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileUpload = () => {
    if (selectedFiles.length >= MAX_IMAGES) {
      message.warning(
        t('message_max_image', {
          maxfile: MAX_IMAGES,
        }),
      );
      return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    setShowUploadOptions(false);
  };

  const formatMessageContent = (content: string) => {
    // Match steps like '1. Step description'
    const stepRegex = /\d+\.\s[^\d]+/g;
    return content
      .split(stepRegex)
      .filter(Boolean)
      .map((part, index) => (
        <span key={index} style={{ display: 'block', marginBottom: '8px' }}>
          {part.trim()}
        </span>
      ));
  };
  const handleRemove = () => {
    if (controller) {
      controller.abort();
      setController(null);
    }
    setIsSending(false);
    setMessages((prevMessages) =>
      prevMessages.filter((message) => !(message.type === 'bot' && message.content === '...')),
    );
  };

  const bubble = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

  const BubbleLoader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 40px;

    & > div {
      width: 7px;
      height: 7px;
      margin: 3px;
      background-color: #d1d1d1;
      border-radius: 50%;
      display: inline-block;
      animation: ${bubble} 1.4s infinite ease-in-out both;
    }

    & > div:nth-child(1) {
      animation-delay: -0.32s;
    }

    & > div:nth-child(2) {
      animation-delay: -0.16s;
    }
  `;

  const renderMessageWithLinks = (content: string) => {
    // Tạo một div tạm thởi để parse HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    // Chuyển đổi HTML thành text có định dạng
    const textContent = tempDiv.textContent || tempDiv.innerText;

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = textContent.match(urlRegex);

    if (!matches) return <div dangerouslySetInnerHTML={{ __html: content }} />;

    const currentDomain = window.location.origin;

    // Clean URLs and create buttons
    const cleanAndCreateButtons = Array.from(new Set(matches)).map((url, index) => {
      const path = new URL(url).pathname;
      const fullPath = currentDomain + path;

      return (
        <LinkButton key={index} onClick={() => history.push(path.replace(/[\W_]+$/, ''))}>
          Go to page
        </LinkButton>
      );
    });

    return (
      <MessageWrapper>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <ButtonsContainer>{cleanAndCreateButtons}</ButtonsContainer>
      </MessageWrapper>
    );
  };
  if (!isOpen && !isHideChatbox) {
    return (
      <ChatButton
        ref={buttonRef}
        style={{
          left: buttonPosition.x,
          top: buttonPosition.y,
          transition: isDragging ? 'none' : 'all 0.2s ease-in-out',
        }}
        onMouseDown={handleMouseDown}
        onClick={() => {
          // No need to handle click here as it's managed in handleMouseUp
        }}
        $isDragging={isDragging}
      >
        <MessageOutlined style={{ fontSize: '24px' }} />
      </ChatButton>
    );
  }

  const handleShowHistoryChat = () => {
    setIsShowChatHistory(!isShowChatHistory);
  };

  const handleChatHistoryItemClick = async (itemChat: any) => {
    setIsShowChatHistory(false);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/chat-ai/chat-history/detail/${itemChat?.id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            userId: getUser().id,
          },
        },
      );
      setChatHistoryDetailData(response?.data?.data);
      setMessages(response?.data?.data?.message);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
    setIsLoading(false);
  };

  const handleNewChat = () => {
    chatIdResponse = '';
    setMessages([
      {
        type: 'bot',
        content: t('chatDisplay.welcomeMessage', { ns: 'ai_chatbox' }),
      },
    ]);
    setIsShowChatHistory(false);
    setChatHistoryDetailData(null);
  };

  const handleSaveMessage = async (
    message: any,
    type: string,
    queryResult: any = undefined,
    callback: (success: boolean) => void,
  ) => {
    try {
      if (chatHistoryDetailData?.id || chatIdResponse) {
        const params = {
          message: {
            type: type,
            content: message,
            queryResult: type === 'bot' ? queryResult : undefined,
          },
          images: previewImages,
        };
        const response = await axios.put(
          `${API_BASE_URL}/chat-ai/chat-history/${chatHistoryDetailData?.id || chatIdResponse}`,
          params,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
              userId: getUser().id,
            },
          },
        );
        if (response?.data?.data) {
          callback(true);
        } else {
          callback(false);
        }
      } else {
        if (type === 'user') {
          const params = {
            chatName: message,
            message: {
              type: type,
              content: message,
            },
            images: previewImages,
          };
          const response = await axios.post(`${API_BASE_URL}/chat-ai/chat-history`, params, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
              userId: getUser().id,
            },
          });
          chatIdResponse = response?.data?.data?.id;
          if (response?.data?.data) {
            callback(true);
          } else {
            callback(false);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
      callback(false);
    }
  };

  return (
    <>
      {!isHideChatbox && (
        <ChatBoxContainer>
          <ChatHeader>
            <span>{t('chatDisplay.title', { ns: 'ai_chatbox' })}</span>
            <CloseButton onClick={() => setIsOpen(false)}>
              <CloseOutlined />
            </CloseButton>
          </ChatHeader>

          {isLoading ? (
            <div style={{ textAlign: 'center', flex: 1, alignContent: 'center' }}>
              <Spin />
            </div>
          ) : (
            <MessagesContainer>
              {!isShowChatHistory &&
                messages.map((message, index) => (
                  <MessageBubble
                    key={index}
                    type={message.type}
                    ref={index === messages.length - 1 ? latestMessageRef : null}
                  >
                    <div className="avatar-wrapper">
                      <div className="avatar">
                        {message.type === 'bot' ? (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 12C11.4477 12 11 11.5523 11 11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11C13 11.5523 12.5523 12 12 12Z"
                              stroke="#fff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
                              stroke="#fff"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <AvatarUser
                            size={34}
                            fontSize={'14px'}
                            firstName={arrayWordFullName?.[arrayWordFullName.length - 1] || ''}
                            lastName={arrayWordFullName?.[0] || ''}
                          />
                        )}
                      </div>
                    </div>
                    <div className="message-wrapper">
                      {message.type === 'user' && message?.images?.length > 0 && (
                        <UserImagesContainer>
                          <UserImage
                            key={index}
                            src={message?.images}
                            alt={`Sent image ${index + 1}`}
                          />
                        </UserImagesContainer>
                      )}
                      {message.type === 'bot' ? (
                        isBotAnswering && message.content === '...' ? (
                          <div className="message-content">
                            <BubbleLoader>
                              <div></div>
                              <div></div>
                              <div></div>
                            </BubbleLoader>
                          </div>
                        ) : (
                          <div className="message-content">
                            {renderMessageWithLinks(message?.content)}
                          </div>
                        )
                      ) : (
                        message?.content && (
                          <div className="message-content">{message?.content}</div>
                        )
                      )}
                    </div>
                  </MessageBubble>
                ))}
              {!isShowChatHistory && !chatHistoryDetailData && messages.length === 1 && (
                <SuggestedQuestionsContainer>
                  {suggestedQuestions.map((question, index) => (
                    <SuggestedQuestion key={index} onClick={() => handleSendMessage(question)}>
                      {question}
                    </SuggestedQuestion>
                  ))}
                </SuggestedQuestionsContainer>
              )}
              {isShowChatHistory && (
                <>
                  <ChatHistoryTitle>Chat History</ChatHistoryTitle>
                  <SuggestedQuestionsContainer>
                    {listChatHistory?.map((item, index) => (
                      <SuggestedQuestion
                        key={index}
                        onClick={() => handleChatHistoryItemClick(item)}
                      >
                        {item?.chatName}
                      </SuggestedQuestion>
                    ))}
                  </SuggestedQuestionsContainer>
                </>
              )}
              <div ref={latestMessageRef} />
            </MessagesContainer>
          )}
          <ChatInput>
            <HiddenInput
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              multiple
            />
            {previewImages.length > 0 && (
              <PreviewContainer>
                {previewImages.map((preview, index) => (
                  <ImageContainer key={index}>
                    <PreviewImage src={preview} alt={`Preview ${index + 1}`} />
                    <RemoveButton
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={() => handleRemoveImage(index)}
                    />
                  </ImageContainer>
                ))}
              </PreviewContainer>
            )}
            <TextArea
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={handleKeyPress}
              style={{
                border: 'none',
                boxShadow: 'none',
              }}
              autoSize={{ minRows: 2, maxRows: 4 }}
              placeholder={t('chatDisplay.messageChatSupport', { ns: 'ai_chatbox' })}
            />
            <ActionsContainer>
              <ButtonContainer>
                <Button
                  ref={uploadButtonRef}
                  onClick={handleShowUploadOptions}
                  style={{ alignSelf: 'flex-end' }}
                  type="text"
                  icon={<PaperClipOutlined />}
                  disabled
                />
                <Button
                  style={{ alignSelf: 'flex-end' }}
                  type="text"
                  onClick={startRecognition}
                  icon={<AudioOutlined />}
                />
                <Button
                  style={{ alignSelf: 'flex-end' }}
                  type="text"
                  onClick={() => handleShowHistoryChat()}
                  icon={
                    <img
                      src={HistoryChatIcon}
                      width={19}
                      height={19}
                      style={{ marginBottom: 2 }}
                      alt="History"
                    />
                  }
                />
                <Button
                  style={{ alignSelf: 'flex-end' }}
                  type="text"
                  onClick={() => handleNewChat()}
                  icon={<PlusOutlined />}
                />
              </ButtonContainer>
              {showUploadOptions && !previewImages.length && (
                <UploadMenu ref={uploadMenuRef}>
                  <UploadFileChatAI onFileUpload={handleFileUpload} />
                </UploadMenu>
              )}
              {isSending ? (
                <SendButton type="text" onClick={handleRemove}>
                  <ImagesStopButton src={stopButton} />
                </SendButton>
              ) : (
                <SendButton onClick={() => handleSendMessage}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginTop: 3 }}
                  >
                    <path
                      d="M5 12H19M19 12L12 5M19 12L12 19"
                      stroke="#666"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SendButton>
              )}
            </ActionsContainer>
          </ChatInput>
        </ChatBoxContainer>
      )}
    </>
  );
};

const ChatBoxContainer = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 380px;
  height: 600px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const ChatButton = styled.button<{ $isDragging: boolean }>`
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: #1890ff;
  color: white;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: ${(props) => (props.$isDragging ? 'grabbing' : 'grab')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  user-select: none;

  &:hover {
    background: #40a9ff;
  }
`;

const ChatHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
`;

const CloseButton = styled(Button)`
  border: none;
  padding: 4px 8px;

  &:hover {
    background: #f5f5f5;
  }
`;
const TextChatAI = styled.div`
  font-size: 12px;
`;

const MessagesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  overflow-y: auto;
`;

const MessageBubble = styled.div<{ type: 'bot' | 'user' }>`
  display: flex;
  margin-bottom: 0px;
  width: 100%;
  gap: 10px;

  ${({ type }) =>
    type === 'bot'
      ? `
    .avatar-wrapper {
      // margin-top: 28px;
    }

    .avatar {
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
      background: #1890ff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .message-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 70%;
    }

    .user-info {
      font-weight: 500;
      margin-bottom: 8px;
      color: #333;
      font-size: 14px;
    }

    .message-content {
      background: white;
      color: #333;
      padding: 10px 0px;
      border-radius: 0 16px 16px 16px;
      font-size: 14px;
      line-height: 1.6;
    }
    `
      : `
    flex-direction: row-reverse;

    .avatar-wrapper {
      // margin-top: 28px;
    }

    .avatar {
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
      overflow: hidden;
      margin-left: 12px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .message-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 70%;
      align-items: flex-end;
    }

    .user-info {
      font-weight: 500;
      margin-bottom: 8px;
      color: #333;
      font-size: 14px;
    }

    .message-content {
      background: #1890ff;
      color: white;
      padding: 12px 16px;
      border-radius: 16px 0 16px 16px;
      font-size: 14px;
      line-height: 1.6;
    }
    `}
`;

const ChatInput = styled.div`
  padding: 12px 16px;
  border-top: 1px solid #f0f0f0;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const SendButton = styled(Button)`
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin-top: 12px;
`;

const SuggestedQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: self-end;
`;

const SuggestedQuestion = styled.button`
  background: #f0f2f5;
  border: none;
  border-radius: 16px;
  padding: 8px 16px;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #1890ff;
  transition: background-color 0.2s;

  &:hover {
    background: #e6f7ff;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 4px;
`;

const LinkButton = styled.button`
  background: white;
  border: none;
  border-radius: 10px;
  padding: 12px 16px;
  color: #0066ff;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  transition: background-color 0.2s;
  font-weight: 600;

  &:hover {
    background: #ebebeb;
  }
`;
const MessageContent = styled.div`
  font-size: 14px;
  color: #333;
  margin-bottom: 12px;
`;

const ChatHistoryTitle = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
  font-size: 16px;
  color: #333;
`;
const ImagesStopButton = styled.img`
  width: 24px;
  height: 24px;
  margin: 3px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const PreviewContainer = styled.div`
  padding: 10px;
  border-top: 1px solid #e8e8e8;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const PreviewImage = styled.img`
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
`;

const RemoveButton = styled(Button)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 2px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  &:hover {
    background: rgba(0, 0, 0, 0.7);
    color: white;
  }
`;

const UploadMenu = styled.div`
  position: absolute;
  bottom: 60px;
  left: 0;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px;
  width: 200px;
`;

const UserImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
  width: 100%;
  background-color: #e9e9e9;
  padding: 8px;
  border-radius: 4px;
`;

const UserImage = styled.img`
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
`;
