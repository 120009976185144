import React from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CustomButton from 'styles/buttonStyled';

interface ResumeReviewButtonProps {
  fileUrl?: string;
  className?: string;
  style?: React.CSSProperties;
}

const ResumeReviewButton: React.FC<ResumeReviewButtonProps> = ({ fileUrl, className, style }) => {
  const { t } = useTranslation(['recruitment', 'action']);

  const handleReviewResume = () => {
    if (fileUrl) {
      window.open(fileUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <CustomButton
      aType="outline"
      icon={<FileSearchOutlined />}
      onClick={handleReviewResume}
      disabled={!fileUrl}
      className={className}
      style={style}
    >
      {t('action:review_cv')}
    </CustomButton>
  );
};

export default ResumeReviewButton;
