// Every route in breadcrumbNameMap must match to a route in breadcrumb file of languages folder
const breadcrumbNameMap: any = {
  '/': {
    isLink: true,
  },
  '/dashboard': {
    isLink: true,
  },
  '/report': {
    isLink: true,
  },
  '/my-page': {
    isLink: false,
  },
  '/my-page/timesheet': {
    isLink: true,
  },
  '/my-page/timesheet/details': {
    isLink: false,
  },
  '/my-page/timekeeping': {
    isLink: true,
  },
  '/my-page/performance-review': {
    isLink: true,
  },
  '/my-page/performance-review/detail': {
    isLink: true,
  },
  '/my-page/on-off': {
    isLink: false,
  },
  '/my-page/on-off/onboard': {
    isLink: true,
  },
  '/my-page/on-off/offboard': {
    isLink: true,
  },
  '/my-page/leave-off': {
    isLink: true,
  },
  '/my-page/payslip': {
    isLink: true,
  },
  '/my-page/device': {
    isLink: true,
  },
  '/my-page/observation_list': {
    isLink: true,
  },
  '/manage-watching-list': {
    isLink: false,
  },
  '/manage-watching-list/watching-list': {
    isLink: true,
  },
  '/manage-watching-list/manager-request-list': {
    isLink: true,
  },
  '/manage-watching-list/hr-request-list': {
    isLink: true,
  },
  '/manage': {
    isLink: false,
  },
  '/manage/request_approval': {
    isLink: true,
  },
  '/manage/request_pending': {
    isLink: true,
  },
  '/manage/request_approval/detail': {
    isLink: false,
  },
  '/manage/request_approval/itemOverView': {
    isLink: true,
  },
  '/manage/timesheet_approval': {
    isLink: true,
  },
  '/manage/employee_timesheet': {
    isLink: true,
  },
  '/manage/timesheet_report': {
    isLink: true,
  },
  '/employee': {
    isLink: false,
  },
  '/employee/list-employee': {
    isLink: true,
  },
  '/employee/dashboard-employee': {
    isLink: true,
  },
  '/contract': {
    isLink: false,
  },
  '/contract/employee-contract': {
    isLink: true,
  },
  '/contract/request-contract': {
    isLink: true,
  },
  '/contract/contract-management': {
    isLink: true,
  },

  '/onboard-offboard': {
    isLink: false,
  },
  '/onboard-offboard/employee-onboard': {
    isLink: true,
  },
  '/onboard-offboard/employee-onboard/view': {
    isLink: true,
  },
  '/onboard-offboard/employee-offboard': {
    isLink: true,
  },
  '/onboard-offboard/employee-offboard/view': {
    isLink: true,
  },
  '/manage-leave-off': {
    isLink: true,
  },
  '/manage-project': {
    isLink: true,
  },
  '/payroll': {
    isLink: false,
  },
  '/payroll/pay-runs': {
    isLink: true,
  },
  'payroll/pay-runs/detail/': {
    isLink: false,
  },
  '/payroll/view-employee': {
    isLink: true,
  },
  '/payroll/overall': {
    isLink: true,
  },
  '/payroll/dashboard': {
    isLink: true,
  },
  '/payroll/manage-request': {
    isLink: true,
  },
  '/history-manage-project': {
    isLink: true,
  },
  '/manage-overtime/overtime-plan-request': {
    isLink: true,
  },
  '/manage-overtime/history-overtime-plan-request': {
    isLink: true,
  },
  '/manage-overtime/overtime-approval': {
    isLink: true,
  },
  '/manage-overtime/history-overtime-approval': {
    isLink: true,
  },
  '/manage-overtime/manage-overtime-type': {
    isLink: true,
  },
  '/manage-overtime/compensatory-configuration': {
    isLink: true,
  },
  '/equipment': {
    isLink: false,
  },
  '/equipment/inventory-control': {
    isLink: true,
  },
  '/equipment/equipment-handover': {
    isLink: true,
  },
  '/equipment/equipment-handover/handover': {
    isLink: true,
  },
  '/configuration': {
    isLink: false,
  },
  '/configuration/category': {
    isLink: true,
  },
  '/configuration/department': {
    isLink: true,
  },
  '/configuration/position': {
    isLink: true,
  },
  '/configuration/role': {
    isLink: true,
  },
  '/configuration/workshift': {
    isLink: true,
  },
  '/configuration/payroll': {
    isLink: true,
  },
  '/configuration/tax-management': {
    isLink: true,
  },
  '/configuration/status': {
    isLink: true,
  },
  '/configuration/action': {
    isLink: true,
  },
  '/configuration/workflow-type': {
    isLink: true,
  },
  '/configuration/setup-policy': {
    isLink: true,
  },
  '/configuration/setup-workflow': {
    isLink: true,
  },
  '/configuration/setup-workflow/create': {
    isLink: true,
  },
  '/configuration/work-shift': {
    isLink: false,
  },
  '/configuration/work-shift/setup-work-shifts': {
    isLink: true,
  },
  '/configuration/work-shift/manage-assign-work-shifts': {
    isLink: true,
  },
  '/configuration/manage-mail': {
    isLink: false,
  },
  '/configuration/general-setting': {
    isLink: false,
  },
  '/configuration/menu-screen': {
    isLink: true,
  },
  '/configuration/performance-review': {
    isLink: true,
  },
  '/recruitment': {
    isLink: true,
  },
  '/recruitment/staff-view': {
    isLink: true,
  },
  '/recruitment/staff-view/jobs': {
    isLink: true,
  },
  '/recruitment/staff-view/jobs/:id': {
    isLink: false,
  },
  '/recruitment/configuration': {
    isLink: true,
  },
  '/recruitment/jobs': {
    isLink: true,
  },
  '/recruitment/candidates': {
    isLink: true,
  },
  '/configuration/goals': {
    isLink: true,
  },
  '/insurance': {
    isLink: false,
  },
  '/insurance/complusory-insurance': {
    isLink: false,
  },
  '/insurance/setting-insurance': {
    isLink: false,
  },
  '/configuration/manage-mail-&-notification': {
    isLink: true,
  },
  '/configuration/manage-mail-&-notification/manage-mail-templates': {
    isLink: true,
  },
  '/configuration/manage-mail-&-notification/auto-send-mail': {
    isLink: true,
  },
  '/configuration/manage-mail-&-notification/config-notification': {
    isLink: true,
  },
  '/performance': {
    isLink: false,
  },
  '/performance/employee-pr-management': {
    isLink: true,
  },
  '/staff-view/jobs': {
    isLink: true,
  },
};

export default breadcrumbNameMap;
