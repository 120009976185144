import React, { useState, useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload, Button, message } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { uploadCV } from 'features/recruitment/recruitmentAction';
import { useParams } from 'react-router';
import { isInterviewer } from 'features/recruitment/jobs/components/types';
import { useTranslation } from 'react-i18next';
import CVOnsite from '../CVOnsite';

interface UploadResumeProps {
  data: any;
  handleChange: (data: any) => void;
  showCVButton?: boolean;
}

const UploadResume: React.FC<UploadResumeProps> = ({ data, handleChange, showCVButton = true }) => {
  const { t } = useTranslation(['status', 'action', 'onboard', 'recruitment']);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [fileUrl, setFileUrl] = useState(null);
  const [showUpload, setShowUpload] = useState(true);
  const [showCVOnsite, setShowCVOnsite] = useState(false);

  useEffect(() => {
    if (data?.linkProfile) {
      setFileUrl(data.linkProfile);
      setShowUpload(false);
    }
  }, [data]);

  const handleUpload = (info) => {
    if (isInterviewer()) return;
    const file = info.file;
    if (file.type !== 'application/pdf') {
      message.error(`${file.name} is not a PDF file`);
      return;
    }
    const url = URL.createObjectURL(file);
    setFileUrl(url);
    setShowUpload(false);

    const formData = new FormData();
    if (id !== 'draft') {
      formData.append('candidateId', id);
    }
    formData.append('file', file);

    dispatch(
      uploadCV({
        formData,
        callBack: (res) => {
          handleChange({ file: res });
        },
      }),
    );
  };

  const handleRemove = () => {
    if (isInterviewer()) return;
    setFileUrl(null);
    setShowUpload(true);
    handleChange({ file: undefined });
  };

  const beforeUpload = (file) => {
    if (isInterviewer()) return Upload.LIST_IGNORE;
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      message.error('You can only upload PDF files!');
    }
    return isPdf || Upload.LIST_IGNORE;
  };

  if (isInterviewer() && !data?.linkProfile) {
    return null;
  }

  return (
    <>
      {!isInterviewer() && <div>{t('recruitment:upload_resume')}</div>}
      {!isInterviewer() && showUpload ? (
        <Upload.Dragger
          showUploadList={false}
          multiple={false}
          accept=".pdf"
          beforeUpload={beforeUpload}
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess?.('ok');
            }, 0);
            handleUpload({ file });
          }}
        >
          <div>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {t('onboard:click_or_drag_file_to_this_area_to_upload')}
            </p>
          </div>
        </Upload.Dragger>
      ) : (
        <>
          {!isInterviewer() && (
            <div style={{ marginTop: '20px', display: 'flex', gap: '8px' }}>
              <Button onClick={handleRemove}>{t('status:remove')}</Button>
              {showCVButton && data?.linkProfile && (
                <Button type="primary" onClick={() => setShowCVOnsite(true)}>
                  CV Onsite
                </Button>
              )}
            </div>
          )}
          {fileUrl && <iframe src={fileUrl} style={{ width: '100%', height: '1000px' }} />}
        </>
      )}
      <CVOnsite visible={showCVOnsite} onClose={() => setShowCVOnsite(false)} data={data} />
    </>
  );
};

export default UploadResume;
