import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Form, InputNumber, Popconfirm, Select } from 'antd';
import { selectFormatMonth } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DatePickerField } from 'components/FormFields';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { ContractSalaryConfirmStatus, MONTH_YEAR_FORMAT } from 'constants/payroll';
import {
  getAllBonusListForUser,
  getAllBonusUser,
  updateBonusUser,
} from 'features/employee/employeeAction';
import { actions, selectEmployee } from 'features/employee/employeeSlice';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { removeAccents } from 'utils/text';

const { Option } = Select;

const AssignedBonusItems = (props: any) => {
  const { employeeData, callBack, monthYear } = props;
  const { t } = useTranslation(['menu', 'action', 'overtime', 'timesheet', 'employee', 'leaveOff']);
  const dispatch = useAppDispatch();
  const { setErrorMessage } = actions;
  const employee = useAppSelector<any>(selectEmployee);
  const dataAssignBonus = employee?.dataAssignBonus?.result;
  const statusConfirmBonus = employee?.dataAssignBonus?.status;
  const dataListBonus = employee?.dataListBonus?.result;
  const loadingBonus = employee?.loadingBonus;

  const error = employee.error;
  const [form] = Form.useForm();
  const dateFormat = useAppSelector(selectFormatMonth);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setTotalAmount] = useState(0);
  const [dataBonusHistory, setDataBonusHistory] = useState<any>([]);
  const [dataBonusHistoryOld, setDataBonusHistoryOld] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [dataOri, setDataOri] = useState<any>([]);
  const [errMsg, setErrMsg] = useState<any>('');
  const [errList, setErrList] = useState<any>([]);
  const [month, setMonth] = useState(monthYear ?? moment().format(MONTH_YEAR_FORMAT));
  const { control } = useForm();

  const isDataDisabled = (record: any) => {
    return statusConfirmBonus === 'approved';
  };

  useEffect(() => {
    if (error?.fieldErrors?.bonusDetails) {
      const err = JSON.parse(error?.fieldErrors?.bonusDetails);
      const msg = Object.entries(err).map((entry) => entry?.[1]);
      setErrMsg(msg);
      const errorListArray = data
        ?.filter((item: any) => msg?.some((i: any) => i?.index === item?.key))
        ?.map((item: any) => item?.key);
      setErrList(errorListArray);
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (employeeData?.id) {
      dispatch(getAllBonusListForUser({}));
      dispatch(getAllBonusUser({ userId: employeeData?.id, monthYear: month }));
    }
  }, [dispatch, employeeData?.id, month]);

  useEffect(() => {
    if (dataAssignBonus?.length) {
      const filteredData = dataAssignBonus
        .filter((item: any) => !item.deleteFlag)
        .map((item: any, index: any) => ({ ...item, key: index }));
      setData(filteredData);
    } else {
      setData([]);
    }
  }, [dataAssignBonus]);

  const uniqueArray = (arr: any) =>
    arr.filter((value: any, index: any) => {
      const _value = value?.id;
      return (
        index ===
        arr.findIndex((obj: any) => {
          return obj?.id === _value;
        })
      );
    });

  useEffect(() => {
    setDataBonusHistory(dataListBonus);
    if (dataListBonus?.length >= 0 && dataAssignBonus?.length >= 0) {
      const isNewData = dataAssignBonus?.filter(
        (item: any) => !dataListBonus?.some((i: any) => item?.bonusHistory?.id === i?.id),
      ); // check if bonus update new bonus but current is old -> if >0 then current is old id
      if (isNewData?.length) {
        const oldData = isNewData?.map((item: any) => ({
          name: item?.bonusHistory?.name + '(old)',
          isTaxed: item?.bonusHistory?.isTaxed,
          id: item?.bonusHistory?.id,
        }));
        setDataBonusHistory([...dataListBonus, ...uniqueArray(oldData)]);
        setDataBonusHistoryOld(uniqueArray(oldData));
      }
    }
  }, [dataListBonus, dataAssignBonus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const menu = [
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      handleClick: (value: any) => {
        let copyArr = JSON.parse(JSON.stringify(data));
        copyArr.splice(
          copyArr.findIndex((item: any) => item?.key === value?.key),
          1,
        );
        setData(copyArr?.map((item: any, index: any) => ({ ...item, key: index })));
      },
    },
  ];

  const disabledDate = (current: moment.Moment | null) => {
    if (!current) return false;

    const startOfMonth = moment(month).startOf('month');
    return current.isBefore(startOfMonth);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let COLUMNS_ADD = [
    {
      title: t('employee:bonus_information_fields.col_id'),
      dataIndex: 'id',
      key: 'id',
      width: 60,
      minWidth: 60,
      maxWidth: 60,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <div>{record?.key + 1}</div>;
      },
    },
    {
      title: t('employee:bonus_information_fields.bonus_name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Select
            showSearch
            disabled={isDataDisabled(record)}
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, bonusHistoryId: value } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, bonusHistoryId: value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            filterOption={(input, option) => {
              return removeAccents(option!.children as unknown as string)
                ?.toLowerCase()
                ?.includes(removeAccents(input)?.toLowerCase());
            }}
            value={(record?.bonusHistoryId || record?.bonusHistory?.id) ?? null}
            style={{ width: '100%' }}
          >
            {(dataBonusHistoryOld //check if have old bonus data
              ?.some(
                (item: any) =>
                  item?.id === record?.bonusHistoryId || item?.id === record?.bonusHistory?.id,
              )
              ? [
                  ...dataListBonus,
                  dataBonusHistory
                    ?.filter((item: any) =>
                      dataBonusHistoryOld?.some((i: any) => i?.id === item?.id),
                    )
                    ?.find(
                      (item: any) =>
                        item?.id === record?.bonusHistoryId ||
                        item?.id === record?.bonusHistory?.id,
                    ),
                ] //array of old bonus data
              : dataBonusHistory //array of new Bonus data
                  ?.filter(
                    (item: any) => !dataBonusHistoryOld?.some((i: any) => i?.id === item?.id),
                  )
            )?.map((item: any) => (
              <Option value={item?.id}>{item?.name}</Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: t('employee:allowance_information_fields.is_pay_taxes'),
      dataIndex: 'tax',
      key: 'tax',
      width: 30,
      align: 'center',
      minWidth: 30,
      maxWidth: 60,
      render: function (text: any, record: any, index: any) {
        return (
          <Checkbox
            disabled={statusConfirmBonus === ContractSalaryConfirmStatus.APPROVED}
            checked={
              dataBonusHistory?.find((item: any) =>
                record?.bonusHistoryId
                  ? item?.id === record?.bonusHistoryId
                  : item?.id === record?.bonusHistory?.id,
              )?.isTaxed
            }
          ></Checkbox>
        );
      },
    },
    {
      title: t('employee:bonus_information_fields.col_month'),
      dataIndex: 'month',
      key: 'month',
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <DatePicker
            allowClear={false}
            format={dateFormat}
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, monthYear: value ? moment(value) : null } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, monthYear: value ? moment(value) : null } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            value={data?.monthYear}
            style={{ width: '100%' }}
            picker="month"
            disabledDate={(current) => disabledDate(current)}
            disabled={statusConfirmBonus === ContractSalaryConfirmStatus.APPROVED}
            defaultValue={record?.monthYear ? moment(record?.monthYear) : undefined}
          ></DatePicker>
        );
      },
    },
    {
      title: t('employee:allowance_information_fields.money'),
      dataIndex: 'money',
      key: 'money',
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <CustomInputNumber
            min={0}
            style={{ width: '100%' }}
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, money: value ?? 0 } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, money: value ?? 0 } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            value={text}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
            addonAfter="VNĐ"
            disabled={isDataDisabled(record)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (statusConfirmBonus !== ContractSalaryConfirmStatus.APPROVED) {
      COLUMNS_ADD.push({
        title: t('employee:employee_information_fields.action'),
        dataIndex: 'action',
        key: 'action',
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        align: 'center',
        render: function (text: any, record: any, index: any) {
          return <MenuAction menu={menu} data={record} />;
        },
      });
    }
  }, [COLUMNS_ADD, menu, statusConfirmBonus, t]);

  const onAddRow = () => {
    if (data?.length >= 0) {
      const newList = data.concat({
        key: data?.length,
        bonusHistoryId: dataListBonus?.[0]?.id,
        monthYear: moment(),
        money: 0,
      });
      setData(newList);
    }
  };

  const getTotalAmount = () => {
    const rs = data?.reduce((acc: any, curr: any) => {
      return acc + curr.money;
    }, 0);

    setTotalAmount(rs);
  };

  useEffect(() => {
    getTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSave = () => {
    const updateData = data?.map((item: any) =>
      item?.id
        ? {
            id: item?.id,
            monthYear: item?.monthYear ? moment(item?.monthYear).format(MONTH_YEAR_FORMAT) : null,
            money: item?.money,
            bonusHistoryId: item?.bonusHistoryId ?? item?.bonusHistory?.id,
          }
        : {
            monthYear: item?.monthYear ? moment(item?.monthYear).format(MONTH_YEAR_FORMAT) : null,
            money: item?.money,
            bonusHistoryId: item?.bonusHistoryId ?? item?.bonusHistory?.id,
          },
    );
    const deleteData = dataAssignBonus
      ?.filter((item: any) => !updateData?.some((i: any) => i?.id === item?.id))
      .map((item: any) => ({
        id: item?.id,
        monthYear: item?.monthYear ? moment(item?.monthYear).format(MONTH_YEAR_FORMAT) : null,
        money: item?.money,
        bonusHistoryId: item?.bonusHistoryId ?? item?.bonusHistory?.id,
        deleteFlag: true,
      }));

    dispatch(
      updateBonusUser({
        id: employeeData?.id,
        data: [...updateData, ...deleteData],
        callBack: () => {
          dispatch(getAllBonusUser({ userId: employeeData?.id, monthYear: month }));
          callBack && callBack();
        },
      }),
    );
  };

  const handleChangeMonth = (value: Moment | null) => {
    if (value) {
      setMonth(moment(value).format(MONTH_YEAR_FORMAT));
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: '#fff', padding: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DatePickerField
            name="monthYear"
            control={control}
            picker="month"
            allowClear={false}
            value={moment(month)}
            defaultValue={moment(month)}
            onChange={handleChangeMonth}
            // disabledDate={(currentMonth) => moment() < currentMonth}
          />
          {statusConfirmBonus !== ContractSalaryConfirmStatus.APPROVED && dataListBonus?.length && (
            <CustomButton icon={<PlusOutlined />} onClick={onAddRow}>
              {t('action:add_new')}
            </CustomButton>
          )}
        </div>
        <Form form={form} component={false} style={{ maxWidth: 900 }}>
          <ITVTable
            loading={loadingBonus}
            columns={COLUMNS_ADD}
            data={data?.filter((item: any) => !item?.deleteFlag) || []}
            height={350}
            maxWidth={900}
            isRowSelect={false}
            setOffset={setCurrentPage}
            offset={currentPage}
            setLimit={setSizePage}
            limit={sizePage}
            errorList={errList}
          />
        </Form>
      </div>

      {/* <div>Total Amount: {formatCurrencyUnit(totalAmount, 'VND')}</div> */}
      {errMsg && (
        <div style={{ color: 'red', height: 80, overflowY: 'scroll' }}>
          {errMsg?.flat(1)?.map((item: any, i: any, arr: any) => {
            let divider = i < arr.length - 1 && <div></div>;
            return (
              <span key={i}>
                <div>{item?.name}</div>
                <ul style={{ listStylePosition: 'inside' }}>
                  {item?.message?.map((childItem: any) => (
                    <li style={{ listStyleType: 'disc' }}>{childItem}</li>
                  ))}
                </ul>
                {divider}
              </span>
            );
          })}
        </div>
      )}
      {statusConfirmBonus !== ContractSalaryConfirmStatus.APPROVED && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Popconfirm
            title={t('leaveOff:modal_item.are_you_sure_save')}
            onConfirm={() => {
              setErrList([]);
              setErrMsg(null);
              onSave();
            }}
            okText={t('Yes')}
            cancelText={t('No')}
            placement="topRight"
          >
            <CustomButton>{t('action:save')}</CustomButton>
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

export default AssignedBonusItems;

const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
