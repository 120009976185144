import React, { useState, useEffect } from 'react';
import { Modal, Button, Spin, message, Input, Tooltip } from 'antd';
import { DownloadOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import axios from 'axios';
import { processSkills } from './utils/skillsProcessing';
import SkillsTable from './components/SkillsSection/SkillsTable';

import {
  CVContainer,
  Logo,
  Title,
  Section,
  SectionTitle,
  InfoRow,
  InfoLabel,
  InfoValue,
  ProjectCard,
  EditButtons,
  TemplateContainer,
  TemplateOptions,
  TemplateOption,
  LoadingContent,
  LoadingOverlay,
  SectionHeader,
  NoUnderlineSectionTitle,
  EducationItem,
  EducationRow,
  EducationSchool,
  EducationMajor,
  FieldLabel,
  SchoolName,
  MajorName,
  DeleteButton,
  NoEducationInfo,
  LanguageLabel,
  LanguageValue,
  CheckboxContainer,
  SkillsContainer,
  SkillItem,
  SkillName,
  ProjectRow,
  ProjectNameContainer,
  ProjectDurationContainer,
  ProjectNameDisplay,
  ProjectDeleteButton,
  ProjectDurationDisplay,
  ProjectLabel,
  ProjectSection,
  ProjectContent,
  CancelButton,
  SaveButton,
  RefreshButton,
  CVContentContainer,
  NoCVContainer,
  NoCVTitle,
  NoCVDescription,
  TemplateTitle,
  LoadingTextContainer,
  LoadingText,
  CenteredInput,
  PositionContainer,
  PositionInput,
  ProjectDurationText,
  BulletPoint,
  FlexRow,
  FlexColumn,
  StyledInput,
  PaddedInput,
  globalStyles,
  personalInfoStyles,
  languageStyles,
  programmingSkillsStyles,
  projectStyles,
} from '../../../../../../styles/CVOnsiteStyles';

export interface Education {
  name: string;
  major: string;
}

export interface Language {
  vietnamese?: string;
  english?: string;
}

export interface ProgrammingSkill {
  name: string;
  technicalSummary: string[] | string;
  yearsOfExperience: string;
  newSkillIndex?: number;
}

export interface Project {
  name?: string;
  role?: string;
  skills?: string;
  responsibilities?: string;
  duration?: string;
}

export interface CVData {
  name?: string;
  position?: string;
  sex?: string;
  nationality?: string;
  languages?: Language;
  education?: Education[];
  programmingSkills?: ProgrammingSkill[];
  projects?: Project[];
  major?: string;
  university?: string;
}

interface CVOnsiteProps {
  visible: boolean;
  onClose: () => void;
  showEditButton?: boolean;
  data?: any; // Data từ UploadResume
}

enum CVTemplate {
  TESTER = 'tester',
  PROJECT_MANAGER = 'project_manager',
  DEVELOPER = 'developer',
}

const templateOptions = [
  { label: 'Tester', value: CVTemplate.TESTER },
  { label: 'Project Manager', value: CVTemplate.PROJECT_MANAGER },
  { label: 'Developer', value: CVTemplate.DEVELOPER },
];

const interpolateColor = (color1: string, color2: string, factor: number) => {
  const c1 = hexToRgb(color1);
  const c2 = hexToRgb(color2);

  const r = Math.round(c1.r + (c2.r - c1.r) * factor);
  const g = Math.round(c1.g + (c2.g - c1.g) * factor);
  const b = Math.round(c1.b + (c2.b - c1.b) * factor);

  return rgbToHex(r, g, b);
};

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

const rgbToHex = (r: number, g: number, b: number) => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const CVOnsite: React.FC<CVOnsiteProps> = ({ visible, onClose, showEditButton = true, data }) => {
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  const hasCV = React.useMemo(() => {
    return !!(data?.file?.linkProfile || data?.linkProfile);
  }, [data]);

  const { t } = useTranslation(['recruitment', 'action']);
  const [loading, setLoading] = useState(false);
  const [cvData, setCvData] = useState<CVData | null>(null);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<CVTemplate | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState<CVData | null>(null);
  const [useDefaultEnglish, setUseDefaultEnglish] = useState(true);

  const handleEdit = () => {
    setUseDefaultEnglish(true);
    console.log('Starting edit mode');
    setIsEditing(true);
    const initialEditData = cvData
      ? {
          ...cvData,
          languages: {
            ...cvData.languages,
            english: cvData.languages?.english || '',
          },
        }
      : {
          projects: [],
          programmingSkills: [],
          education: [],
          languages: {
            vietnamese: '',
            english: '',
          },
        };
    console.log('Initial edit data:', initialEditData);
    setEditData(initialEditData);
  };

  const handleSave = () => {
    setCvData(editData);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditData(cvData);
    setIsEditing(false);
  };

  const handleInputChange = (field: string, value: string) => {
    console.log('Input change:', field, value);
    console.log('Current editData:', editData);
    if (!editData || !isMounted.current) {
      console.log('No editData or component unmounted');
      return;
    }
    if (field.includes('.')) {
      const [parent, child] = field.split('.') as [keyof CVData, string];
      const parentObj = editData[parent] as { [key: string]: string };
      const newData = {
        ...editData,
        [parent]: {
          ...parentObj,
          [child]: value,
        },
      };
      console.log('Updated data:', newData);
      setEditData(newData);
    } else {
      const newData = {
        ...editData,
        [field]: value,
      };
      console.log('Updated data:', newData);
      setEditData(newData);
    }
  };

  const handleLanguageChange = (field: string, value: string) => {
    if (!editData || !isMounted.current) return;
    setEditData({
      ...editData,
      languages: {
        ...editData.languages,
        [field]: value,
      },
    });
  };

  const handleEducationChange = (index: number, field: string, value: string) => {
    if (!editData || !isMounted.current) return;
    const newEducation = [...(editData.education || [])];
    newEducation[index] = {
      ...newEducation[index],
      [field]: value,
    };
    setEditData({
      ...editData,
      education: newEducation,
    });
  };

  const handleAddEducation = () => {
    if (!editData || !isMounted.current) return;
    const newEducation = [...(editData.education || [])];
    newEducation.push({ name: '', major: '' });
    setEditData({
      ...editData,
      education: newEducation,
    });
  };

  const handleRemoveEducation = (index: number) => {
    if (!editData || !isMounted.current) return;
    const newEducation = [...(editData.education || [])];
    newEducation.splice(index, 1);
    setEditData({
      ...editData,
      education: newEducation,
    });
  };

  const handleSkillChange = (index: number, field: keyof ProgrammingSkill, value: string) => {
    if (!editData?.programmingSkills || !isMounted.current) return;
    const newSkills = [...editData.programmingSkills];
    newSkills[index] = {
      ...newSkills[index],
      [field]: value,
    };
    setEditData({
      ...editData,
      programmingSkills: newSkills,
    });
  };

  const handleProjectChange = (index: number, field: keyof Project, value: string) => {
    setEditData((prevData) => {
      if (!prevData) return prevData;
      const updatedProjects = [...(prevData.projects || [])];
      updatedProjects[index] = {
        ...updatedProjects[index],
        [field]: value,
      };
      return {
        ...prevData,
        projects: updatedProjects,
      };
    });
  };

  const handleAddCategory = () => {
    console.log('Current editData:', editData);
    if (!editData) {
      console.log('No editData found');
      return;
    }

    const newCategory: ProgrammingSkill = {
      name: '',
      technicalSummary: '',
      yearsOfExperience: '',
    };

    const updatedSkills = editData.programmingSkills || [];
    updatedSkills.push(newCategory);

    const updatedData = {
      ...editData,
      programmingSkills: updatedSkills,
    };
    console.log('Updated data:', updatedData);

    setEditData(updatedData);
  };

  const handleAddSkill = () => {
    if (!editData) return;
    const newSkill: ProgrammingSkill = {
      name: '',
      technicalSummary: '',
      yearsOfExperience: '',
    };
    setEditData({
      ...editData,
      programmingSkills: [...(editData.programmingSkills || []), newSkill],
    });
  };

  const handleRemoveSkill = (index: number) => {
    if (!editData?.programmingSkills) return;
    const newSkills = [...editData.programmingSkills];
    newSkills.splice(index, 1);
    setEditData({
      ...editData,
      programmingSkills: newSkills,
    });
  };

  const handleRemoveCategory = (categoryIndex: number) => {
    if (!editData?.programmingSkills) return;

    const updatedSkills = [...editData.programmingSkills];
    updatedSkills.splice(categoryIndex, 1);

    setEditData({
      ...editData,
      programmingSkills: updatedSkills,
    });
  };

  const handleAddProject = () => {
    const newProject: Project = {
      name: '',
      role: '',
      skills: '',
      responsibilities: '',
      duration: '',
    };
    setEditData((prevData) => ({
      ...prevData!,
      projects: [...(prevData?.projects || []), newProject],
    }));
  };

  const handleRemoveProject = (index: number) => {
    if (!editData?.projects) return;
    const newProjects = [...editData.projects];
    newProjects.splice(index, 1);
    setEditData({
      ...editData,
      projects: newProjects,
    });
  };

  const handleFileUpload = async () => {
    if (!selectedTemplate) {
      message.error(t('recruitment:please_select_cv_template'));
      return false;
    }

    try {
      setLoading(true);

      // Tạo FormData với template
      const formData = new FormData();
      formData.append(
        'template',
        selectedTemplate === CVTemplate.PROJECT_MANAGER
          ? 'project_manager'
          : selectedTemplate === CVTemplate.TESTER
          ? 'tester'
          : 'developer',
      );

      // Lấy file từ URL (check cả data.file và data)
      const linkProfile = data?.file?.linkProfile || data?.linkProfile;
      console.log('Final linkProfile:', linkProfile);

      if (!linkProfile) {
        throw new Error(t('recruitment:cv_file_not_found'));
      }

      let pdfFile;
      try {
        // Tải file PDF
        const pdfResponse = await axios.get(linkProfile, { responseType: 'blob' });
        console.log('PDF response received:', pdfResponse.status);

        // Tạo file từ blob data
        pdfFile = new File([pdfResponse.data], 'cv.pdf', { type: 'application/pdf' });
        setCurrentFile(pdfFile);

        // Thêm vào FormData
        formData.append('file', pdfFile);
      } catch (error) {
        console.error('Error fetching PDF:', error);
        if (error instanceof Error) {
          throw new Error(t('recruitment:cannot_load_cv_file', { error: error.message }));
        }
        throw new Error(t('recruitment:cannot_load_cv_file_unknown_error'));
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/recruitment/process-cv/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      console.log('Raw API Response:', JSON.stringify(response.data, null, 2));
      console.log('Education from API:', response.data.education);

      // Kiểm tra và format education data từ API
      let educationData: Education[] = [];
      const rawEducation = response.data.education;

      if (typeof rawEducation === 'object' && rawEducation !== null) {
        // Xử lý trường hợp có current và combined
        if (rawEducation.current || rawEducation.combined) {
          if (rawEducation.current?.name || rawEducation.current?.major) {
            educationData.push({
              name: (rawEducation.current.name || '').toString(),
              major: (rawEducation.current.major || '').toString(),
            });
          }
          if (rawEducation.combined?.name || rawEducation.combined?.major) {
            educationData.push({
              name: (rawEducation.combined.name || '').toString(),
              major: (rawEducation.combined.major || '').toString(),
            });
          }
        } else if (Array.isArray(rawEducation)) {
          // Nếu là array
          educationData = rawEducation
            .map((edu) => ({
              name: (edu.name || edu.university || '').toString(),
              major: (edu.major || edu.field || '').toString(),
            }))
            .filter((edu) => edu.name || edu.major);
        } else if (rawEducation.name || rawEducation.major) {
          // Nếu là object đơn lẻ
          educationData = [
            {
              name: (rawEducation.name || rawEducation.university || '').toString(),
              major: (rawEducation.major || rawEducation.field || '').toString(),
            },
          ];
        }
      } else if (typeof rawEducation === 'string') {
        // Nếu là string, tách dựa trên dấu - hoặc ,
        const parts = rawEducation.split(/[-,]/);
        if (parts.length >= 2) {
          educationData = [
            {
              name: parts[0].trim(),
              major: parts[1].trim(),
            },
          ];
        } else {
          educationData = [
            {
              name: rawEducation.trim(),
              major: '',
            },
          ];
        }
      }

      // Lọc bỏ các entry rỗng
      educationData = educationData.filter((edu) => edu.name || edu.major);

      console.log('Formatted Education:', educationData);

      const processedData = {
        ...response.data,
        education: educationData,
      };

      console.log('Final processed data:', processedData);
      setCvData(processedData);
      message.success('Đã xử lý CV thành công!');
    } catch (error) {
      console.error('Lỗi chi tiết:', error);
      message.error('Lỗi khi xử lý CV: ' + (error as Error).message);
    } finally {
      setLoading(false);
    }
    return false;
  };

  const processSkillsAndYears = (category: ProgrammingSkill) => {
    let currentSkills: string[] = [];
    if (Array.isArray(category.technicalSummary)) {
      currentSkills = [...category.technicalSummary];
    } else if (typeof category.technicalSummary === 'string') {
      currentSkills = category.technicalSummary
        .split(/[,\n]/)
        .map((s) => s.trim().replace(/^[•\-\*]\s*/, ''))
        .filter(Boolean);
    }

    const years = (category.yearsOfExperience || '')
      .split(/[,\n]/)
      .map((y) => y.trim())
      .filter(Boolean);

    return { currentSkills, years };
  };

  const updateCategorySkills = (
    categoryIndex: number,
    currentSkills: string[],
    years: string[],
    updatedSkills: ProgrammingSkill[],
  ) => {
    updatedSkills[categoryIndex] = {
      ...updatedSkills[categoryIndex],
      technicalSummary: currentSkills,
      yearsOfExperience: years.join('\n'),
      newSkillIndex: currentSkills.length - 1,
    };
    return updatedSkills;
  };

  const addNewSkill = (skills: string[], years: string[]) => {
    skills.push('');
    years.push('');
  };

  const handleAddSkillToCategory = (categoryIndex: number) => {
    if (!editData?.programmingSkills) return;

    const updatedSkills = [...editData.programmingSkills];
    const { currentSkills, years } = processSkillsAndYears(updatedSkills[categoryIndex]);

    addNewSkill(currentSkills, years);
    const newSkills = updateCategorySkills(categoryIndex, currentSkills, years, updatedSkills);

    setEditData({
      ...editData,
      programmingSkills: newSkills,
    });
  };

  const handleUpdateCategorySkill = (
    categoryIndex: number,
    skillIndex: number,
    field: 'skill' | 'years',
    value: string,
  ) => {
    if (!editData?.programmingSkills) return;

    const updatedSkills = [...editData.programmingSkills];
    const { currentSkills, years } = processSkillsAndYears(updatedSkills[categoryIndex]);

    if (field === 'skill') {
      currentSkills[skillIndex] = value;
    } else {
      years[skillIndex] = value;
    }

    const newSkills = updateCategorySkills(categoryIndex, currentSkills, years, updatedSkills);

    setEditData({
      ...editData,
      programmingSkills: newSkills,
    });
  };

  const handleRemoveSkillFromCategory = (categoryIndex: number, skillIndex: number) => {
    if (!editData?.programmingSkills) return;

    const updatedSkills = [...editData.programmingSkills];

    // Tách và xóa skill từ technicalSummary
    let currentSkills: string[] = [];
    if (Array.isArray(updatedSkills[categoryIndex].technicalSummary)) {
      currentSkills = [...updatedSkills[categoryIndex].technicalSummary];
    } else if (typeof updatedSkills[categoryIndex].technicalSummary === 'string') {
      currentSkills = updatedSkills[categoryIndex].technicalSummary
        .split(/[,\n]/)
        .map((s) => s.trim().replace(/^[•\-\*]\s*/, ''))
        .filter(Boolean);
    }
    currentSkills.splice(skillIndex, 1);

    // Nếu không còn skill nào, thêm một skill rỗng
    if (currentSkills.length === 0) {
      currentSkills.push('');
    }

    updatedSkills[categoryIndex].technicalSummary = currentSkills.join('\n');

    // Tách và xóa year từ yearsOfExperience
    const years = (updatedSkills[categoryIndex].yearsOfExperience || '')
      .split(/[,\n]/) // Tách theo dấu phẩy và xuống dòng
      .map((y) => y.trim())
      .filter(Boolean);
    years.splice(skillIndex, 1);

    // Nếu không còn year nào, thêm một year rỗng
    if (years.length === 0) {
      years.push('');
    }

    updatedSkills[categoryIndex].yearsOfExperience = years.join('\n');

    setEditData({
      ...editData,
      programmingSkills: updatedSkills,
    });
  };

  const handleExportPDF = async () => {
    try {
      message.loading({ content: t('recruitment:creating_pdf'), key: 'pdf-export' });

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      // Thiết lập font và style mặc định
      pdf.setFont('helvetica', 'normal');
      pdf.setFontSize(12);

      const margin = 20; // Margin in mm
      const pageHeight = 297; // A4 height in mm
      const footerHeight = 10; // Footer height in mm
      const marginBottom = pageHeight - footerHeight - 5; // Thêm 5mm buffer để break sớm hơn

      // Logo
      const logoWidth = 40;
      const logoHeight = 10;
      pdf.addImage(
        '/logo.png', // Sử dụng đường dẫn tương đối đến file logo
        'PNG',
        margin,
        5, // Giảm yPos xuống để logo lên cao hơn
        logoWidth,
        logoHeight,
      );

      // Initial yPos after logo
      let yPos = 30; // Điều chỉnh yPos bắt đầu để các phần khác không bị đè

      // Tên và Position
      pdf.setFontSize(18);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont('helvetica', 'bold');
      const name = (cvData?.name || 'Name').toUpperCase();
      pdf.text(name, 105, yPos, { align: 'center' });
      yPos += 8;

      pdf.setFontSize(12);
      pdf.setTextColor(255, 77, 79);
      pdf.setFont('helvetica', 'normal');
      pdf.text(cvData?.position || 'Developer', 105, yPos, { align: 'center' });
      yPos += 15;

      // Hàm render section title với style thống nhất
      const renderSectionTitle = (title: string) => {
        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(14);
        pdf.setTextColor(255, 71, 71); // Màu đỏ đậm cho tiêu đề

        // Căn lề trái
        pdf.text(title, margin, yPos);
        yPos += 8;

        // Vẽ line decoration
        const lineY = yPos - 4;
        const lineWidth = pdf.internal.pageSize.width - 2 * margin;
        pdf.setDrawColor(255, 71, 71); // Màu đỏ đậm cho line
        pdf.setLineWidth(0.5);
        pdf.line(margin, lineY, margin + lineWidth, lineY);

        yPos += 8;
      };

      // Render PERSONAL INFORMATION
      renderSectionTitle('PERSONAL INFORMATION');
      // Content của Personal Information
      pdf.setFontSize(12);

      if (cvData?.sex || cvData?.nationality) {
        // Tính toán khoảng cách của các label
        const sexLabelWidth = pdf.getTextWidth('Sex:');
        const nationalityLabelWidth = pdf.getTextWidth('Nationality:');
        const labelValueGap = 5; // Khoảng cách đồng đều giữa label và giá trị

        // Labels
        pdf.setFont('helvetica', 'bold');
        pdf.setTextColor(0, 0, 0); // Chuyển sang màu đen cho labels
        pdf.text('Sex:', margin, yPos);
        pdf.text('Nationality:', margin + 80, yPos);

        // Values
        pdf.setFont('helvetica', 'normal');
        const sexText = cvData?.sex || 'N/A';
        const nationalityText = cvData?.nationality || 'N/A';

        // Tách text thành nhiều dòng nếu quá dài
        const sexLines = pdf.splitTextToSize(sexText, 40);
        const nationalityLines = pdf.splitTextToSize(nationalityText, 40);

        // Đặt giá trị với khoảng cách đồng đều từ label
        pdf.text(sexLines, margin + sexLabelWidth + labelValueGap, yPos);
        pdf.text(nationalityLines, margin + 80 + nationalityLabelWidth + labelValueGap, yPos);

        yPos += Math.max(sexLines.length, nationalityLines.length) * 6;
      } else {
        // Hiển thị thông báo khi không có thông tin
        pdf.setTextColor(102, 102, 102);
        pdf.setFont('helvetica', 'italic');
        pdf.text('No personal information', margin, yPos);
        yPos += 10;
      }

      yPos += 10; // Khoảng cách đến section tiếp theo

      // Lấy kích thước trang
      const pageWidth = pdf.internal.pageSize.getWidth();

      // Render EDUCATION
      renderSectionTitle('EDUCATION');
      // Content của Education
      pdf.setFontSize(12);

      if (cvData?.education && cvData.education.length > 0) {
        cvData.education.forEach((edu, index) => {
          // Kiểm tra nếu cần sang trang mới
          if (yPos + 20 > marginBottom) {
            pdf.addPage();
            yPos = margin;
          }

          // University/College
          pdf.setTextColor(0, 0, 0);
          pdf.setFont('helvetica', 'bold');
          const universityName = edu.name || 'N/A';
          pdf.text(universityName, margin, yPos);

          // Tính toán độ rộng của tên trường
          const universityWidth =
            (pdf.getStringUnitWidth(universityName) * 12) / pdf.internal.scaleFactor;

          // Tính toán vị trí giữa của khoảng trống giữa tên trường và major
          const spaceWidth = 30; // Khoảng trống giữa tên trường và major
          const dashPosition = margin + universityWidth + spaceWidth / 2;
          pdf.text('-', dashPosition, yPos);

          // Khoảng cách sau dấu '-'
          const labelValueGap = 5;

          // Major label (không cần hiển thị 'Major' nữa)
          pdf.setFont('helvetica', 'normal');
          pdf.setTextColor(102, 102, 102);

          // Major value (hiển thị trực tiếp sau dấu '-')
          const majorText = edu.major || 'N/A';
          // Tính toán độ rộng còn lại cho major
          const remainingWidth = pageWidth - (dashPosition + spaceWidth / 2) - 20; // 20 là margin phải
          // Tách major thành nhiều dòng nếu quá dài
          const majorLines = pdf.splitTextToSize(majorText, remainingWidth);
          pdf.text(majorLines, dashPosition + spaceWidth / 2, yPos); // Đặt major sau khoảng trống

          yPos += Math.max(10, majorLines.length * 6); // Tăng khoảng cách dựa vào số dòng của major
        });
      } else {
        // Hiển thị thông báo khi không có thông tin
        pdf.setTextColor(102, 102, 102);
        pdf.setFont('helvetica', 'italic');
        pdf.text('No education information', margin, yPos);
        yPos += 10;
      }

      yPos += 10; // Khoảng cách đến section tiếp theo

      // Render LANGUAGE
      renderSectionTitle('LANGUAGE');
      // Content của Language
      pdf.setFontSize(12);
      pdf.setTextColor(60, 60, 60);

      // Kiểm tra nếu cần sang trang mới
      if (yPos + 20 > marginBottom) {
        pdf.addPage();
        yPos = margin + 5; // Giảm margin top khi bắt đầu trang mới
      }

      if (cvData?.languages?.vietnamese || cvData?.languages?.english) {
        // Tính toán khoảng cách của các label
        const vietnameseLabelWidth = pdf.getTextWidth('Vietnamese:');
        const englishLabelWidth = pdf.getTextWidth('English:');
        const labelValueGap = 5; // Khoảng cách đồng đều giữa label và giá trị

        // Labels
        pdf.setFont('helvetica', 'bold');
        pdf.setTextColor(0, 0, 0); // Đảm bảo màu đen cho labels
        pdf.text('Vietnamese:', margin, yPos);
        pdf.text('English:', margin + 80, yPos);

        // Values
        pdf.setFont('helvetica', 'normal');
        pdf.setTextColor(102, 102, 102); // Màu xám cho nội dung
        const vietnameseText = cvData?.languages?.vietnamese || 'N/A';
        const englishText = useDefaultEnglish
          ? 'Intermediate'
          : cvData?.languages?.english || 'Intermediate';

        // Tách text thành nhiều dòng nếu quá dài
        const vietnameseLines = pdf.splitTextToSize(vietnameseText, 40);
        const englishLines = pdf.splitTextToSize(englishText, 40);

        // Đặt giá trị với khoảng cách đồng đều từ label
        pdf.text(vietnameseLines, margin + vietnameseLabelWidth + labelValueGap, yPos);
        pdf.text(englishLines, margin + 80 + englishLabelWidth + labelValueGap, yPos);

        yPos += Math.max(vietnameseLines.length, englishLines.length) * 6;
      } else {
        // Hiển thị thông báo khi không có thông tin
        pdf.setTextColor(102, 102, 102);
        pdf.setFont('helvetica', 'italic');
        pdf.text('No language information', margin, yPos);
        yPos += 10;
      }

      yPos += 10; // Khoảng cách đến section tiếp theo

      // Render PROGRAMMING SKILLS
      renderSectionTitle('PROGRAMMING SKILLS');
      // Content của Programming Skills
      const col1Width = 55; // Items (25%)
      const col3Width = 30; // Years (15%)
      const col2Width = 190 - margin - col1Width - col3Width; // Technical Summary (60%)
      const rowHeight = 8;
      const headerHeight = 10; // Header height cho title
      const categoryGap = 4; // Giảm gap giữa các category

      // Process programming skills data
      const skillsMap = new Map();
      cvData?.programmingSkills?.forEach((category: any) => {
        const processedSkills = processSkills(category);
        if (skillsMap.has(category.name)) {
          skillsMap.get(category.name).push(...processedSkills);
        } else {
          skillsMap.set(category.name, processedSkills);
        }
      });

      // Draw table header with red background
      pdf.setFillColor(255, 77, 79); // Đậm hơn màu đỏ của header
      pdf.rect(margin, yPos, col1Width, headerHeight, 'F');
      pdf.rect(margin + col1Width, yPos, col2Width, headerHeight, 'F');
      pdf.rect(margin + col1Width + col2Width, yPos, col3Width, headerHeight, 'F');

      // Header text
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(10);
      pdf.setTextColor(255, 255, 255);

      // Center align header texts
      const items = 'Items';
      const technical = 'Technical Summary';
      const years = 'Years of';
      const experience = 'Experience';

      const itemsX = margin + (col1Width - pdf.getTextWidth(items)) / 2;
      const technicalX = margin + col1Width + (col2Width - pdf.getTextWidth(technical)) / 2;
      const yearsX = margin + col1Width + col2Width + (col3Width - pdf.getTextWidth(years)) / 2;

      // Canh giữa theo chiều dọc cho header
      const textHeight = 5;
      const verticalCenter = yPos + (headerHeight - textHeight) / 2 + 4;

      pdf.text(items, itemsX, verticalCenter);
      pdf.text(technical, technicalX, verticalCenter);

      // Years of Experience căn giữa hoàn toàn
      const yearsWidth = pdf.getTextWidth(years);
      const experienceWidth = pdf.getTextWidth(experience);
      const maxWidth = Math.max(yearsWidth, experienceWidth);
      const yearsStartX = margin + col1Width + col2Width + (col3Width - maxWidth) / 2;
      const yearsVerticalCenter = yPos + headerHeight / 2;

      pdf.text(years, yearsStartX, yearsVerticalCenter - 1);
      pdf.text(experience, yearsStartX, yearsVerticalCenter + 3);

      yPos += headerHeight;

      // Vẽ đường kẻ trắng cho header
      pdf.setDrawColor(255, 255, 255);
      pdf.setLineWidth(0.2);
      pdf.line(margin + col1Width, yPos - headerHeight, margin + col1Width, yPos);
      pdf.line(
        margin + col1Width + col2Width,
        yPos - headerHeight,
        margin + col1Width + col2Width,
        yPos,
      );

      // Helper function to draw a portion of category
      const drawCategoryPortion = (
        category: string,
        skills: any[],
        isFirstPortion: boolean,
      ): void => {
        const portionHeight = rowHeight * skills.length;

        // Draw category background
        pdf.setFillColor(245, 245, 245);
        pdf.rect(margin, yPos, col1Width, portionHeight, 'F');

        // Draw category name only on first portion
        if (isFirstPortion) {
          pdf.setTextColor(60, 60, 60);
          pdf.setFont('helvetica', 'bold');
          const categoryX =
            margin +
            (col1Width -
              (pdf.getStringUnitWidth(category) * pdf.getFontSize()) / pdf.internal.scaleFactor) /
              2;
          const categoryY = yPos + (rowHeight * skills.length) / 2;
          pdf.text(category, categoryX, categoryY);
        }

        // Draw skills
        skills.forEach((item: any, index: number) => {
          // Draw white background for Technical Summary and Years
          pdf.setFillColor(255, 255, 255);
          pdf.rect(margin + col1Width, yPos, col2Width, rowHeight, 'F');
          pdf.rect(margin + col1Width + col2Width, yPos, col3Width, rowHeight, 'F');

          // Draw borders
          pdf.setDrawColor(230, 230, 230); // Làm nhạt màu xám của border
          pdf.setLineWidth(0.2);

          // Draw full borders for Technical Summary and Years columns
          pdf.rect(margin + col1Width, yPos, col2Width, rowHeight);
          pdf.rect(margin + col1Width + col2Width, yPos, col3Width, rowHeight);

          // For Items column
          // Draw vertical lines
          pdf.line(margin, yPos, margin, yPos + rowHeight); // Left border
          pdf.line(margin + col1Width, yPos, margin + col1Width, yPos + rowHeight); // Right border

          // Draw horizontal lines only at start/end
          if (index === 0) {
            pdf.line(margin, yPos, margin + col1Width, yPos);
          }
          if (index === skills.length - 1) {
            pdf.line(margin, yPos + rowHeight, margin + col1Width, yPos + rowHeight);
          }

          // Reset draw color to white for next vertical lines
          pdf.setDrawColor(255, 255, 255);
          // Draw skill text
          pdf.setTextColor(60, 60, 60);
          pdf.setFont('helvetica', 'normal');

          // Technical Summary - căn giữa theo chiều dọc
          const technicalY = yPos + rowHeight / 2 + 1;
          const technical = Array.isArray(item.skill) ? item.skill.join(', ') : item.skill || '-';
          pdf.text(technical, margin + col1Width + 3, technicalY);

          // Years - căn giữa theo chiều dọc và ngang
          const years = item.years || '-';
          const yearsWidth = pdf.getTextWidth(years);
          const yearsX = margin + col1Width + col2Width + (col3Width - yearsWidth) / 2;
          pdf.text(years, yearsX, technicalY);

          yPos += rowHeight;
        });
      };

      // Process each category
      Array.from(skillsMap.entries()).forEach(([category, skills]) => {
        const categoryHeight = rowHeight * skills.length;

        // Check if need new page for this category
        if (yPos + categoryHeight > marginBottom) {
          // Tính toán số rows có thể fit vào trang hiện tại
          const remainingSpace = marginBottom - yPos;
          const maxRowsOnPage = Math.floor(remainingSpace / rowHeight);

          if (maxRowsOnPage > 0) {
            // Vẽ một phần của category trên trang hiện tại
            const currentPageSkills = skills.slice(0, maxRowsOnPage);
            const remainingSkills = skills.slice(maxRowsOnPage);

            // Draw current page portion
            drawCategoryPortion(category, currentPageSkills, true);

            // Move to next page for remaining skills
            pdf.addPage();
            yPos = margin + 5; // Giảm margin top khi bắt đầu trang mới

            // Draw remaining skills on new page
            if (remainingSkills.length > 0) {
              drawCategoryPortion(category, remainingSkills, false);
            }
          } else {
            // Không đủ chỗ cho một row nào, chuyển toàn bộ sang trang mới
            pdf.addPage();
            yPos = margin + 5; // Giảm margin top khi bắt đầu trang mới
            drawCategoryPortion(category, skills, true);
          }
        } else {
          // Vẽ toàn bộ category trên trang hiện tại
          drawCategoryPortion(category, skills, true);
        }

        yPos += categoryGap;
      });

      // Add spacing before next section
      yPos += 14;

      renderSectionTitle('WORK EXPERIENCE');

      // Content của Work Experience
      if (yPos + 8 + 20 > marginBottom) {
        pdf.addPage();
        yPos = margin + 5; // Giảm margin top khi bắt đầu trang mới
      }

      //WORK EXPERIENCE
      if (cvData?.projects && cvData.projects.length > 0) {
        const workExperience = cvData.projects;

        // Tính toán không gian còn lại trên trang hiện tại
        const calculateRemainingSpace = () => {
          return marginBottom - yPos;
        };

        workExperience.forEach((project, index) => {
          // Tính toán độ rộng tối đa của trang
          const maxWidth = pdf.internal.pageSize.width - 2 * margin;
          // Độ rộng nội dung chính xác bằng với line divider
          const contentMaxWidth = maxWidth - 4; // Trừ đi 4px để tránh lố chữ

          // Hàm kiểm tra không gian còn lại và tạo trang mới nếu cần
          const checkAndAddNewPageIfNeeded = (neededSpace: number): boolean => {
            if (yPos + neededSpace > marginBottom) {
              pdf.addPage();
              yPos = margin + 5;
              return true;
            }
            return false;
          };

          // Xử lý Project Title và Duration
          const nameLines = optimizeTextWrapping(
            project.name || 'N/A',
            contentMaxWidth * 0.82,
            pdf,
          );
          const titleHeight = nameLines.length * 7 + 8; // Chiều cao cho title + divider + spacing

          checkAndAddNewPageIfNeeded(titleHeight);

          // Project Title với style hiện đại
          pdf.setFont('helvetica', 'bold');
          pdf.setFontSize(14); // Tăng font size lên 14
          pdf.setTextColor(0, 0, 0); // Đồng bộ màu đen cho tiêu đề

          // Project Title (bên trái)
          nameLines.forEach((line, index) => {
            pdf.text(line, margin, yPos + index * 7); // Căn lề trái đồng bộ với các section khác
          });

          pdf.setFont('helvetica', 'normal'); // Reset font style cho các phần còn lại

          // Duration (bên phải) với style nổi bật
          if (project.duration) {
            pdf.setFontSize(10); // Giảm font size cho duration
            const durationWidth = pdf.getTextWidth(project.duration);
            pdf.setFont('helvetica', 'normal');
            pdf.setTextColor(102, 102, 102); // Đồng bộ màu xám cho duration
            pdf.text(project.duration, pdf.internal.pageSize.width - margin - durationWidth, yPos);
            pdf.setFontSize(14); // Khôi phục font size cho các phần khác
          }

          yPos += Math.max(nameLines.length * 7, 7) + 3;

          // Vẽ line divider mỏng đồng bộ với các section khác
          const lineWidth = pdf.internal.pageSize.width - 2 * margin;
          pdf.setDrawColor(230, 230, 230); // Làm nhạt màu xám của line
          pdf.line(margin, yPos - 1, margin + lineWidth, yPos - 1);
          yPos += 5;

          // Xử lý Role
          if (project.role && project.role !== 'N/A') {
            const roleLines = optimizeTextWrapping(project.role || 'N/A', contentMaxWidth - 5, pdf);
            const roleHeight = roleLines.length * 5 + 8; // Chiều cao cho role title + nội dung + spacing

            checkAndAddNewPageIfNeeded(roleHeight);

            pdf.setFont('helvetica', 'bold');
            pdf.setFontSize(11);
            pdf.setTextColor(0, 0, 0); // Đồng bộ màu đen cho tiêu đề

            // Icon cho Role
            pdf.setFillColor(102, 102, 102); // Đồng bộ màu xám cho bullet point
            pdf.circle(margin + 2, yPos - 1.5, 1.2, 'F');
            pdf.text('Role', margin + 9, yPos);
            yPos += 6;

            pdf.setFont('helvetica', 'normal');
            pdf.setTextColor(102, 102, 102); // Đồng bộ màu xám cho nội dung
            roleLines.forEach((line) => {
              pdf.text(line, margin + 9, yPos);
              yPos += 5;
            });
            yPos += 2;
          }

          // Xử lý Skills
          if (project.skills && project.skills !== 'N/A') {
            const skillLines = optimizeTextWrapping(
              project.skills || 'N/A',
              contentMaxWidth - 5,
              pdf,
            );
            const skillsHeight = skillLines.length * 5 + 8; // Chiều cao cho skills title + nội dung + spacing

            checkAndAddNewPageIfNeeded(skillsHeight);

            pdf.setFont('helvetica', 'bold');
            pdf.setFontSize(11);
            pdf.setTextColor(0, 0, 0); // Đồng bộ màu đen cho tiêu đề

            // Icon cho Skills
            pdf.setFillColor(102, 102, 102); // Đồng bộ màu xám cho bullet point
            pdf.circle(margin + 2, yPos - 1.5, 1.2, 'F');
            pdf.text('Skills', margin + 9, yPos);
            yPos += 6;

            pdf.setFont('helvetica', 'normal');
            pdf.setTextColor(102, 102, 102); // Đồng bộ màu xám cho nội dung
            skillLines.forEach((line) => {
              pdf.text(line, margin + 9, yPos);
              yPos += 5;
            });
            yPos += 2;
          }

          // Xử lý Responsibilities
          if (project.responsibilities && project.responsibilities !== 'N/A') {
            const respLines = optimizeTextWrapping(
              project.responsibilities || 'N/A',
              contentMaxWidth - 5,
              pdf,
            );
            const respHeight = respLines.length * 5 + 7; // Chiều cao cho responsibilities title + nội dung + spacing

            checkAndAddNewPageIfNeeded(respHeight);

            pdf.setFont('helvetica', 'bold');
            pdf.setFontSize(11);
            pdf.setTextColor(0, 0, 0); // Đồng bộ màu đen cho tiêu đề

            // Icon cho Responsibilities
            pdf.setFillColor(102, 102, 102); // Đồng bộ màu xám cho bullet point
            pdf.circle(margin + 2, yPos - 1.5, 1.2, 'F');
            pdf.text('Responsibilities', margin + 9, yPos);
            yPos += 6;

            pdf.setFont('helvetica', 'normal');
            pdf.setTextColor(102, 102, 102); // Đồng bộ màu xám cho nội dung
            respLines.forEach((line) => {
              pdf.text(line, margin + 9, yPos);
              yPos += 5;
            });
            yPos += 1;
          }

          // Thêm khoảng cách giữa các project
          yPos += 8;
          console.log(
            `Project ${index + 1} (${
              project.name
            }): yPos=${yPos}, remainingSpace=${calculateRemainingSpace()}`,
          );
        });
      } else {
        pdf.setTextColor(128, 128, 128);
        pdf.setFont('helvetica', 'italic');
        pdf.text('No work experience information', margin, yPos);
        yPos += 10;
      }

      // Add footer to all pages
      const pageCount = pdf.getNumberOfPages();
      const companyName = 'Innotech Vietnam Corporation';

      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);

        // Draw company name
        pdf.setFontSize(10);
        pdf.setTextColor(180, 180, 180);
        const companyX = (210 - pdf.getTextWidth(companyName)) / 2;
        pdf.text(companyName, companyX, marginBottom + 6); // Giảm khoảng cách với footer xuống 6mm
      }

      const fileName = `CV-${cvData?.name || 'Untitled'}.pdf`;
      pdf.save(fileName);
      message.success({
        content: t('recruitment:pdf_success'),
        key: 'pdf-export',
      });
    } catch (error) {
      console.error('PDF export error:', error);
      message.error({
        content: t('recruitment:pdf_error'),
        key: 'pdf-export',
      });
    }
  };

  // Hàm tối ưu text wrapping
  const optimizeTextWrapping = (text: string, maxWidth: number, pdfDoc: jsPDF) => {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    words.forEach((word) => {
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      const testWidth = pdfDoc.getTextWidth(testLine);

      if (testWidth > maxWidth && currentLine) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };

  const renderCV = () => {
    if (!cvData) return null;

    return (
      <CVContainer>
        <Logo src="/logo.png" alt="Company Logo" />
        <div className="cv-content">
          <Title>
            {isEditing ? (
              <CenteredInput
                value={editData?.name || ''}
                onChange={(e) => handleInputChange('name', e.target.value)}
              />
            ) : (
              cvData.name || 'Name'
            )}
          </Title>
          <PositionContainer>
            {isEditing ? (
              <PositionInput
                value={editData?.position || ''}
                onChange={(e) => handleInputChange('position', e.target.value)}
              />
            ) : (
              cvData.position || 'Developer'
            )}
          </PositionContainer>

          <Section>
            <SectionTitle>PERSONAL INFORMATION</SectionTitle>
            <InfoRow>
              <FlexRow>
                <FlexColumn>
                  <InfoLabel>Sex:</InfoLabel>
                  {isEditing ? (
                    <StyledInput
                      value={editData?.sex || ''}
                      onChange={(e) => handleInputChange('sex', e.target.value)}
                    />
                  ) : (
                    <InfoValue>{cvData.sex || '-'}</InfoValue>
                  )}
                </FlexColumn>
                <FlexColumn>
                  <InfoLabel>Nationality:</InfoLabel>
                  {isEditing ? (
                    <StyledInput
                      value={editData?.nationality || ''}
                      onChange={(e) => handleInputChange('nationality', e.target.value)}
                    />
                  ) : (
                    <InfoValue>{cvData.nationality || '-'}</InfoValue>
                  )}
                </FlexColumn>
              </FlexRow>
            </InfoRow>
          </Section>

          <Section>
            <SectionHeader>
              <NoUnderlineSectionTitle>EDUCATION</NoUnderlineSectionTitle>
              {isEditing && (
                <Button type="primary" size="small" onClick={handleAddEducation}>
                  + Add Education
                </Button>
              )}
            </SectionHeader>
            {(() => {
              const educationList = isEditing ? editData?.education : cvData?.education;
              return (educationList || [])?.map((edu, index) => (
                <EducationItem key={index}>
                  {isEditing && (
                    <Tooltip title="Remove education">
                      <DeleteButton onClick={() => handleRemoveEducation(index)} />
                    </Tooltip>
                  )}
                  <EducationRow>
                    <EducationSchool>
                      {isEditing ? (
                        <div>
                          <FieldLabel>University/College</FieldLabel>
                          <Input
                            placeholder="Enter University/College name"
                            value={editData?.education?.[index].name || ''}
                            onChange={(e) => handleEducationChange(index, 'name', e.target.value)}
                          />
                        </div>
                      ) : (
                        <SchoolName>{edu?.name || '-'}</SchoolName>
                      )}
                    </EducationSchool>
                    <EducationMajor>
                      {isEditing ? (
                        <div>
                          <FieldLabel>Major(s)</FieldLabel>
                          <Input
                            placeholder="Enter major(s), separate by comma"
                            value={editData?.education?.[index].major || ''}
                            onChange={(e) => handleEducationChange(index, 'major', e.target.value)}
                          />
                        </div>
                      ) : (
                        <MajorName>
                          {edu?.major?.split(',').map((major, idx) => (
                            <span key={idx}>
                              {idx > 0 && <BulletPoint>•</BulletPoint>}
                              {major.trim()}
                            </span>
                          )) || '-'}
                        </MajorName>
                      )}
                    </EducationMajor>
                  </EducationRow>
                </EducationItem>
              ));
            })()}
            {(() => {
              const educationList = isEditing ? editData?.education : cvData?.education;
              return (!educationList || educationList.length === 0) && !isEditing ? (
                <NoEducationInfo>No education information</NoEducationInfo>
              ) : null;
            })()}
          </Section>

          <Section>
            <SectionHeader>
              <NoUnderlineSectionTitle>LANGUAGE</NoUnderlineSectionTitle>
            </SectionHeader>
            <InfoRow>
              <FlexRow>
                <FlexColumn>
                  <LanguageLabel>Vietnamese:</LanguageLabel>
                  {isEditing ? (
                    <StyledInput
                      value={editData?.languages?.vietnamese || ''}
                      onChange={(e) => handleLanguageChange('vietnamese', e.target.value)}
                    />
                  ) : (
                    <LanguageValue>{cvData.languages?.vietnamese || '-'}</LanguageValue>
                  )}
                </FlexColumn>
                <FlexColumn>
                  <LanguageLabel>English:</LanguageLabel>
                  <CheckboxContainer>
                    {isEditing ? (
                      <CheckboxContainer>
                        <StyledInput
                          value={
                            useDefaultEnglish ? 'Intermediate' : editData?.languages?.english || ''
                          }
                          onChange={(e) => {
                            if (!useDefaultEnglish) {
                              handleLanguageChange('english', e.target.value);
                            }
                          }}
                          disabled={useDefaultEnglish}
                        />
                        <Button
                          size="small"
                          onClick={() => {
                            const newUseDefault = !useDefaultEnglish;
                            setUseDefaultEnglish(newUseDefault);
                            if (!newUseDefault) {
                              handleLanguageChange('english', cvData?.languages?.english || '');
                            }
                          }}
                          className="padded-button"
                        >
                          {useDefaultEnglish ? 'Use Data' : 'Use Default'}
                        </Button>
                      </CheckboxContainer>
                    ) : (
                      <LanguageValue>
                        {useDefaultEnglish
                          ? 'Intermediate'
                          : cvData.languages?.english || 'Intermediate'}
                      </LanguageValue>
                    )}
                  </CheckboxContainer>
                </FlexColumn>
              </FlexRow>
            </InfoRow>
          </Section>

          {cvData.programmingSkills && cvData.programmingSkills.length > 0 && (
            <Section>
              <SectionHeader>
                <NoUnderlineSectionTitle>PROGRAMMING SKILLS</NoUnderlineSectionTitle>
                {isEditing && editData && (
                  <div>
                    <Button
                      type="primary"
                      size="small"
                      onClick={handleAddCategory}
                      className="margin-right-button"
                    >
                      + Add Category
                    </Button>
                  </div>
                )}
              </SectionHeader>
              <SkillsTable
                isEditing={isEditing}
                editData={editData}
                cvData={cvData}
                setEditData={setEditData}
                handleAddSkillToCategory={handleAddSkillToCategory}
                handleUpdateCategorySkill={handleUpdateCategorySkill}
                handleRemoveSkillFromCategory={handleRemoveSkillFromCategory}
              />
            </Section>
          )}

          {cvData.projects && cvData.projects.length > 0 && (
            <Section>
              <SectionHeader>
                <NoUnderlineSectionTitle>WORK EXPERIENCE</NoUnderlineSectionTitle>
                {isEditing && (
                  <Button type="primary" size="small" onClick={handleAddProject}>
                    + Add Project
                  </Button>
                )}
              </SectionHeader>
              {(() => {
                const projects = isEditing ? editData?.projects : cvData?.projects;
                return projects?.map((project, index) => (
                  <ProjectCard key={index}>
                    {isEditing && (
                      <Tooltip title="Remove project">
                        <ProjectDeleteButton onClick={() => handleRemoveProject(index)} />
                      </Tooltip>
                    )}
                    <ProjectRow>
                      <ProjectNameContainer>
                        {isEditing ? (
                          <div>
                            <FieldLabel>Project Name</FieldLabel>
                            <Input
                              placeholder="Enter project name"
                              value={editData?.projects?.[index]?.name || ''}
                              onChange={(e) => handleProjectChange(index, 'name', e.target.value)}
                            />
                          </div>
                        ) : (
                          <ProjectNameDisplay>{project.name || '-'}</ProjectNameDisplay>
                        )}
                      </ProjectNameContainer>
                      <ProjectDurationContainer>
                        {isEditing ? (
                          <div>
                            <FieldLabel>Duration</FieldLabel>
                            <Input
                              placeholder="Enter duration (e.g. Sep 2024 - Present)"
                              value={editData?.projects?.[index]?.duration || ''}
                              onChange={(e) =>
                                handleProjectChange(index, 'duration', e.target.value)
                              }
                            />
                          </div>
                        ) : (
                          <ProjectDurationText>{project.duration || '-'}</ProjectDurationText>
                        )}
                      </ProjectDurationContainer>
                    </ProjectRow>
                    <ProjectSection>
                      <ProjectLabel>
                        <strong>Role</strong>
                      </ProjectLabel>
                      {isEditing ? (
                        <Input
                          placeholder="Enter role"
                          value={editData?.projects?.[index]?.role || ''}
                          onChange={(e) => handleProjectChange(index, 'role', e.target.value)}
                        />
                      ) : (
                        <ProjectContent>{project.role || '-'}</ProjectContent>
                      )}
                    </ProjectSection>
                    <ProjectSection>
                      <ProjectLabel>
                        <strong>Skills</strong>
                      </ProjectLabel>
                      {isEditing ? (
                        <Input
                          placeholder="Enter skills"
                          value={editData?.projects?.[index]?.skills || ''}
                          onChange={(e) => handleProjectChange(index, 'skills', e.target.value)}
                        />
                      ) : (
                        <ProjectContent>{project.skills || '-'}</ProjectContent>
                      )}
                    </ProjectSection>
                    <ProjectSection>
                      <ProjectLabel>
                        <strong>Responsibilities</strong>
                      </ProjectLabel>
                      {isEditing ? (
                        <Input.TextArea
                          placeholder="Enter responsibilities"
                          value={editData?.projects?.[index]?.responsibilities || ''}
                          onChange={(e) =>
                            handleProjectChange(index, 'responsibilities', e.target.value)
                          }
                        />
                      ) : (
                        <ProjectContent>{project.responsibilities || '-'}</ProjectContent>
                      )}
                    </ProjectSection>
                  </ProjectCard>
                ));
              })()}
            </Section>
          )}

          {showEditButton && (
            <EditButtons>
              {!isEditing ? (
                <Button type="primary" onClick={handleEdit}>
                  {t('recruitment:edit')}
                </Button>
              ) : (
                <>
                  <CancelButton onClick={handleCancel}>{t('recruitment:cancel')}</CancelButton>
                  <SaveButton type="primary" onClick={handleSave}>
                    {t('recruitment:save')}
                  </SaveButton>
                </>
              )}
            </EditButtons>
          )}
        </div>
      </CVContainer>
    );
  };

  return (
    <>
      <style>{globalStyles}</style>
      <Modal
        visible={visible}
        onCancel={onClose}
        width={800}
        footer={
          cvData ? (
            [
              <RefreshButton
                key="refresh"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={handleFileUpload}
                loading={loading}
              >
                {t('recruitment:reanalyze')}
              </RefreshButton>,
              <Button
                key="export"
                type="primary"
                icon={<DownloadOutlined />}
                onClick={handleExportPDF}
                disabled={isEditing}
                title={isEditing ? 'Please save or cancel your changes before exporting' : ''}
              >
                {t('recruitment:export_pdf')}
              </Button>,
            ]
          ) : (
            <Button
              type="primary"
              onClick={handleFileUpload}
              loading={loading}
              disabled={!selectedTemplate}
            >
              {t('recruitment:analyze_cv')}
            </Button>
          )
        }
        title={hasCV ? 'CV Onsite' : 'No CV Available'}
      >
        {!cvData && hasCV && (
          <div>
            <TemplateContainer>
              <TemplateTitle>{t('recruitment:select_cv_template')}</TemplateTitle>
              <TemplateOptions>
                {templateOptions.map((option) => (
                  <TemplateOption
                    key={option.value}
                    selected={selectedTemplate === option.value}
                    onClick={() => setSelectedTemplate(option.value)}
                  >
                    <h4>{option.label}</h4>
                    <p>
                      {option.value === CVTemplate.TESTER && t('recruitment:tester_cv_description')}
                      {option.value === CVTemplate.PROJECT_MANAGER &&
                        t('recruitment:project_manager_cv_description')}
                      {option.value === CVTemplate.DEVELOPER &&
                        t('recruitment:developer_cv_description')}
                    </p>
                  </TemplateOption>
                ))}
              </TemplateOptions>
            </TemplateContainer>
          </div>
        )}

        {cvData && (
          <>
            <CVContentContainer>{renderCV()}</CVContentContainer>
          </>
        )}

        {!hasCV && (
          <NoCVContainer>
            <NoCVTitle>{t('recruitment:no_cv_available')}</NoCVTitle>
            <NoCVDescription>{t('recruitment:please_upload_cv_first')}</NoCVDescription>
          </NoCVContainer>
        )}

        {loading && (
          <>
            <LoadingContent>
              <LoadingTextContainer>
                <Spin size="large" />
                <LoadingText>{t('recruitment:ai_processing_cv')}</LoadingText>
              </LoadingTextContainer>
            </LoadingContent>
            <LoadingOverlay />
          </>
        )}
      </Modal>
    </>
  );
};

export default CVOnsite;
