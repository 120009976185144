import { DownOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import CustomButton from 'styles/buttonStyled';
import JobsRequisitionStaffViewTable from './components/JobsRequisitionStaffViewTable';
import { Modal, Popover } from 'antd';
import CreateJob from './components/CreateJob';
import { getUser } from 'utils/auth';
import { PermissionUser } from './components/types';
import { useTranslation } from 'react-i18next';
import { CustomButtonFilter } from 'features/employee/components/Functions';
import JobSimpleFilter from './components/JobSimpleFilter';

export enum VIEW_SCREEN {
  CREATE_JOB = 'CREATE_JOB',
  COMPLETE_A_FORM = 'COMPLETE_A_FORM',
  USE_A_TEMPLATE = 'USE_A_TEMPLATE',
}
const JobsRequisitionStaff = () => {
  const { t } = useTranslation(['recruitment', 'employee']);
  const location = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [currentScreen, setCurrentScreen] = useState(VIEW_SCREEN.CREATE_JOB);
  const [isOpenSimpleFilter, setIsOpenSimpleFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const handleOpenSimpleFilter = (visible: any) => {
    setIsOpenSimpleFilter(visible);
  };

  return (
    <>
      <PageHeader title={titleHeader} />
      <ITVSearch style={{ marginBottom: '1rem' }} handleSearch={setSearch} />
      <Popover
        placement="bottom"
        content={
          <JobSimpleFilter
            onFilterChange={(value) => {
              setFilter(value);
              setCurrentPage(1);
            }}
          />
        }
        trigger="click"
        open={isOpenSimpleFilter}
        onOpenChange={handleOpenSimpleFilter}
      >
        <CustomButtonFilter style={{ marginLeft: 8 }}>
          <FilterOutlined />
          {t('employee:filter.title')}
          <DownOutlined />
        </CustomButtonFilter>
      </Popover>
      <JobsRequisitionStaffViewTable
        search={search}
        filter={filter}
        sizePage={sizePage}
        setSizePage={setSizePage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
        destroyOnClose
        width={currentScreen === VIEW_SCREEN.COMPLETE_A_FORM ? 1000 : undefined}
      >
        <CreateJob setCurrentScreen={setCurrentScreen} setVisible={setVisible} />
      </Modal>
    </>
  );
};

export default JobsRequisitionStaff;
