import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Note from 'components/Note';

interface TooltipNoteProps {
  employeeId: string;
  id?: string;
  contextType: string;
}

const TooltipNote: React.FC<TooltipNoteProps> = ({ employeeId, id, contextType }) => {
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const { t } = useTranslation(['note']);

  return (
    <>
      <Tooltip title={t('note:notes')}>
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setIsNoteModalVisible(true)}
          style={{
            position: 'fixed',
            bottom: '24px',
            right: '24px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            padding: 0,
          }}
        />
      </Tooltip>
      <Note
        visible={isNoteModalVisible}
        onClose={() => setIsNoteModalVisible(false)}
        userId={employeeId}
        contextType={contextType}
        idType={id}
      />
    </>
  );
};

export default TooltipNote;
