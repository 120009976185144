import { useRef, useEffect } from 'react';

const useSpeechRecognition = (setInputValue: (value: string) => void) => {
  const recognition = useRef<SpeechRecognition | null>(null);

  useEffect(() => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognition.current = new SpeechRecognition();
      recognition.current.continuous = false;
      recognition.current.interimResults = false;
      recognition.current.lang = 'vi-VN';

      recognition.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setInputValue(transcript);
      };

      recognition.current.onerror = (event) => {
        console.error('Speech recognition error:', event);
      };
    } else {
      console.warn('Speech recognition not supported in this browser.');
    }
  }, [setInputValue]);

  const startRecognition = () => {
    if (recognition.current) {
      if (recognition.current.state !== 'inactive') {
        recognition.current.stop();
      }
      recognition.current.start();
    }
  };

  return { startRecognition };
};

export default useSpeechRecognition;
