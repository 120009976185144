import { IRole } from 'hrm-common/extensions/interfaces/personel';
import { setItem, getJson, removeItem } from './storage';

export const setUser = (user: any) => {
  setItem('user', JSON.stringify(user));
};

export const getUser = () => {
  return getJson('user');
};

export const logout = () => {
  removeItem('user');
};

export const getToken = () => {
  const localUser = getUser();
  if (!localUser) {
    return null;
  }
  return localUser.accessToken;
};

export const removeStorage = () => {
  localStorage.removeItem('hrm.user');
  localStorage.removeItem('hrm.tenant');
  localStorage.removeItem('hrm.register');
  localStorage.removeItem('os_pageViews');
  localStorage.removeItem('isOptedOut');
  localStorage.removeItem('isPushNotificationsEnabled');
  localStorage.removeItem('onesignal-notification-prompt');
  localStorage.removeItem('persist:root');
  localStorage.removeItem('current_date_choose');
  localStorage.removeItem('isVerifyOTP');
};

export const getLanguage = () => {
  return getJson('language');
};

export const checkIsRoleSystemAdmin = (roles: IRole[]) => {
  return roles.some((role) => role.name === 'System Admin');
};

export const permisisonsLineManager = [
  'INSURANCE_PARTICIPANT_LIST_EXPORT',
  'TIMESHEET_MONTH_APPROVAL',
  'TIMEKEEPING_ACCESS_PERSONAL',
  'PAYSLIP_ACCESS_PERSONAL',
  'PERSONNEL_RECORD_APPROVAL',
  'PERSONNEL_RECORD_ACTIVE_DEACTIVE',
  'EMAIL_TEMPLATE_MANAGE',
  'PROFILE_ACCESS_PERSONAL',
  'ONBOARD_ACCESS_PERSONNEL',
  'DEVICE_MANAGE',
  'OFFBOARD_REQUEST_APPROVAL',
  'DEVICE_ACCESS_PERSONNEL',
  'DASHBOARD_ACCESS_MANAGER',
  'DASHBOARD_ACCESS',
  'TIMESHEET_VIEW_EMPLOYEE',
  'TIMESHEET_VIEW_PERSONAL',
  'TIMESHEET_VIEW_TIMECLOCK',
  'TIMESHEET_VIEW_OVERTIME',
  'TIMESHEET_VIEW_TIMEOFF',
  'TIMESHEET_ADJUST_TIMECLOCK',
  'TIMESHEET_OVERTIME_REQUEST',
  'TIMESHEET_TIMEOFF_REQUEST',
  'TIMESHEET_CONFIRM',
  'TIMESHEET_ACCESS_PERSONAL',
  'CATEGORY_ADD',
  'CATEGORY_UPDATE',
  'TIMESHEET_VIEW_OBSERVATION',
  'TIMESHEET_ACCESS_OBSERVATION_LIST',
  'PERSONNEL_VIEW_LIST',
  'PERSONNEL_ADD_RECORD',
  'PERSONNEL_VIEW_RECORD',
  'POSITION_VIEW',
  'PERSONNEL_EXPORT',
  'PERSONNEL_MANAGE',
  'DEPARTMENT_VIEW',
  'OFFBOARD_EMPLOYEE_PROCESS',
  'TIMESHEET_VIEW_LIST_REQUEST',
  'ONBOARD_MANAGE_EMPLOYEE',
  'TIMESHEET_MANAGE_EMPLOYEE',
  'OFFBOARD_MANAGE_EMPLOYEE',
  'ONBOARD_MANAGE_TASK',
  'ONBOARD_EVALUATE',
  'TIMESHEET_OVERVIEW_LIST_REQUEST',
  'TIMESHEET_REQUEST_APPROVAL',
  'DEPARTMENT_MANAGE',
  'POSITION_UPDATE',
  'ONBOARD_MANAGE_PROCESS',
  'POSITION_MANAGE',
  'PERMISSION_VIEW_LIST',
  'RECORD_INSURANCE_PAYMENT_VIEW',
  'EDIT_PERSONAL_PROFILE',
  'INSURANCE_APPROVAL_LIST_VIEW',
  'INSURANCE_APPROVAL_APPROVE/REJECT_REQUEST',
  'INSURANCE_APPROVAL_DELETE',
  'TIMESHEET_RESET_MONTH',
  'OFFBOARD_LIST',
  'INSURANCE_APPROVAL',
  'OFFBOARD_REQUEST_APPROVAL_FIRST_CONFIRM',
  'OFFBOARD_REQUEST_APPROVAL_SECOND_CONFIRM',
  'HOME_MOBILE_CREATE_REQUEST',
  'HOME_MOBILE_REQUEST_APPROVEMENT',
  'OFFBOARD_MANAGE_PROCESS',
  'HOME_MOBILE_STAFF_CHECKING',
  'INSURANCE_PARTICIPANTS_LIST_VIEW',
  'DEVICE_HANDOVER',
  'DEVICE_RETRIEVE',
  'ONBOARD_LIST',
  'ONBOARD_EMPLOYEE_PROCESS',
  'ONBOARD_HANDOVER_EQUIPMENT',
  'TIMESHEET_RESET_REQUEST',
  'DASHBOARD_ACCESS_HR',
  'INSURANCE_PARTICIPANT_DETAIL_VIEW',
  'INSURANCE_PARTICIPANTS_LIST',
  'PERSONNEL_RECORD_REQUEST_APPROVAL',
  'RECORD_INSURANCE_PAYMENT',
  'PROFILE_VIEW_PERSONAL',
  'PROFILE_PERSONAL_EDIT',
  'ONBOARD_CONDUCT_TASK',
  'ONBOARD_SELF_EVALUATE',
  'CATEGORY_VIEW',
  'DEVICE_RECEIVE_EQUIPMENT',
  'PERSONNEL_UPDATE_RECORD',
  'TIMESHEET_CANCEL_ADJUST_TIMECLOCK',
  'TIMESHEET_CANCEL_OVERTIME',
  'TIMESHEET_CANCEL_TIMEOFF',
  'OFFBOARD_ACCESS_PERSONNEL',
  'OFFBOARD_CREATE_REQUEST',
  'OFFBOARD_CANCEL_REQUEST',
  'HOME_MOBILE',
  'CATEGORY_DELETE',
  'TIMESHEET_OBSERVATION_VIEW_OVERTIME',
  'CATEGORY_MANAGE',
  'POSITION_DELETE',
  'DEPARTMENT_ADD',
  'DEPARTMENT_UPDATE',
  'COMPULSORY_INSURANCE_SETTING_UPDATE',
  'COMPULSORY_INSURANCE_SETTING',
  'SCHEDULE_INCREASE/DECREASE_LIST',
  'SCHEDULE_INCREASE/DECREASE_CREATE',
  'SCHEDULE_INCREASE/DECREASE',
  'TIMESHEET_OBSERVATION_VIEW_TIMEOFF',
  'DEVICE_INVENTORY',
  'TIMESHEET_ADJUST_TIMECLOCK_APPROVAL',
  'TIMESHEET_OVERTIME_APPROVAL',
  'TIMESHEET_TIMEOFF_APPROVAL',
  'REQUEST_APPROVAL',
  'TIMESHEET_EXPORT',
  'OFFBOARD_HANDOVER_EQUIPMENT',
  'OFFBOARD_HANDOVER_JOB',
  'OFFBOARD_HANDOVER_JOB_CONFIRM',
  'OFFBOARD_EXIST_INTERVIEW',
  'OFFBOARD_EXIST_INTERVIEW_INVITE',
  'DEVICE_RETURN_EQUIPMENT',
  'LEAVE_OFF_MANAGE_ACCESS',
  'ONBOARD_EMPLOYEE',
  'EMAIL_TEMPLATE_VIEW',
  'EMAIL_TEMPLATE_ADD',
  'EMAIL_TEMPLATE_UPDATE',
  'EMAIL_TEMPLATE_DELETE',
  'EMAIL_TEMPLATE_SEND',
  'DEPARTMENT_DELETE',
  'POSITION_ADD',
  'PERMISSION_UPDATE_ROLE',
  'PERMISSION_MANAGE',
  'COMPULSORY_INSURANCE_SETTING_VIEW',
  'COMPULSORY_INSURANCE_VIEW_HISTORY',
  'SCHEDULE_INCREASE/DECREASE_EDIT',
  'SCHEDULE_INCREASE/DECREASE_DELETE',
  'SCHEDULE_INCREASE/DECREASE_EXPORT',
  'TIMESHEET_SYNCHRONIZED_DATA',
  'LEAVE_OFF_CREATE_REASON',
  'TIMESHEET_ADJUST_TIMECLOCK_FOR_EMPLOYEE',
  'LEAVEOFF_CREATE_FOR_EMPLOYEE',
  'WORKSHIFT_MANAGE',
  'WATCHINGLIST_VIEW_ALL_EMPLOYEE',
  'GENERAL_MANAGE',
  'VIEW_EMPLOYEE_PAYROLL_INFO',
  'SETUP_PAYROLL',
  'SETUP_WORKFLOW',
  'VIEW_PAYRUNS_INFO',
  'MASTER',
  'VIEW_ALL_CONTRACT',
  'EDIT_ALL_CONTRACT',
  'APPROVE_CONTRACT',
  'WATCHINGLIST_REMOVE_EMPLOYEE',
  'WATCHINGLIST_ADD_EMPLOYEE',
  'WATCHINGLIST_EMPLOYEE_SKILL',
  'WATCHINGLIST_HR_REQUEST_LIST_VIEW',
  'WATCHINGLIST_MANAGER_REQUEST_LIST_VIEW',
  'WACHINGLIST_EMPLOYEE_SKILL',
  'WATCHINGLIST_MANAGE',
  'WATCHINGLIST_VIEW',
  'WATCHINGLIST_MN_REQUEST_LIST_VIEW',
  'RECORD_INSURANCE_PAYMENT_EXPORT',
  'RECORD_INSURANCE_PAYMENT_APPROVE',
  'INSURANCE_APPROVAL_EXPORT',
  'PERFORMANCE_REVIEW_MANAGE',
  'PERFORMANCE_REVIEW_HR',
  'SETUP_GOAL',
  'PTI_MANAGE',
  'TRAINING_ACCESS_PERSONAL',
  'TRAINING_MANAGE',
  'VIEW_PERFORMANCE_REVIEW',
  'CREATE_CYCLE_REVIEW',
  'UPDATE_CYCLE_REVIEW',
  'START_CYCLE_REVIEW',
  'VIEW_PENDING_REVIEW',
  'VIEW_EMPLOYEE_PR_MANAGEMENT',
  'PERFORMANCE_ACCESS_PERSONAL',
  'RECRUIT_JOB_APPROVE',
  'RECRUIT_CONFIG',
  'RECRUIT_APPROVE_OFFER',
  'RECRUIT_JOB_OFFER',
  'RECRUIT_JOB_CREATED',
  'RECRUIT_CANDIDATE_INTERVIEW',
  'RECRUIT_CREATE_TEMPLATE',
  'PERFORMANCE_MANAGE',
  'TIMESHEET_REPORT',
  'TIMESHEET_CONFIG_TIMEKEEPING',
  'SHOW_MONEY_IN_PAYROLL',
  'PROFILE_ACCESS_PERSONAL',
  'TIMESHEET_ACCESS_PERSONAL',
  'PAYSLIP_ACCESS_PERSONAL',
  'TIMEKEEPING_ACCESS_PERSONAL',
  'ONBOARD_ACCESS_PERSONNEL',
  'OFFBOARD_ACCESS_PERSONNEL',
  'DEVICE_ACCESS_PERSONNEL',
  'TIMESHEET_ACCESS_OBSERVATION_LIST',
  'LEAVE_OFF_ACCESS_PERSONAL',
];
