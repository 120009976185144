export const path = {
  dashboard: '/dashboard',
  myPage: '/my-page',
  employee: '/employee/list-employee',
  manageWatchingList: '/manage-watching-list',
  manageProject: '/manage-project',
  historyManageProject: '/history-manage-project',
  manageLeaveOff: '/manage-leave-off',
  manageOvertime: '/manage-overtime',
  insurance: '/insurance',
  compulsoryInsurance: '/insurance/complusory-insurance',
  payroll: '/payroll',
  payrollDetail: '/payroll/pay-runs/detail',
  employeeTimesheet: '/manage/employee_timesheet',
  requestApproval: '/manage/request_approval',
  employeeContract: '/contract/employee-contract',
  requestContract: '/contract/request-contract',
  contractManagement: '/contract/contract-management',
  generateContractAI: '/contract/contract-management/generate-contract-ai',
  defineSignatureContract: '/contract/contract-management/generate-contract-ai/define-signature',
  generateContractAIDetail: '/contract/contract-management/generate-contract-ai/contract-detail',
  editContract: '/contract/contract-management/generate-contract-ai/contract-edit',
  onsiteEmployee: '/configuration/general-setting/onsite-employee',
  report: '/report',
} as const;
