import { useMsal } from '@azure/msal-react';
import { Col, Form, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { loginWithPassword } from 'features/auth/loginAction';

import EyeIcon from 'assets/icons/ic_eye_fill.svg';
import EyeSlashIcon from 'assets/icons/ic_eye_slash_fill.svg';
import GoogleIcon from 'assets/icons/ic_google.svg';
import Office365Icon from 'assets/icons/ic_office_365.svg';
import SmsIcon from 'assets/icons/ic_sms.svg';
import KingworkLogo from 'assets/icons/kingwork_logo.png';

import { getUser } from 'utils/auth';
import { getJson, removeItem, setJson } from 'utils/storage';

import { loginRequest } from 'constants/msAuthConfig';
import { PERMISSION_DASHBOARD_ACCESS } from 'constants/permissions';

import { ROOT_API } from 'constants/commons';
import AuthInput from 'features/auth/components/AuthInput';
import AuthSignInPhone from 'features/auth/components/AuthSignInPhone';
import SignInTypeButton from 'features/auth/components/SignInTypeButton';
import {
  CheckboxStyled,
  ContainserStyled,
  CustomButtonStyled,
  ForgotPasswordStyled,
  FormItemStyled,
  FormStyled,
  ImageStyled,
  LineStyled,
  LoginOptionLabelStyled,
  LogoStyled,
  RowStyled,
  SignUpLinkStyled,
  SloganTextStyled,
  TitleStyled,
} from './styled';

const AuthSignIn = () => {
  const accountStorage = getJson('account');
  const emailStorage = accountStorage?.email || '';

  const { search } = useLocation();
  const backUrl = search.replace('?backUrl=', '');
  // const backUrl = new URLSearchParams(search).get('backUrl');
  const { t } = useTranslation(['auth', 'modal', 'common']);
  const [username, setUsername] = useState(emailStorage);
  const [password, setPassword] = useState('');
  const [subDomain, setDubDomain] = useState('');

  const [isShowLoginPhone, setIsShowLoginPhone] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(
    !localStorage.getItem('hrm.remember') || localStorage.getItem('hrm.remember') === 'yes'
      ? true
      : false,
  );
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const user = getUser();
  const history = useHistory();

  const loginPassword = (
    isRememberMe: boolean,
    username: string,
    password: string,
    isAuto: boolean = false,
  ) => {
    if (isRememberMe && username) {
      setJson('account', { email: username, phone: null });
    } else {
      removeItem('account');
    }
    localStorage.setItem('hrm.remember', isRememberMe ? 'yes' : 'no');
    dispatch(
      loginWithPassword({
        username,
        password,
        callBack: (response: any) => {
          if (!isAuto) return;
          if (response?.error) {
            Modal.error({
              title: `${t('modal:error_try_again')}`,
              onOk() {
                const uri = `https://itv.kingwork.vn/register`;
                window.location.replace(uri);
              },
            });
          }
        },
      }),
    );
  };

  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      if (ROOT_API === 'https://dev.kingwork.vn/api') {
        localStorage.setItem('hrm.tenant', 'dev');
      } else if (ROOT_API === 'https://qa.kingwork.vn/api') {
        localStorage.setItem('hrm.tenant', 'qa');
      } else {
        localStorage.setItem('hrm.tenant', 'greensun');
      }
    } else {
      const parts = window.location.host.split('.');
      const subdomain = parts[0] ? parts[0] : 'itv';
      localStorage.setItem('hrm.tenant', subdomain || 'itv');
      setDubDomain(subdomain);

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const email = urlParams.get('email') ? window.atob(urlParams.get('email') || '') : '';
      const password = urlParams.get('password')
        ? window.atob(urlParams.get('password') || '')
        : '';
      if (email && password) {
        loginPassword(true, email, password, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      if (user.id) {
        const OneSignal = window.OneSignal || [];
        OneSignal.push(function () {
          OneSignal.isPushNotificationsEnabled(function (isEnabled: boolean) {
            if (isEnabled) {
              console.log('Push notifications are enabled!');
              OneSignal.setExternalUserId(user.id);
            } else {
              console.log('Push notifications are not enabled yet.');
            }
          });
        });
      }
      const isPermissionsUser = getUser()?.permissions?.includes(
        PERMISSION_DASHBOARD_ACCESS.DASHBOARD_ACCESS_HR,
      );

      // Check for pending note token
      const pendingNoteToken = localStorage.getItem('pendingNoteToken');
      const pendingNoteStatus = localStorage.getItem('pendingNoteStatus');
      const pendingNoteType = localStorage.getItem('pendingNoteType');

      if (pendingNoteToken) {
        // Construct URL with parameters
        const noteHandleUrl = `/notes/handle?token=${pendingNoteToken}${
          pendingNoteStatus ? `&status=${pendingNoteStatus}` : ''
        }${pendingNoteType ? `&type=${pendingNoteType}` : ''}`;
        history.replace(noteHandleUrl);
        return;
      }

      if (backUrl) {
        history.replace(backUrl);
      } else if (isMobileOnly) {
        history.replace('/home');
      } else if (
        user?.permissions?.includes(PERMISSION_DASHBOARD_ACCESS.DASHBOARD_ACCESS_HR) ||
        user?.isSystemAdmin
      ) {
        if (isPermissionsUser) {
          history.replace('/module-selection');
        } else {
          history.replace('/dashboard');
        }
      } else {
        if (isPermissionsUser) {
          history.replace('/module-selection');
        } else {
          history.replace('/my-page/timesheet');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onLoginButtonClick = (values: any) => {
    loginPassword(isRememberMe, username, password);
  };

  const onLoginMicrosoftButtonClick = async () => {
    try {
      instance.loginRedirect(loginRequest).catch((error) => {
        console.log('error', error);
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const onToggleVisiblePassword = () => {
    setVisiblePassword((visible) => !visible);
  };

  return (
    <ContainserStyled>
      {isMobileOnly ? (
        <TitleStyled className="top-up">{t('auth:sign_in.title')}</TitleStyled>
      ) : (
        <TitleStyled>{t('auth:sign_in.title')}</TitleStyled>
      )}
      <FormStyled onFinish={onLoginButtonClick}>
        <Col md={24} xs={24}>
          <FormItemStyled
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
            initialValue={emailStorage}
          >
            <AuthInput
              label={t('auth:sign_in.account_label')}
              value={username}
              onChange={(e: any) => setUsername(e.target.value)}
              placeholder={t('auth:sign_in.placeholder_account')}
            />
          </FormItemStyled>
        </Col>
        <Col md={24} xs={24}>
          <FormItemStyled
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <AuthInput
              label={t('auth:sign_in.password_label')}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              placeholder={t('auth:sign_in.placeholder_passwrod')}
              type={visiblePassword ? 'text' : 'password'}
            >
              <div onClick={onToggleVisiblePassword} style={{ cursor: 'pointer' }}>
                {visiblePassword ? (
                  <img src={EyeIcon} alt="hiden password" />
                ) : (
                  <img src={EyeSlashIcon} alt="show password" />
                )}
              </div>
            </AuthInput>
          </FormItemStyled>
        </Col>
        <RowStyled className="top-up line-up">
          <CheckboxStyled
            defaultChecked={isRememberMe}
            onChange={(e) => setIsRememberMe(e.target.checked)}
          >
            {t('auth:sign_in.rememeber_me')}
          </CheckboxStyled>
          <ForgotPasswordStyled
            onClick={() => {
              history.push('/password-reset');
            }}
          >
            {t('auth:sign_in.forgot_password')}
          </ForgotPasswordStyled>
        </RowStyled>
        <Form.Item>
          <CustomButtonStyled type="primary" htmlType="submit">
            {t('auth:sign_in.login_button_label')}
          </CustomButtonStyled>
        </Form.Item>
      </FormStyled>
      <RowStyled className="top-down">
        <LineStyled />
        <LoginOptionLabelStyled>{t('auth:sign_in.login_option_label')}</LoginOptionLabelStyled>
        <LineStyled />
      </RowStyled>
      <RowStyled>
        <SignInTypeButton label="Google">
          <ImageStyled src={GoogleIcon} alt="google" />
        </SignInTypeButton>
        <SignInTypeButton label="Office 365" onClick={onLoginMicrosoftButtonClick}>
          <ImageStyled src={Office365Icon} alt="office 365" />
        </SignInTypeButton>
        <SignInTypeButton label="Phone number" onClick={() => setIsShowLoginPhone(true)}>
          <ImageStyled src={SmsIcon} alt="linkedin" />
        </SignInTypeButton>
      </RowStyled>
      <RowStyled>
        <LineStyled />
      </RowStyled>
      {(subDomain === 'core' ||
        subDomain === 'hrm-dev' ||
        subDomain === 'hrm-qa' ||
        subDomain === '') && (
        <>
          <RowStyled>
            <SignUpLinkStyled
              onClick={() => {
                history.push('/register');
              }}
            >
              {t('auth:sign_in.signup_new_account_label')}
            </SignUpLinkStyled>
          </RowStyled>
          <RowStyled className={'bee'}>
            <SloganTextStyled>{t('auth:sign_in.slogan_start')}</SloganTextStyled>
            <LogoStyled src={KingworkLogo} alt="kingwork" />
            <SloganTextStyled>{t('auth:sign_in.slogan_end')}</SloganTextStyled>
          </RowStyled>
        </>
      )}
      <AuthSignInPhone isVisible={isShowLoginPhone} onClose={() => setIsShowLoginPhone(false)} />
    </ContainserStyled>
  );
};

export default AuthSignIn;
