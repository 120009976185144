import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getUser } from 'utils/auth';
import BtnBack from 'assets/icons/btn_back.png';
import SuccessIcon from 'assets/icons/ic_success_green.svg';
import ErrorIcon from 'assets/icons/ic_times_orange.svg';
import { isMobileOnly } from 'react-device-detect';
import { message } from 'antd';
import { useAppSelector } from 'app/hooks';
import notesApi from 'api/notesApi';

const NoteHandlePage = () => {
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState<any>(undefined);
  const [text, setText] = useState('');
  const user = useAppSelector((state) => state.auth?.user);

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const token = params.get('token');
  const type = params.get('type');

  useEffect(() => {
    // Kiểm tra người dùng đã đăng nhập chưa
    if (!user) {
      // Lưu token và các tham số vào localStorage để sau khi đăng nhập có thể xử lý
      if (token) {
        localStorage.setItem('pendingNoteToken', token);
        localStorage.setItem('pendingNoteType', type || '');
      }
      // Chuyển hướng đến trang đăng nhập
      history.push('/login');
      return;
    }

    if (!token) return;

    if (type === 'note approval') {
      const handleNoteApproval = async () => {
        try {
          const response = await notesApi.handleNoteByToken({
            token: token,
          });
          setIsSuccess(true);
          setText(response.message || 'Note đã được xử lý thành công');

          // Xóa token đã lưu sau khi xử lý thành công
          localStorage.removeItem('pendingNoteToken');
          localStorage.removeItem('pendingNoteStatus');
          localStorage.removeItem('pendingNoteType');

          setTimeout(() => {
            isMobileOnly ? history.replace('/') : history.push('/');
          }, 2000);
        } catch (error: any) {
          setIsSuccess(false);
          setText(error.message || 'Đã xảy ra lỗi khi xử lý note');
        }
      };
      handleNoteApproval();
    }
  }, [token, type, user, history]);

  return (
    <>
      <Container>
        <div className="container">
          {isSuccess === true && (
            <div className="container_img">
              <img src={SuccessIcon} alt="Success icon" width={100} height={100} />
              <div>{text}</div>
            </div>
          )}
          {isSuccess === false && (
            <div className="container_img">
              <img src={ErrorIcon} alt="Error icon" width={100} height={100} />
              <div>{text}</div>
              <img
                src={BtnBack}
                alt="button back"
                width={150}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/')}
              />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default NoteHandlePage;

export const Container = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cl_text_primary);
  font-family: Mulish;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container_img {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container_img div {
    margin-bottom: 50px;
  }
`;
