import axiosClient from './axiosClient';

const recruitApi = {
  recruitAction: (token: any) => {
    const response = axiosClient.post(
      `/recruitment/candidate-email/approve-reject-by-token`,
      token,
    );
    return response;
  },
  updateDetailCandidateInJob: (params: any) => {
    const response = axiosClient.put(
      `/recruitment/candidate/update-candidate-process/${params?.id}`,
      params?.body,
    );
    return response;
  },
  recruitJobAction: (token: any) => {
    const response = axiosClient.post(
      `/recruitment/job/approve-reject-by-token/job-approval`,
      token,
    );
    return response;
  },
  recruitOfferAction: (token: any) => {
    const response = axiosClient.post(
      `/recruitment/candidate/approve-reject-by-token/offer-approval`,
      token,
    );
    return response;
  },
  recruitProgressAction: (token: any) => {
    const response = axiosClient.post(
      `/recruitment/candidate-email/approve-reject-by-token/interview-candidate`,
      token,
    );
    return response;
  },
  recruitAttendInterviewAction: (token: any) => {
    const response = axiosClient.post(
      `recruitment/candidate/approve-reject-by-token/interviewer-interview`,
      token,
    );
    return response;
  },
  getPublicJobDetail: (jobId: string) => {
    const url = `/recruitment/jobs/public/${jobId}`;
    return axiosClient.get(url);
  },
};

export default recruitApi;
