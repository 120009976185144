import { DeleteTwoTone, EyeOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { YYYY_MM_DD } from 'constants/commons';
import {
  deleteRecruitmentJob,
  getRecruitmentJob,
  getRecruitmentJobByAssignedUser,
  updateJobRequestStatus,
  updateJobStatus,
} from 'features/recruitment/recruitmentAction';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PermissionUser, isInterviewer } from './types';
import { getUser } from 'utils/auth';
import ModalFormReason from 'features/timesheet/components/ModalFormReason';
import { useTranslation } from 'react-i18next';

export interface RecruitJobType {
  id: string;
  status: string;
  createdAt: string;
  positionName: string;
  department: string;
  location: string;
  headcount: number;
  jobRequestStatus?: string;
  totalApplications: string;
  jobCode: string;
  createdBy: string;
  actionBy: string;
  createdReason: string | null;
}

export enum JobStatus {
  NEW = 'New',
  WAITING_FOR_APPROVAL = 'Waiting for approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
}

export enum JobRequestStatus {
  REQUEST = 'Request',
  DONE = 'Done',
  CANCEL = 'Cancel',
}

export interface JobsRequisitionFilter {
  jobLocation?: string[];
  jobDepartment?: string[];
  positionName?: string;
  jobType?: string[];
  jobCode?: string;
  status?: string;
}

export interface JobsRequisitionStaffViewTableProps {
  search?: string;
  filter?: JobsRequisitionFilter;
  sizePage: number;
  setSizePage: (size: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const JobsRequisitionStaffViewTable = ({
  search = '',
  filter = {},
  sizePage,
  setSizePage,
  currentPage,
  setCurrentPage,
}: JobsRequisitionStaffViewTableProps) => {
  const { t } = useTranslation(['recruitment', 'action', 'payroll', 'timesheet']);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const recruitmentJobData = useAppSelector(selectRecruitmentData)?.recruitmentJobData;
  const currentUser = getUser();

  const [isOpenModel, setIsOpenModel] = useState(false);
  const [itemActionSelected, setItemActionSelected] = useState({ type: '', id: '' });

  const getDataJobsRequisitionManagement = () => {
    const query = {
      limit: sizePage,
      offset: currentPage,
      searchName: search,
      filter: {
        jobLocation: [],
        jobDepartment: [],
        positionName: '',
        jobType: [],
        jobCode: '',
        status: '',
        ...filter,
      },
      assignedUserId: currentUser?.id,
    };
    dispatch(getRecruitmentJobByAssignedUser(query));
  };

  useEffect(() => {
    getDataJobsRequisitionManagement();
  }, [dispatch, search, sizePage, currentPage, filter]);

  const baseMenu = [
    {
      name: t('timesheet:View_Detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      handleClick: (value: RecruitJobType) => {
        history.push({
          pathname: `/recruitment/jobs/detail/${value.id}`,
          state: { fromStaffView: true },
        });
      },
    },
  ];

  const handleActionSave = (values: any) => {
    dispatch(
      updateJobStatus({
        id: itemActionSelected?.id,
        body: {
          status:
            itemActionSelected?.type === JobStatus.APPROVED
              ? JobStatus.APPROVED
              : JobStatus.REJECTED,
          reason: values?.reason || '',
        },
        callBack: () => {
          setIsOpenModel(false);
          getDataJobsRequisitionManagement();
        },
      }),
    );
  };

  const approveMenuItem = {
    name: t('timesheet:approve'),
    handleClick: (value: RecruitJobType) => {
      setIsOpenModel(true);

      setItemActionSelected({ type: JobStatus.APPROVED, id: value?.id });
    },
  };

  const handleUpdateJobStatus = (id: string, status: string) => {
    dispatch(
      updateJobRequestStatus({
        id: id,
        body: {
          jobRequestStatus: status,
        },
        callBack: () => {
          getDataJobsRequisitionManagement();
        },
      }),
    );
  };

  const approveMenuItemWithJobRequestStatus = [
    {
      name: t('action:done'),
      type: 'confirm',
      confirmTitle: t('recruitment:confirm_done'),
      handleClick: (value: RecruitJobType) => {
        handleUpdateJobStatus(value?.id, 'Done');
      },
    },
    {
      name: t('action:cancel'),
      type: 'confirm',
      confirmTitle: t('recruitment:confirm_cancel'),
      handleClick: (value: RecruitJobType) => {
        handleUpdateJobStatus(value?.id, 'Cancel');
      },
    },
  ];

  const rejectMenuItem = {
    name: t('timesheet:Reject'),
    handleClick: (value: RecruitJobType) => {
      setIsOpenModel(true);
      setItemActionSelected({ type: JobStatus.REJECTED, id: value?.id });
    },
  };

  const getMenuForRecord = (record: RecruitJobType) => {
    let menu = [...baseMenu];

    if (!isInterviewer() && getUser()?.permissions?.includes(PermissionUser.RECRUIT_JOB_APPROVE)) {
      if (record.status === JobStatus.WAITING_FOR_APPROVAL) {
        menu.push(approveMenuItem);
      }

      if (record.status === JobStatus.WAITING_FOR_APPROVAL || record.status === JobStatus.NEW) {
        menu.push(rejectMenuItem);
      }

      if (
        record.status === JobStatus.APPROVED &&
        record?.jobRequestStatus !== JobRequestStatus.DONE &&
        record?.jobRequestStatus !== JobRequestStatus.CANCEL
      ) {
        menu.push(...approveMenuItemWithJobRequestStatus);
      }
    }

    return menu;
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id',
      width: 30,
      minWidth: 30,
      align: 'center',
      fixed: 'left',
      render: (_: any, __: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <div>{trueIndex + 1}</div>;
      },
    },
    {
      title: t('recruitment:job_id'),
      key: 'jobCode',
      dataIndex: 'jobCode',
      width: 100,
      minWidth: 100,
      fixed: 'left',
    },
    {
      title: t('recruitment:position_name'),
      key: 'positionName',
      dataIndex: 'positionName',
      width: 100,
      minWidth: 100,
      fixed: 'left',
    },
    {
      title: t('recruitment:job_department'),
      key: 'department',
      dataIndex: 'department',
      width: 100,
      minWidth: 100,
      fixed: 'left',
    },
    {
      title: t('recruitment:job_location'),
      key: 'location',
      dataIndex: 'location',
      width: 80,
      minWidth: 80,
    },
    {
      title: t('recruitment:headcount'),
      key: 'headcount',
      dataIndex: 'headcount',
      width: 70,
      minWidth: 70,
      align: 'center',
    },
    {
      title: t('recruitment:total_applications'),
      dataIndex: 'totalApplications',
      width: 80,
      minWidth: 80,
      align: 'center',
    },
    {
      title: t('recruitment:job_request_status'),
      key: 'status',
      dataIndex: 'status',
      width: 100,
      minWidth: 100,
    },
    {
      title: t('recruitment:created_by'),
      key: 'createdBy',
      dataIndex: 'createdBy',
      width: 100,
      minWidth: 100,
    },
    {
      title: t('recruitment:reason_by'),
      key: 'createdReason',
      dataIndex: 'createdReason',
      ellipsis: true,
      width: 100,
      minWidth: 100,
    },
    {
      title: t('recruitment:approved_by'),
      key: 'actionBy',
      dataIndex: 'actionBy',
      width: 80,
      minWidth: 80,
    },
    {
      title: t('recruitment:job_status'),
      key: 'jobRequestStatus',
      dataIndex: 'jobRequestStatus',
      width: 80,
      minWidth: 80,
    },
    {
      title: t('recruitment:job_created_date'),
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (value: any, record: any, index: any) => {
        return value ? moment(value).format(YYYY_MM_DD) : '';
      },
    },
    {
      title: t('action:action'),
      key: 'action',
      dataIndex: 'action',
      width: 50,
      minWidth: 50,
      align: 'center',
      render: (_: any, record: RecruitJobType, index: any) => {
        const menu = getMenuForRecord(record);
        return <MenuAction menu={menu} data={record} />;
      },
    },
  ];

  return (
    <>
      <ITVTable
        data={recruitmentJobData?.result || []}
        columns={columns}
        isRowSelect={true}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={recruitmentJobData?.totalResult}
        width={2000}
      />
      <ModalFormReason
        title={t('payroll:note')}
        isModalReason={isOpenModel}
        setIsModalReason={setIsOpenModel}
        handleFinish={handleActionSave}
      />
    </>
  );
};

export default JobsRequisitionStaffViewTable;
