import { useEffect, useMemo, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import { ContainerBgWhite } from 'styles/containerBgWhite';
import { PayrollByYearFilters } from './PayrollByYearFilters';
import { PayrollByYearList } from './PayrollByYearList';
import payrollApi from 'api/payrollApi';
import { ListParams, PayrollByYearPayload, PayrollYear } from 'types';
import { PAYROLL_YEAR_SORT_FIELDS, SORT_TYPES } from 'constants/payroll';

interface PayrollByYearProps {
  year: string;
}

export function PayrollByYear({ year }: PayrollByYearProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [employeePayrollList, setEmployeePayrollList] = useState<Array<PayrollYear>>([]);
  const [totalResult, setTotalResult] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState<Partial<ListParams>>({
    search: '',
    year: year,
  });

  const filtersMemo = useMemo(
    () => ({
      ...filters,
      offset: page,
      limit,
    }),
    [filters, page, limit],
  );

  useEffect(() => {
    setFilters((prev) => ({ ...prev, year }));
  }, [year]);

  useEffect(() => {
    const fetchPayrollByYearAPI = async () => {
      try {
        setIsLoading(true);
        const response = await payrollApi.getPayrollByYear(filtersMemo);

        setEmployeePayrollList(response?.data?.data || []);
        setTotalResult(response.data?.total || 0);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchPayrollByYearAPI();
  }, [filtersMemo]);

  const handleFiltersChange = (payload: PayrollByYearPayload) => {
    setPage(1);

    setFilters((prevFilter) => {
      return {
        ...prevFilter,
        ...payload,
      };
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    if (pagination?.current === page) {
      setPage(1);

      setFilters((prevFilters) => ({
        ...prevFilters,
        viewBy: sorter.column
          ? PAYROLL_YEAR_SORT_FIELDS[sorter.field as keyof typeof PAYROLL_YEAR_SORT_FIELDS]
          : PAYROLL_YEAR_SORT_FIELDS.full_name,
        sortBy: sorter.column
          ? SORT_TYPES[sorter.order as keyof typeof SORT_TYPES]
          : SORT_TYPES.ascend,
      }));
    }
  };

  return (
    <ContainerBgWhite>
      <PayrollByYearFilters onSubmit={handleFiltersChange} />

      <PayrollByYearList
        loading={isLoading}
        employeePayrollList={employeePayrollList}
        totalResult={totalResult}
        onTableChange={handleTableChange}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      />
    </ContainerBgWhite>
  );
}
