import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, message, Tag } from 'antd';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import ITVSelect from 'components/ITVSelect';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { INote, ApprovalStatus, ISendMailApproverNoteRequest } from 'api/notesApi';
import { removeAccents } from 'utils/text';
import notesApi from 'api/notesApi';

const StyledErrorMessage = styled.div`
  color: var(--cl_error500);
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  display: flex;
  justify-content: flex-end;
`;

const StyledModal = styled.div`
  width: 40vw;
  height: 100vh;
  background: white;
  padding: 24px;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1100;
  transform: translateX(100%);
  animation: slide-in 0.3s ease-out forwards;
  overflow-y: auto;

  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const NoteContent = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 16px;
  background-color: #f9f9f9;
  overflow-y: auto;
  max-height: 200px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

interface MailFormProps {
  initialMessage: string;
  listUserApproverOffer: any[];
  note: INote;
  onCancel: () => void;
  onSuccess: () => void;
}

const MailForm = ({
  onCancel,
  initialMessage,
  listUserApproverOffer,
  onSuccess,
  note,
}: MailFormProps): React.ReactElement => {
  console.log('note', note);
  const { t } = useTranslation(['note']);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedApprover, setSelectedApprover] = useState<any[]>([]);

  // Khi component mount, khởi tạo default cho field offerApprover dựa trên note.noteApprover
  useEffect(() => {
    if (note && note.noteApprover && note.noteApprover.length > 0) {
      const defaultSelected = note.noteApprover.map((item) => item.userId);
      setValue('offerApprover', defaultSelected);
      setSelectedApprover(note.noteApprover);
    }
  }, [note, setValue]);

  // Nếu có initialMessage, đặt giá trị cho messageContent
  useEffect(() => {
    if (initialMessage) {
      setValue('messageContent', initialMessage);
    }
  }, [initialMessage, setValue]);

  // Hàm xử lý khi chọn người phê duyệt
  const handleChangeApprover = (selectedValues: string | string[], selectedOptions: any) => {
    const valuesArray = typeof selectedValues === 'string' ? [selectedValues] : selectedValues;

    // Với mỗi id được chọn, kiểm tra nếu có trong dữ liệu cũ (note.noteApprover) thì lấy dữ liệu cũ
    const approvers = valuesArray.map((id) => {
      const existing = note.noteApprover.find((item) => item.userId === id);
      if (existing) return existing;
      const user = listUserApproverOffer.find((item) => item.id === id);
      return user
        ? {
            userId: user.userId,
            email: user.email,
            fullName: user.fullName,
          }
        : {
            userId: id,
            email: '',
            fullName: '',
          };
    });
    setSelectedApprover(approvers);
    console.log('selectedApprover', approvers);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const now = new Date().toISOString();
      const payload: ISendMailApproverNoteRequest = {
        ...note,
        // Sử dụng selectedApprover đã được cập nhật hoặc giữ lại dữ liệu cũ nếu không có thay đổi
        noteApprover: selectedApprover.map((approver) => ({
          userId: approver.userId,
          email: approver.email,
          fullName: approver.fullName,
          status: ApprovalStatus.PENDING,
          timestamp: now,
        })),
      };

      await notesApi.sendMailApproverNote(payload);
      onCancel();
      onSuccess(); // Gọi callback để refresh dữ liệu
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return ReactDOM.createPortal(
    <Overlay onClick={onCancel}>
      <StyledModal onClick={(e) => e.stopPropagation()}>
        <CloseButton shape="circle" icon={<CloseOutlined />} onClick={onCancel} />
        <h2>{t('note:sendMail')}</h2>
        <div style={{ fontWeight: 500, margin: '8px 0' }}>{t('note:approver_required')}</div>
        <Controller
          name="offerApprover"
          control={control}
          rules={{ required: t('note:approver_required') }}
          render={({ field }) => (
            <ITVSelect
              {...field}
              placeholder={t('note:select_approver')}
              dataSource={listUserApproverOffer}
              mode="multiple"
              showSearch
              style={{ width: '100%' }}
              size="middle"
              valueSelected={field.value}
              onChangeSelect={(value, key) => {
                field.onChange(value);
                handleChangeApprover(value, key);
              }}
              filterOption={(input, option) =>
                removeAccents(String(option?.label).toLowerCase()).indexOf(
                  removeAccents(input.toLowerCase()),
                ) >= 0
              }
              statusError={errors.offerApprover ? true : false}
            />
          )}
        />
        <div style={{ fontWeight: 500, margin: '8px 0' }}>{t('note:approver')}</div>
        <NoteContent dangerouslySetInnerHTML={{ __html: initialMessage }} />
        <ButtonGroup>
          <Button
            onClick={onCancel}
            style={{
              backgroundColor: '#f0f0f0',
              borderColor: '#d9d9d9',
              color: '#000',
            }}
          >
            {t('note:cancel')}
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            style={{
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              color: '#fff',
            }}
          >
            {t('note:send')}
          </Button>
        </ButtonGroup>
      </StyledModal>
    </Overlay>,
    document.body,
  ) as React.ReactElement;
};

export default MailForm;
