import { ProgrammingSkill } from '../interfaces/types';

export const processSkills = (category: ProgrammingSkill) => {
  if (!category) return [];

  // Xử lý skills: kiểm tra nếu là mảng thì sử dụng trực tiếp, nếu là chuỗi thì tách ra
  let skills: string[] = [];
  if (Array.isArray(category.technicalSummary)) {
    skills = [...category.technicalSummary];
  } else if (typeof category.technicalSummary === 'string') {
    skills = category.technicalSummary
      .split(/[,\n]/) // Tách theo dấu phẩy và xuống dòng
      .map((s) => s.trim().replace(/^[•\-\*]\s*/, '')) // Bỏ bullet point và trim
      .filter(Boolean);
  }

  // Nếu không có skill nào sau khi lọc, thêm một skill rỗng
  if (skills.length === 0) {
    skills.push('');
  }

  // Xử lý years: tách theo cả dấu phẩy và xuống dòng
  const yearsRaw = (category.yearsOfExperience || '')
    .split(/[,\n]/) // Tách theo dấu phẩy và xuống dòng
    .map((y) => y.trim())
    .filter(Boolean);

  // Lọc và định dạng years: chỉ giữ lại các dòng có format "X years" hoặc "X+ years"
  const years = yearsRaw
    .map((y) => {
      const yearMatch = y.match(/(\d+)(\+)?\s*(year|years|năm)?/i);
      if (yearMatch) {
        return yearMatch[1] + (yearMatch[2] || '');
      }
      const morePattern = /more\s+than\s+(\d+)/i;
      const moreMatch = y.match(morePattern);
      if (moreMatch) {
        return moreMatch[1] + '+';
      }
      return '';
    })
    .filter((y) => y !== '');

  // Map skills với years theo index, nếu skill không có years thì để trống
  const result = skills.map((skill, index) => {
    return {
      skill: skill,
      years: index < years.length ? years[index] : '',
    };
  });

  // Nếu không có skill nào, thêm một skill rỗng
  if (result.length === 0) {
    result.push({ skill: '', years: '' });
  }

  return result;
};
